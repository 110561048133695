import React, { useState, useEffect, useContext } from "react";
import {
  MapContext,
  FiltersContext,
  SubmissionsContext,
  SnackbarContext
} from "../contexts";
import { calculateRadiusBasedOnZoom } from "../math";
import { debounce } from "lodash";
import { getImageCount } from "../api/api";
import { useUser } from "reactfire";
import useSupercluster from "use-supercluster";
import { getGeolocationErrorMessage } from "../components/constants";
import * as geofirex from "geofirex";
import firebase from "firebase";
import { useFirestore } from "reactfire";

import { checkSubmission } from "../components/home/submission-utils";
const currDate = new Date();

export const MapStore = ({ children }) => {
  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [lastQueriedBounds, setLastQueriedBounds] = useState(null);
  const [zoom, setZoom] = useState(13);
  const [lastQueriedZoomLevel, setLastQueriedZoomLevel] = useState(13);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [radius, setRadius] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shouldCalculateRadius, setShouldCalculateRadius] = useState(true);
  const [infoWindowToOpenId, setInfoWindowToOpenId] = useState(null);
  const user = useUser();
  const [autocomplete, setAutoComplete] = useState(null);
  const [count, setCount] = useState(null);
  const [backToMap, setBackToMap] = useState(false);
  const [liveFeed, setLiveFeed] = useState(false);
  const [troubleSubmissions, setTroubleSubmissions] = useState([]);

  const [searchCity, setSearchCity] = useState(false);

  const { openSnackbar, snackInfo } = useContext(SnackbarContext);

  const {
    filters,
    resetting,
    assetId,
    filterRemoved,
    setFilterRemoved,
    setFilters,
    setAssetId
  } = useContext(FiltersContext);
  const {
    setSubmissionsInBounds,
    submissions,
    setSubmissions,
    setLastQueriedSubmissions,
    inspections
  } = useContext(SubmissionsContext);

  const cleanup = () => {
    setMap(null);
    setBounds(null);
    setLastQueriedBounds(null);
    setSubmissions([]);
    setLastQueriedSubmissions([]);
    setSubmissionsInBounds([]);
    setZoom(13);
    setLastQueriedZoomLevel(13);
    setCurrentLocation(null);
    setRadius(null);
    setLoading(false);
    setShouldCalculateRadius(true);
    setInfoWindowToOpenId(null);
    setAutoComplete(null);
    setCount(null);

    setFilters({
      modes: [],
      tags: [],
      users: [],
      submissionTypes: [],
      status: [],
      troubleStatusUsers: [],
      districts: []
    });
    if (assetId) {
      setAssetId(null);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      },
      error => {
        const message = getGeolocationErrorMessage(error.code);
        openSnackbar({
          type: "error",
          open: true,
          message: message
        });
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  }, []);

  const firestore = useFirestore();
  const geo = geofirex.init(firebase);
  const [subscription, setSubscription] = useState(null);

  const unsub = async () => {
    subscription && (await subscription.unsubscribe());
  };

  const updateLiveFeed = () => {
    let trobSub = [];
    troubleSubmissions.forEach(sub => {
      const valid = checkSubmission({ sub, filters, currDate, inspections });
      if (valid) {
        trobSub.push(valid);
      }
    });
    setSubmissions(trobSub);
    setLastQueriedSubmissions(trobSub);
    setLoading(false);
  };

  useEffect(() => {
    if (liveFeed && map) {
      updateLiveFeed();
    }
  }, [troubleSubmissions]);

  useEffect(() => {
    let snapSubmissions = [];
    let oneInvalid = false;
    submissions.forEach(item => {
      let sub = item.data ? item.data() : item;
      if (item.data) {
        sub.id = item.id;
      }
      const valid = checkSubmission({ sub, filters, currDate, inspections });
      if (valid) {
        snapSubmissions.push(valid);
      } else {
        oneInvalid = true;
      }
    });
    if (oneInvalid) {
      setSubmissions(snapSubmissions);
      setLastQueriedSubmissions(snapSubmissions);
    }
  }, [submissions]);

  const getSubmissions = ({ newCount, currMap }) => {
    const rad = calculateRadiusBasedOnZoom(currMap);
    if (subscription) {
      unsub();
    }
    const updateSubmissions = subs => {
      const snapSubmissions = [];
      subs.forEach(item => {
        let sub = item.data ? item.data() : item;
        if (item.data) {
          sub.id = item.id;
        }
        const valid = checkSubmission({ sub, filters, currDate, inspections });
        if (valid) {
          snapSubmissions.push(valid);
        }
      });

      if (snapSubmissions.length <= 7000) {
        setSubmissions(snapSubmissions);
        setLastQueriedSubmissions(snapSubmissions);
        setLoading(false);
      } else if (!snackInfo.open) {
        setLoading(false);
        openSnackbar({
          type: "info",
          message: "Too many submissions for map to load",
          open: true
        });
      }
    };
    if (liveFeed) {
      updateLiveFeed();
    } else if (currentLocation && rad && !assetId && newCount <= 7000) {
      const center = geo.point(currMap.center.lat(), currMap.center.lng());
      let query = firestore.collection("submissions");
      if (
        filters &&
        filters.dates &&
        (filters.dates.toDate || filters.dates.fromDate)
      ) {
        const { toDate, fromDate } = filters.dates;
        if (toDate) {
          query = query.where("time_created", "<=", toDate);
        }
        if (fromDate) {
          query = query.where("time_created", ">=", fromDate);
        }

        const unsubscribe = query.onSnapshot(updateSubmissions, error =>
          console.log(error)
        );
        return () => {
          unsubscribe();
        };
      } else {
        const sub = geo
          .query(query)
          .within(center, rad, "geohash_point", { log: true })
          .subscribe(updateSubmissions, error => console.log(error));
        setSubscription(sub);
      }
    } else if (newCount > 7000 && !snackInfo.open) {
      setLoading(false);
      setSubmissions([]);
      setLastQueriedSubmissions([]);
      openSnackbar({
        type: "info",
        message: "Please zoom in to see submissions",
        open: true
      });
    }
  };

  const getCount = async ({ radiusNew, mapNew }) => {
    if (!assetId) {
      setLoading(true);
    }
    if (map) {
      const newBounds = map.getBounds();
      let ne = newBounds.getNorthEast();
      let sw = newBounds.getSouthWest();
      let boundsMap = {
        maxLat: ne.lat(),
        minLat: sw.lat(),
        maxLng: ne.lng(),
        minLng: sw.lng()
      };

      let newCount = await getImageCount({
        user,
        bounds: boundsMap,
        filters,
        zoom,
        assetId,
        liveFeed
      });

      if (newCount && newCount.submissions_count >= 0) {
        setCount(newCount.submissions_count);
        if (!assetId) {
          getSubmissions({
            rad: radiusNew || radius,
            newCount: newCount.submissions_count,
            currMap: mapNew || map
          });
        } else if (subscription) {
          unsub();
        }
      } else if (!assetId) {
        openSnackbar({
          type: "error",
          message: "Something went wrong. Please try again",
          open: true
        });
        setLoading(false);
      }
    }
  };
  const debounceCount = debounce(getCount, 1000);
  useEffect(() => {
    if (resetting) {
      debounceCount({});
    }
  }, [resetting]);

  useEffect(() => {
    if (count > 8000) {
      debounceCount({});
    }
  }, [filters]);

  useEffect(() => {
    if (map && !assetId && !liveFeed) {
      debounceCount({});
    }
  }, [filters.dates]);

  useEffect(() => {
    const debounceRadius = debounce(setRadius, 1000);

    if ((map && shouldCalculateRadius) || filterRemoved) {
      const radiusNew = calculateRadiusBasedOnZoom(map);
      debounceRadius(radiusNew);
      setShouldCalculateRadius(false);
      if (!lastQueriedBounds) {
        setLastQueriedBounds(map.getBounds());
        debounceCount({});
      }
      filterRemoved && setFilterRemoved(false);
    }
  }, [zoom, map, filterRemoved]);

  const onMapChange = async ({ zoom: newZoom, bounds }) => {
    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);

    const outOfBounds =
      lastQueriedBounds &&
      (!lastQueriedBounds.contains(bounds.nw) ||
        !lastQueriedBounds.contains(bounds.se));

    if (newZoom !== zoom) {
      if (newZoom < zoom && newZoom < lastQueriedZoomLevel) {
        setLastQueriedZoomLevel(newZoom);
        setShouldCalculateRadius(true);
        debounceCount({});
      } else if (count > 7000 || outOfBounds) {
        setLastQueriedZoomLevel(newZoom);
        debounceCount({});
      }
      setZoom(newZoom);
    } else if (searchCity) {
      if (outOfBounds) {
        debounceCount({});
      }
    }
    setSearchCity(false);
  };

  const debouceLocation = debounce(setCurrentLocation, 1000);

  const onMapPan = map => {
    const newBounds = map.getBounds();

    const center = map.center;
    if (
      lastQueriedBounds &&
      (!lastQueriedBounds.contains(newBounds.getNorthEast()) ||
        !lastQueriedBounds.contains(newBounds.getSouthWest()))
    ) {
      debouceLocation({ lat: center.lat(), lng: center.lng() });
      setLastQueriedBounds(newBounds);
      debounceCount({ mapNew: map });
    }
  };

  const zoomToSubmission = item => {
    if (item) {
      map.setCenter(
        new window.google.maps.LatLng(item.group_latitude, item.group_longitude)
      );
      map.setZoom(22);

      setInfoWindowToOpenId(item.id);
    }
  };

  const points = submissions.map(submission => ({
    type: "Feature",
    properties: {
      cluster: false,
      submissionId: submission.id,
      submission
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(submission.group_longitude),
        parseFloat(submission.group_latitude)
      ]
    }
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 200, maxZoom: 22 }
  });

  useEffect(() => {
    const subsInBounds = [];
    clusters.forEach(cluster => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const { cluster: isCluster, submission } = cluster.properties;
      const bounds = map.getBounds();
      const containsCluster = bounds.contains(
        new window.google.maps.LatLng(latitude, longitude)
      );
      if (containsCluster) {
        if (isCluster) {
          const leaves = supercluster.getLeaves(cluster.id, "Infinity");
          leaves.forEach(leaf => subsInBounds.push(leaf.properties.submission));
        } else {
          subsInBounds.push(submission);
        }
      }
    });
    setSubmissionsInBounds(subsInBounds);
  }, [clusters]);

  const recenterMap = () => {
    const bounds = new window.google.maps.LatLngBounds();
    submissions.forEach(submission =>
      bounds.extend(
        new window.google.maps.LatLng(
          submission.group_latitude,
          submission.group_longitude
        )
      )
    );
    map.fitBounds(bounds);
    onMapPan(map);
  };

  const recenterLivefeed = () => {
    if (map) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: "New Brunswick" }, results => {
        map.fitBounds(results[0].geometry.bounds, 0);
      });
    }
  };

  useEffect(() => {
    if (assetId && assetId.length) {
      const submissionsCollection = firestore.collection("submissions");
      const imagesCollection = firestore.collection("submission_files");

      const getAssetQuery = (collection, field) => {
        let query = collection;
        const end = assetId.replace(/.$/, c =>
          String.fromCharCode(c.charCodeAt(0) + 1)
        );
        return query.where(field, ">=", assetId).where(field, "<", end);
      };

      const updateSubmissionsByAsset = promisedResults => {
        const submissionsSnap = [];
        const querySnap = [];
        promisedResults.forEach(results => {
          results.forEach(async doc => {
            const sub = doc.data();
            sub.id = doc.id;
            const valid = checkSubmission({
              sub,
              filters,
              currDate,
              isAsset: true
            });
            if (valid) {
              submissionsSnap.push(valid);
            }
            querySnap.push(sub);
          });
        });

        const uniqueSubs = submissionsSnap.filter(
          (submission, index, self) =>
            index === self.findIndex(t => t.id === submission.id)
        );

        const uniqueQuery = querySnap.filter(
          (submission, index, self) =>
            index === self.findIndex(t => t.id === submission.id)
        );

        setSubmissions(uniqueSubs);

        setLastQueriedSubmissions(uniqueQuery);
        setLoading(false);
      };

      const onInspectionAssetRetrieveal = promisedRes => {
        let queries = [];
        promisedRes.forEach(imageSet => {
          const ids = [];
          imageSet.forEach(async doc => {
            const image = doc.data();
            ids.push(image.group_id);
          });
          let calls = ids.map(id =>
            firestore
              .collection("submissions")
              .where("group_id", "==", id)
              .get()
          );
          queries = [...queries, ...calls];
        });

        Promise.all([
          getAssetQuery(submissionsCollection, "asset_id").get(),
          ...queries
        ]).then(updateSubmissionsByAsset);
      };

      Promise.all([
        getAssetQuery(
          submissionsCollection,
          "trouble_status_reference_number"
        ).get(),
        getAssetQuery(imagesCollection, "meter_asset_id").get(),
        getAssetQuery(imagesCollection, "inspection_asset_id").get(),
        getAssetQuery(imagesCollection, "asset_id").get()
      ]).then(onInspectionAssetRetrieveal);
    } else if (map) {
      debounceCount({});
    }
  }, [assetId]);

  return (
    <MapContext.Provider
      value={{
        zoom,
        map,
        setMap,
        currentLocation,
        setCurrentLocation,
        radius,
        onMapPan,
        infoWindowToOpenId,
        setInfoWindowToOpenId,
        autocomplete,
        setAutoComplete,
        zoomToSubmission,
        onMapChange,
        count,
        loading,
        setLoading,
        clusters,
        supercluster,
        setSearchCity,
        setLastQueriedBounds,
        recenterMap,
        unsub,
        setCurrentLocation,
        cleanup,
        backToMap,
        setBackToMap,
        setLiveFeed,
        liveFeed,
        updateLiveFeed,
        troubleSubmissions,
        setTroubleSubmissions,
        recenterLivefeed
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MapStore;
