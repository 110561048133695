import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import colors from "../ui/colors";
import { getMarkerStyle } from "./util";

const useStyles = makeStyles()({
  cluster: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    zIndex: 1,
    cursor: "pointer"
  },
  clusterCore: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 1,
    backgroundColor: colors.panoPinBlue
  },
  clusterCoreShadow: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 0,
    backgroundColor: colors.panoPinBlue,
    opacity: 0.25
  }
});

const Cluster = ({ pointCount, onClusterClick }) => {
  const { width, height, size } = getMarkerStyle(pointCount);
  const { classes } = useStyles();

  return (
    <div
      className={classes.cluster}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: width / 2
      }}
      onClick={onClusterClick}
    >
      <div
        style={{
          width: size,
          height: size,
          borderRadius: size / 2
        }}
        className={classes.clusterCore}
      >
        <div
          className={classes.clusterCoreShadow}
          style={{
            width: `${width}px`,
            height: `${height}px`,
            borderRadius: width / 2
          }}
        />
        <Typography style={{ color: "white" }}>{pointCount}</Typography>
      </div>
    </div>
  );
};

export default Cluster;
