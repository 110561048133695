import React from "react";
import {
  Typography,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Button
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import moment from "moment";

const useStyles = makeStyles()(theme => ({
  typography: {
    padding: theme.spacing(1)
  },
  root: {
    minWidth: "275px",
    maxWidth: "300px",

    overflowX: "scroll",
    overflowY: "hidden"
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    overflowX: "scroll",
    overflowY: "hidden",

    "&::-webkit-scrollbar-track": {
      background: "white"
    }
  },
  image: { maxHeight: "150px", objectFit: "contain !important" },
  windowContainer: { overflow: "hidden" }
}));

const SubmissionInfoWindow = ({ submission, images, onImageClick, small }) => {
  const { classes } = useStyles();
  const SubmissionInfoItem = ({ text }) => (
    <Typography className={classes.typography}>{text}</Typography>
  );
  const smallWidth = small ? { maxWidth: "380px" } : {};
  return (
    <Grid
      container
      direction="column"
      className={classes.windowContainer}
      style={smallWidth}
    >
      <Grid>
        {submission.geo_location_summary ? (
          <SubmissionInfoItem
            text={`${submission.geo_location_summary.street_address} ${submission.geo_location_summary.municipality}`}
          />
        ) : null}
        <SubmissionInfoItem
          text={moment
            .unix(submission.time_created.seconds)
            .format("YYYY/MM/DD HH:mm")}
        />
        <SubmissionInfoItem
          text={`Created By: ${
            submission.user_display_name || submission.user_email
          }`}
        />

        {submission.trouble_status_reference_number ? (
          <SubmissionInfoItem
            text={`Reference Number: ${submission.trouble_status_reference_number}`}
          />
        ) : null}
      </Grid>
      <ImageList className={classes.gridList} cols={5}>
        {images.map((image, i) => (
          <ImageListItem
            key={image.download_link}
            style={{ cursor: "pointer" }}
          >
            <img
              id={image.download_link}
              src={image.download_link}
              alt={image.download_link}
              className={classes.image}
              onClick={() => onImageClick(i)}
            />
            <img
              src={image.download_link}
              alt={image.download_link}
              style={{ display: "none" }}
            />
            {(image.meter_asset_id ||
              image.inspection_asset_id ||
              image.asset_id) && (
              <ImageListItemBar
                title={
                  image.inspection_asset_id ||
                  image.meter_asset_id ||
                  image.asset_id
                }
              />
            )}
          </ImageListItem>
        ))}
      </ImageList>

      <Button
        id={`${submission.group_id}-view-btn`}
        onClick={() => onImageClick(0)}
      >
        View Submission
      </Button>
    </Grid>
  );
};

export default SubmissionInfoWindow;
