import React, { useState, useContext } from "react";
import { useAuth } from "reactfire";
import {
  Button,
  Grid,
  Paper,
  CircularProgress,
  TextField,
  Typography
} from "@mui/material";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import colors from "../ui/colors";
import { MapContext, UserContext, FiltersContext } from "../../contexts";
import { EMAIL_REG } from "../constants";
import { useTranslation } from "react-i18next";

const { REACT_APP_TENANT_ID } = process.env;

export const Login = () => {
  const auth = useAuth();
  const history = useHistory();
  const provider = new firebase.auth.OAuthProvider("microsoft.com");
  const [popupOpen, setPopupOpen] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const { setCurrentLocation } = useContext(MapContext);
  const { getUserGroups } = useContext(UserContext);
  const { getUsers } = useContext(FiltersContext);
  const [emailError, setEmailError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const { t } = useTranslation();

  const onLoginSuccess = data => {
    setPopupOpen(false);
    getUserGroups(data.user);
    getUsers();

    navigator.geolocation.getCurrentPosition(position => {
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
    });
    history.replace("/");
  };

  const signInWithMicrosoft = () => {
    provider.setCustomParameters({
      tenant: REACT_APP_TENANT_ID,
      prompt: "login",
      login_hint: email
    });
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(onLoginSuccess, () => setPopupOpen(false));
      return setPopupOpen(true);
    });
  };

  const onLoginFailure = error => {
    setLoginError(error.message);
    setPopupOpen(false);
  };

  const emailPasswordLogin = () => {
    const split = email.split("@");
    split[1] === "nbpower.com"
      ? signInWithMicrosoft()
      : auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(onLoginSuccess, onLoginFailure);
          return setPopupOpen(true);
        });
  };

  const continueLoginFlow = () => {
    const split = email.split("@");
    split[1] === "nbpower.com" ? signInWithMicrosoft() : setShowPassword(true);
  };

  return (
    <Grid
      container
      style={{ margin: 10 }}
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid
        component={Paper}
        item
        container
        xs={4}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        style={{
          height: "450px",
          backgroundColor: colors.main
        }}
      >
        <Grid
          item
          container
          component={Paper}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          direction="column"
          style={{
            height: "90%",
            width: "90%",
            backgroundColor: "white"
          }}
        >
          <img
            src={require("../../images/pano_logo.svg")}
            style={{ height: "100px", margin: 16 }}
          />
          {popupOpen ? (
            <CircularProgress />
          ) : (
            <Grid
              container
              item
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <TextField
                id="email"
                label={t("common:email")}
                variant="outlined"
                error={emailError}
                defaultValue={email}
                helperText={emailError ? t("errorMessages:invalidEmail") : null}
                type="email"
                onChange={({ target }) => setEmail(target.value)}
                onBlur={() => {
                  email &&
                    setEmailError(!!!EMAIL_REG.test(email.toLowerCase()));
                }}
                onFocus={() => {
                  setEmailError(false);
                  setLoginError(null);
                }}
                inputProps={{
                  style: { backgroundColor: "white" }
                }}
              />

              {!showPassword ? (
                <Button
                  variant="contained"
                  onClick={continueLoginFlow}
                  style={{ marginTop: 14, width: 170 }}
                  disabled={emailError || !email}
                >
                  Continue
                </Button>
              ) : null}
              {showPassword ? (
                <>
                  <TextField
                    id="password"
                    label={t("common:password")}
                    variant="outlined"
                    type="password"
                    onChange={({ target }) => setPassword(target.value)}
                    inputProps={{
                      style: { backgroundColor: "white" }
                    }}
                    style={{ marginTop: 14 }}
                    onFocus={() => setLoginError(null)}
                  />

                  <Button
                    variant="contained"
                    onClick={emailPasswordLogin}
                    style={{ marginTop: 8, width: 170 }}
                    disabled={!email || !password || emailError}
                  >
                    Login
                  </Button>
                </>
              ) : null}
              {loginError ? (
                <Typography
                  variant="subtitle2"
                  component="subtitle2"
                  style={{
                    color: "red",
                    width: 230,
                    margin: 10,
                    textAlign: "center"
                  }}
                >
                  {loginError}
                </Typography>
              ) : null}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
