import React, { Suspense } from "react";
import UserStore from "./UserStore";
import MapStore from "./MapStore";
import CarouselStore from "./CarouselStore";
import FiltersStore from "./FiltersStore";
import SubmissionsStore from "./SubmissionsStore";
import SnackbarStore from "./SnackbarStore";

export const Stores = ({ children }) => (
  <Suspense fallback={<h1>Loading...</h1>}>
    <SnackbarStore>
      <UserStore>
        <CarouselStore>
          <FiltersStore>
            <SubmissionsStore>
              <MapStore>{children}</MapStore>
            </SubmissionsStore>
          </FiltersStore>
        </CarouselStore>
      </UserStore>
    </SnackbarStore>
  </Suspense>
);
export default Stores;
