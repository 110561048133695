import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "../translations";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  resources: translations,
  lng: "en",
  // have a common namespace used around the full app
  ns: ["common"],
  defaultNS: "common",
  debug: true,
  nonExplicitWhitelist: true,
  interpolation: {
    escapeValue: false // not needed for react as it does escape per default to prevent xss!
  }
});

export default i18n;
