import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { questionBank } from "../question-bank";

const useStyles = makeStyles()(theme => ({
  infoRow: {
    display: "flex",
    maxWidth: "425px",
    flexWrap: "wrap"
  },
  typInfoItem: {
    width: "195px",
    padding: theme.spacing(1)
  },
  info: {
    fontSize: "0.9rem"
  },
  checklistHeader: {
    padding: theme.spacing(1),
    fontWeight: "bold",
    fontSize: "1.2rem"
  }
}));

const ChecklistAnswers = ({ checklist }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const overrideWidth = checklist.length === 1 ? { width: "100%" } : {};
  return (
    <Grid className={classes.infoRow}>
      {checklist.map((group, i) => (
        <Grid
          key={`checklist-group-${i}`}
          className={classes.typInfoItem}
          style={overrideWidth}
        >
          <Typography
            style={{ textDecoration: "underline", textTransform: "capitalize" }}
          >
            {t(`checklist:${group.question}`)}
          </Typography>
          {group.answers.map((ans, i) => (
            <Typography className={classes.info}>
              {t(`checklist:${ans}`)}
            </Typography>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

const TroubleChecklist = ({ checklist, type }) => {
  const { classes } = useStyles();
  const [formattedChecklist, setFormattedChecklist] = useState([]);

  useEffect(() => {
    let checklist_group = [];
    if (checklist && (type === "inspection" || type === "meter")) {
      checklist.forEach(answer => {
        const split = answer.split(":");
        const index = checklist_group.findIndex(
          group => group.question === split[0]
        );
        if (index >= 0) {
          let group_ans = checklist_group[index].answers;
          group_ans.push(split[1]);
          checklist_group[index].answers = group_ans;
        } else {
          checklist_group.push({
            question: split[0],
            answers: [split[1]]
          });
        }
      });
    } else if (checklist && type === "general") {
      checklist.forEach(cat => {
        let answers = [];
        if (cat.responses && cat.responses.length) {
          cat.responses.forEach(item => {
            const question = questionBank.find(
              quest => quest.id === item.questionId
            );
            let answer = null;
            if (item.answerId) {
              answer = questionBank.find(quest => quest.id === item.answerId);
            }
            let str = question
              ? `• ${question.question || question.id} - `
              : "• ";
            str = answer ? `${str}${answer.answer_title}` : str;
            /**str = item.customAnswerText
              ? `${str} - ${item.customAnswerText}`
              : str;*/

            answers.push(str);
            item.customAnswerText && answers.push(`• ${item.customAnswerText}`);
          });
        }
        checklist_group.push({
          question: cat.id,
          answers
        });
      });
    }
    setFormattedChecklist(checklist_group);
  }, [checklist]);
  return (
    <Grid item>
      <Typography
        className={classes.checklistHeader}
        style={{ paddingBottom: 0 }}
      >
        Checklist
      </Typography>
      <ChecklistAnswers checklist={formattedChecklist} />
    </Grid>
  );
};

export default TroubleChecklist;
