import React, { useEffect, useState, useContext } from "react";
import ReactDOMServer from "react-dom/server";
import { Grid, Badge } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { CSSTransition } from "react-transition-group";
import SubmissionInfoWindow from "./SubmissionInfoWindow";
import { useFirestore } from "reactfire";
import {
  CarouselContext,
  MapContext,
  SubmissionsContext
} from "../../contexts";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles()(theme => ({
  typography: {
    padding: theme.spacing(1)
  },
  root: {
    minWidth: 275
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  "alert-enter": {
    opacity: 0,
    transform: "scale(0.9)"
  },
  "alert-enter-active": {
    opacity: 1,
    transform: "translateX(0)",
    transition: "opacity 300ms, transform 300ms"
  },
  "alert-exit": {
    opacity: 1
  },
  "alert-exit-active": {
    opacity: 0,
    transform: "scale(0.9)",
    transition: "opacity 300ms, transform 300ms"
  },
  markerImage: {
    position: "absolute",
    top: "-35px",
    left: "-15px",
    cursor: "pointer"
  }
}));

const AnimatedMarker = ({
  submission,
  lat,
  lng,
  firstLoad = true,
  currentInfo,
  setCurrentInfo
}) => {
  const { classes } = useStyles();

  const firestore = useFirestore();
  const [transitioning, setTransitioning] = useState(firstLoad);
  const [images, setImages] = useState([]);
  const history = useHistory();

  const { setCarouselInfo } = useContext(CarouselContext);
  const { infoWindowToOpenId, setInfoWindowToOpenId, map, liveFeed } =
    useContext(MapContext);
  const { updateSubmissionState } = useContext(SubmissionsContext);

  useEffect(() => {
    const updateImages = querySnapshot => {
      const snapImages = [];
      querySnapshot.forEach(async doc => {
        const sub = doc.data();
        sub.id = doc.id;

        snapImages.push(sub);
      });
      setImages(snapImages);
    };

    const unsubscribe = firestore
      .collection("submission_files")
      .where("group_id", "==", submission.group_id)
      .onSnapshot(updateImages);
    return () => {
      unsubscribe();
    };
  }, []);

  const showInfoWindow = () => {
    if (currentInfo) {
      currentInfo.info.close();
    }

    const onViewSubmission = () => {
      const beginning = liveFeed ? "/live-feed" : "";
      history.push(`${beginning}/carousel/${submission.group_id}`);
      setCarouselInfo(submission, images, 0);
    };

    const infoWindowContent = (
      <SubmissionInfoWindow
        submission={submission}
        images={images}
        small={true}
        onViewSubmission={onViewSubmission}
      />
    );
    const info = new window.google.maps.InfoWindow({
      content: ReactDOMServer.renderToString(infoWindowContent),
      position: { lat, lng }
    });
    window.google.maps.event.addListener(info, "domready", () => {
      images.forEach((img, i) => {
        document
          .getElementById(img.download_link)
          .addEventListener("click", () => {
            const beginning = liveFeed ? "/live-feed" : "";
            history.push(`${beginning}/carousel/${submission.group_id}`);
            setCarouselInfo(submission, images, i);
          });
      });
      document
        .getElementById(`${submission.group_id}-view-btn`)
        .addEventListener("click", () => {
          const beginning = liveFeed ? "/live-feed" : "";
          history.push(`${beginning}/carousel/${submission.group_id}`);
          setCarouselInfo(submission, images, 0);
        });
    });
    info.open(map);
    setCurrentInfo({ info, subId: submission.id });
    updateSubmissionState(submission);
  };

  useEffect(() => {
    if (
      images.length &&
      infoWindowToOpenId &&
      infoWindowToOpenId === submission.id
    ) {
      showInfoWindow();
      setInfoWindowToOpenId(null);
    }
  }, [infoWindowToOpenId, images]);

  return (
    <Grid container direction="column">
      <CSSTransition
        in={transitioning}
        timeout={300}
        classNames="alert"
        unmountOnExit
        onEnter={() => setTransitioning(false)}
        onExited={() => setTransitioning(true)}
      >
        <Badge
          color="secondary"
          variant="dot"
          invisible={!submission.new}
          className={classes.markerImage}
        >
          <img
            src={
              submission.mode === "normal"
                ? require("../../images/pin-sm.png")
                : require("../../images/pin-red.png")
            }
            onClick={showInfoWindow}
            className={classes.markerImage}
            alt={`marker-${submission.id}`}
          />
        </Badge>
      </CSSTransition>
    </Grid>
  );
};
export default AnimatedMarker;
