import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Typography, CircularProgress } from "@mui/material";
import { csvTableSorter, csvTableInfo } from "./table-utils";
import EnhancedTable from "../ui/EnhancedTable/EnhancedTable";
import firebase from "firebase";
import moment from "moment";
import LoadingPage from "../ui/LoadingPage";
import { UserContext } from "../../contexts";

const DataDownload = () => {
  const [csvListRefs, setCSVListRefs] = useState([]);
  const [csvList, setCSVList] = useState([]);
  const [nightlyDownloadUrl, setNightlyDownloadUrl] = useState(null);
  const [submissionDownloadUrl, setSubmissionDownloadUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const { accessList } = useContext(UserContext);

  const meterAccess =
    accessList && accessList.find(item => item === "daily-meter");
  const dailyAccess =
    accessList && accessList.find(item => item === "daily-all");
  const nightlyAccess =
    accessList && accessList.find(item => item === "nightly");
  const submissionAccess =
    accessList && accessList.find(item => item === "submission");

  const storage = firebase
    .app()
    .storage("gs://nbp-delta-csv-assets-prod")
    .ref();

  const nightlyStorage = firebase
    .app()
    .storage("gs://nbp-csv-assets-prod")
    .ref();

  const submissionStorage = firebase
    .app()
    .storage("gs://gis-csv-exports")
    .ref();

  const meterStorage = firebase
    .app()
    .storage("gs://nbp-meter-csv-assets-prod")
    .ref();
  useEffect(() => {
    const getMeterList = list => {
      meterStorage.listAll().then(res => {
        const meterList = res.items.map(item => {
          item.type = "meter";
          return item;
        });
        setCSVListRefs([...list, ...meterList]);
      });
    };

    const flagAccess = () => {
      setLoading(false);
      setNoAccess(true);
    };

    dailyAccess
      ? storage
          .listAll()
          .then(res => {
            let list = res.items;
            meterAccess ? getMeterList(list) : setCSVListRefs(list);
          })
          .catch(error => {
            console.log(error);
          })
      : meterAccess
      ? getMeterList([])
      : !nightlyAccess && !submissionAccess && flagAccess();

    nightlyAccess
      ? nightlyStorage.listAll().then(res => {
          let list = res.items;
          let counter = 0;
          let latest = null;
          list.forEach(item => {
            item.getMetadata().then(meta => {
              if (
                !latest ||
                (latest && meta.timeCreated > latest.timeCreated)
              ) {
                latest = item;
                latest.timeCreated = meta.timeCreated;
              }
              counter++;
              if (counter === list.length) {
                latest.getDownloadURL().then(url => {
                  setNoAccess(false);
                  setLoading(false);
                  setNightlyDownloadUrl(url);
                });
              }
            });
          });
        })
      : setNightlyDownloadUrl(null);

    submissionAccess
      ? submissionStorage
          .child(`pano-asset-gis-update.csv`)
          .getDownloadURL()
          .then(url => {
            setNoAccess(false);
            setLoading(false);
            setSubmissionDownloadUrl(url);
          })
      : setSubmissionDownloadUrl(null);
  }, [accessList]);

  useEffect(() => {
    let list = csvListRefs;
    let counter = 0;
    setNoAccess(false);
    csvListRefs.forEach((itemRef, i) => {
      let info = {
        name: `Daily Updates - ${
          itemRef.type === "meter" ? "Meter" : "All Data"
        }`,
        id: `csv-list-item-${i}`
      };
      Promise.all([itemRef.getMetadata(), itemRef.getDownloadURL()]).then(
        promisedResults => {
          promisedResults.forEach(result => {
            if (result.timeCreated) {
              info.date = moment(result.timeCreated)
                .subtract(1, "days")
                .toDate();
              info.creation = result.timeCreated;
            } else {
              info.url = result;
            }
          });
          list[i] = info;
          counter++;

          if (counter === csvListRefs.length) {
            let filtered = list.filter(item => !!item.url);
            const sevenDays = moment().subtract(8, "days");
            filtered = filtered.filter(item => moment(item.date) > sevenDays);
            setCSVList(filtered);
            setLoading(false);
          }
        },
        () => {
          counter++;
        }
      );
    });
  }, [csvListRefs]);

  return loading ? (
    <LoadingPage />
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ padding: 6 }}
    >
      {noAccess && <Typography>Page Access Denied</Typography>}
      {(meterAccess || dailyAccess) && csvList.length ? (
        <Grid item style={{ width: "70%" }}>
          <EnhancedTable
            info={csvList}
            tableInfo={csvTableInfo}
            sortAccessor={csvTableSorter}
            toolbar={{ title: "Daily Updates", hideCheckbox: true }}
          />
        </Grid>
      ) : meterAccess || dailyAccess ? (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          style={{ height: 200 }}
        >
          <CircularProgress
            size={70}
            thickness={4}
            style={{
              strokeLinecap: "round",
              color: "#0775e3"
            }}
          />
        </Grid>
      ) : null}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {nightlyDownloadUrl && (
          <Grid style={{ margin: 10 }}>
            <Button
              variant="contained"
              color="primary"
              href={nightlyDownloadUrl}
            >
              Download Full Image List (Nightly)
            </Button>
          </Grid>
        )}
        {submissionDownloadUrl && (
          <Grid style={{ margin: 10 }}>
            <Button
              variant="contained"
              color="primary"
              href={submissionDownloadUrl}
            >
              Download Full Submission List
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DataDownload;
