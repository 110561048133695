import React from "react";
import { CircularProgress, Grid } from "@mui/material";

const LoadingPage = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: 500 }}
    >
      <CircularProgress
        size={70}
        thickness={4}
        style={{
          strokeLinecap: "round",
          color: "#0775e3"
        }}
      />
    </Grid>
  );
};

export default LoadingPage;
