import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import EnhancedTable from "../ui/EnhancedTable/EnhancedTable";

const ManagementSection = ({
  title,
  tableInfo,
  info,
  sortAccessor,
  onAdd,
  md,
  onRowSelected,
  buttonText,
  toolbar,
  clearSearch
}) => (
  <Grid item xs={12} md={md || 6}>
    {title ? (
      <Typography
        style={{ flex: "1 1 100%", paddingLeft: 3 }}
        variant="h6"
        id="managementSectionTitle"
        component="div"
        gutterBottom
      >
        {title}
      </Typography>
    ) : null}
    <EnhancedTable
      info={info}
      tableInfo={tableInfo}
      onRowSelected={onRowSelected}
      sortAccessor={sortAccessor}
      toolbar={toolbar}
      clearSearch={clearSearch}
    />
    <Grid
      style={{
        justifyContent: "flex-end",
        display: "flex",
        padding: "4px 0"
      }}
    >
      {onAdd && (
        <Button variant="contained" color="primary" onClick={onAdd}>
          {buttonText || "Add"}
        </Button>
      )}
    </Grid>
  </Grid>
);

export default ManagementSection;
