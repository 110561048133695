export default {
  common: {
    home: "Home",
    add: "Add",
    edit: "Edit",
    name: "Name",
    no: "No",
    yes: "Yes",
    saveChanges: "Save Changes",
    email: "Email",
    date: "Date",
    delete: "Delete",
    troubleEmail: "Trouble Mode Email",
    password: "Password",
    submit: "Submit",
    description: "Description"
  },
  stats: {
    submissions: "Submissions",
    normal: "Normal",
    trouble: "Trouble",
    photos: "Photos"
  },
  filters: {
    date: "Date",
    from: "From",
    to: "To",
    users: "Users",
    tags: "Tags",
    mode: "Mode",
    asset: "Asset Id",
    filters: "Filters",
    submissionType: "Submission Type",
    district: "Districts",
    critical: "Critical Response",
    troubleStatusEditors: "Trouble Status Editors"
  },
  table: {
    submitted: "Submitted",
    submittedBy: "Submitted By",
    status: "Status",
    address: "Address"
  },
  statusTable: {
    modifiedBy: "Modified By",
    timeModified: "Time Modified",
    changedTo: "Changed To",
    comments: "Comments",
    referenceNumber: "Reference Number"
  },
  tags: {
    tree: "Tree",
    pole: "Pole",
    wire: "Wire",
    other: "Other",
    transformer: "Transformer",
    light: "Light",
    meter: "Meter",
    telecom: "Telecom",
    "field-urgent": "Field - Urgent",
    "field-planned": "Field - Planned",
    animal: "Animal"
  },
  header: {
    logout: "Logout",
    management: "Management",
    home: "Home",
    data: "Data Download",
    live: "Live Feed"
  },
  checklist: {
    accessibility: "Accessibility Issues",
    locked_gate: "Locked Gate",
    locked_door: "Locked Door",
    alarm: "Alarm",
    obstructed: "Obstructed",
    housekeeping: "Housekeeping",
    siding: "Siding",
    other: "Other",
    safety: "Safety Issues",
    dog: "Dog",
    mold: "Mold",
    propane_natural_gas: "Propane & Natural Gas",
    propane: "Propane",
    natural_gas: "Natural Gas",
    other_issues: "Other Issues",
    electrical_entrance: "Electrical Entrance",
    meterChecklist: "View Checklist"
  },
  status: {
    submitted: "Submitted",
    complete_no_action: "Review Complete - No action for NB Power at this time",
    complete_action_work_order:
      "Review Complete – Action Required – Work Order created",
    complete_action_field_staff:
      "Review Complete – Action Required – Field Staff Response Initiated",
    complete_action_customer_verification:
      "Review Complete – Action Required – Customer Verification Required",
    pending_review: "Pending Review",
    complete_action: "Review Complete – Action Required",
    updated: "Updated",

    pole: "Pole",
    light: "Light",
    frame: "Frame",
    guywire: "Guywire",
    transformer: "Transformer",
    switch: "Switch",
    false_positive: "False Positive"
  },
  management: {
    displayName: "Display Name",
    email: "Email",
    inspections: "Inspection",
    groups: "Group",
    groupManagement: "Group Management",
    userManagement: "User Management",
    inspectionManagement: "Inspection Management",
    groupExists: "Group name already exists",
    none: "None",
    members: "Members",
    addMembers: "Add Members",
    editGroup: "Edit Group",
    addGroup: "Add Group",
    addUser: "Add User",
    editUser: "Edit User",
    editInspection: "Edit Inspection",
    addInspection: "Add Inspection"
  },
  deleteDialog: {
    confirmMessage:
      "This will deleted the selected items. Do you wish to continue?",
    title: "Delete Items"
  },
  asset: {
    pole: "Pole",
    light: "Light",
    frame: "Frame",
    guywire: "Guywire",
    transformer: "Transformer",
    switch: "Switch",
    false_positive: "False Positive",
    no_detected: "No Assets Found",
    defect: "Defect",
    insulator: "Insulator",
    electrical_primary_wire: "Electrial Primary Wire",
    electrical_secondary_wire: "Electrical Secondary Wire",
    electrical_neutral_wire: "Electrical Neutral Wire",
    telecom_wire: "Telecom Wire",
    telecom_splice_enclosure: "Telecom Splice Enclosure",
    street_light: "Street Light",
    area_light: "Area Light"
  },
  errorMessages: {
    invalidEmail: "Invalid email format",
    existingUserEmail: "There is already an existing user with this email"
  },
  camera: {
    text: "Text Detection",
    barcode: "Barcode Detection",
    manual: "Manual"
  },
  dataDownload: {
    creationDate: "Creation Date"
  },
  inspection: {
    icon: "Icon",
    cameraDetection: "Camera Detection Type",
    trouble: "Trouble Mode Checklist",
    disabled: "Disabled",
    enabled: "Enabled",
    checklist: "Checklist",
    addCat: "Add Category",
    editCat: "Edit Category",
    answers: "Answers",
    deleteCat: "Delete Category",
    deleteMessage: "Are you sure you wish to delete this category?"
  },
  notifications: {
    unsupportedBrowser: "This browser does not support desktop notification",
    notificationEnabled: "Notfications enabled for live feed",
    notificationAdvice:
      "A new trouble submission has been added. Allow notification in settings for Pano to see new trouble submissions"
  },
  assetType: {
    existing: "Existing Meter",
    existing_surroundings: "Existing Meter Surroundings",
    new_meter: "New Meter",
    new_surroundings: "New Meter Surroundings",
    base: "Meter Base",
    asset: "Asset Type"
  },
  help: {
    title: "Help",
    started: "Getting Started",
    submitting: "Submitting",
    safety: "Safety Minute",
    meterFlowTitle: "Inspections",
    photoMapSubmission: "View a Submission on Map",
    flatListView: " List of Submissions",
    relatedSubmissions: "Submissions of the Same Asset",
    filterSubmissions: "Filter Submissions on Map",
    imageReview: "Image Review",
    assetTip: "Assets",
    troubleChecklist: "Trouble Mode Checklist",
    viewStatus: "View Status Updates",
    introMessage:
      "There are 2 modes on the bottom of the screen which can be selected, Normal Mode or Trouble Mode. \n\nYou can also select a tag at the bottom that best describes the asset or scenario that you are taking a picture of. \n\nFinally, to begin a submission, tap on the capture button to either take a photo or a video clip. \n\nPlease note that we would like one submission (picture or set of pictures) per asset.",
    topicBody1:
      "In normal mode, you can submit photos of NB Power assets in their regular condition to help us keep our inventory of asset photos up to date. Tag the asset you are submitting by selecting one of the buttons at the bottom.",
    topicBody2:
      "In trouble mode, you can submit photos of issues with equipment for our experts to review. This could be a tree near a line, leaning pole, streetlight on all day, bird nest on equipment, etc. \n\nIn this mode, you can indicate why you are taking the photo by selecting the appropriate tag at the bottom (you can select from Tree, Wire, Pole, Transformer, Light, Meter, Animal and Other) and answer a few questions after taking the picture to help us understand more about the situation.",
    topicBody3:
      "The gallery icon allows you to select images from your camera roll to add to a submission.",
    topicBody4:
      "The video option enables you to record a video of an asset instead of a photo.",
    topicBody5:
      "For each submission, use the map provided to determine the exact location of the asset. This can be done by moving the map until the pin is in the desired location. To assist you with this, we have added an overlay of our NB Power poles, so you can move the map until the pin is over the pole with the equipment or situation you are taking a photo of. \n\nYou may choose the Street View icon to the right of the “Done” button on this screen to view the location you pinned.  If you need to correct it, return to the map screen and make your adjustments.",
    topicBody6:
      "Before your submission is submitted, be sure to review your asset pictures. If you need to delete an image, tapping on the trash icon will remove it from your submission. As well, you can add images from your gallery or take a new picture by clicking on the gallery or camera icon next to your submission images. You can also review anything entered in a checklist if the photo was taken in trouble mode with certain tags. You may also at this time add any additional notes to your submission that you wish (text box will not show if you entered information in a text box in a Trouble mode checklist).",
    topicBody7:
      "Inspections can be done by selecting an inspection from the hamburger menu.",
    topicBody8:
      'When you use the "Meter Tag" on the main screen to navigate to inspect a meter, a dialog box will appear for you to confirm or cancel the action.',
    topicBody9:
      "You will need to select at least one of the issues from the checklist when making a submission for an inspection in trouble mode",
    topicBody10:
      "You may view, edit, and view status updates of previous submissions by clicking on the hamburger menu in the upper left-hand corner and selecting “Previous Submissions”. You can toggle between a map and a list view of previous submissions by clicking on the orange toggle button at the bottom of the screen.",
    topicBody11:
      "On the map view you may zoom-in on a cluster of submissions to a single submission or group of submissions at the same location and tap to view more details or edit the submission.",
    topicBody12:
      "You can also filter for submissions by opening the filter tray when you tap on the up-pointing arrow at the bottom of the submissions map screen. You filter submissions by providing details in the fields and/or by selecting asset tags and/or submission mode.",
    topicBody13:
      "From the list view, you may tap on a submission to also view other details or edit the submission.",
    topicBody14:
      "When reviewing a submission, you can also view related submissions to the asset by tapping on the asset ID when you are in read mode of the submission.",
    topicBody15:
      "When reviewing a submission, you can also view the asset Time Created and Time Updated of that submission.",
    topicBody16:
      "While using this app, and at all times, stay aware of your surroundings and be safe! Always stay at least the length of a bus (30 feet or 10 meters) away from any downed power lines, and always watch for traffic or other potential hazards. \n\n If there is a dangerous or life-threatening situation, and you don't have the appropriate training and certifications to respond to the issue, STOP, STAY BACK, and please call 911.",
    topicBody17:
      "Once you take a photo, you will be able to review it and have the option to retake the photo if not satisfied, Add more photos of the same asset (different angles for example), Edit (here you can mark up the photo to indicate areas of importance), and if satisfied, then move to the Next step.",
    topicBody18:
      "If you are taking a photo in trouble mode, we will ask you to answer a few questions to help us better understand the situation.\n\nPlease click on the appropriate option as the questions come up. You may also be asked to enter additional details in a text box.\n\nIf you indicate that there is a serious situation that we should know about right away, we provide you with a safety reminder and give you the option to contact NB Power within Pano so that you can report the issue to us right away.",
    topicBody19:
      "When in the Previous Submission screen, you can select a previous submission, and click on “View Status” to see any updates or changes in status. "
  }
};
