import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox
} from "@mui/material";
import { useTranslation } from "react-i18next";
import colors from "../colors";

const EnhancedTableHeader = ({
  classes,
  order,
  orderBy,
  onRequestSort,
  columns,
  toolbar,
  numSelected,
  rowCount,
  onSelectAllClick
}) => {
  const { t } = useTranslation();
  return (
    <TableHead style={{ background: colors.headerGray }}>
      <TableRow>
        {toolbar && !toolbar.hideCheckbox ? (
          <TableCell
            padding="checkbox"
            style={{
              background: colors.headerGray
            }}
          >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all" }}
            />
          </TableCell>
        ) : null}
        {columns.map(({ id, numeric, disablePadding, label }) => (
          <TableCell
            key={id}
            align={numeric ? "right" : "left"}
            padding={disablePadding ? "none" : "normal"}
            sortDirection={orderBy === id ? order : false}
            style={{
              color: "white",
              fontWeight: "bold",
              background: colors.headerGray
            }}
          >
            <TableSortLabel
              active={orderBy === id}
              direction={orderBy === id ? order : "asc"}
              onClick={() => onRequestSort(id)}
              style={{ color: "white", fontWeight: "bold" }}
            >
              {t(label)}
              {orderBy === id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHeader;
