import { TagType } from "../constants";
const emergencyCommon = {
  answer_title: "Yes",
  activateEmergencyCall: true
};

const commonWire = [{ id: 30003 }, { id: 30004 }];

export const questionBank = [
  {
    id: `${TagType.POLE} - Pole Broken`,
    answer_title: "Pole Broken",
    question: "Is the pole?",
    answers: [
      { id: `${TagType.POLE} - Pole Broken - Standing` },
      { id: `${TagType.POLE} - Pole Broken - On The Ground` }
    ]
  },

  { ...emergencyCommon, id: 32111 },
  {
    id: 3223,
    answer_title: "No",
    question: "Wires Low",
    answers: [{ id: 7787 }, { id: 32312 }]
  },
  { ...emergencyCommon, id: 7787 },
  {
    id: 32312,
    answer_title: "No",
    question: "How long has it been that way?",
    answers: [{ id: 1111 }, { id: 43322232 }]
  },
  {
    id: 30001,
    answer_title: "Pole with wire(s) to another pole",
    question: "Is it blocking a road or access to a home?",
    answers: commonWire
  },
  { ...emergencyCommon, id: 30003 },
  {
    id: 30004,
    answer_title: "No",
    question: "Wires Down?",
    answers: [{ id: 30005 }, { id: 30006 }]
  },
  { ...emergencyCommon, id: 30005 },
  {
    id: 30006,
    answer_title: "No",
    question: "Wires Low?",
    answers: [{ id: 30009 }, { id: 30010 }]
  },
  { ...emergencyCommon, id: 30009 },
  {
    id: 30010,
    answer_title: "No",
    question: "Is there smoke / sparks?",
    answers: [{ id: 30011 }, { id: 30012 }]
  },
  { ...emergencyCommon, id: 30011 },
  {
    id: 30012,
    answer_title: "No",
    question: "Describe Additional Details",
    textInput: true
  },

  {
    id: 30013,
    answer_title: "Wires between poles",
    question: "Is it blocking a road or access to a home?",
    answers: commonWire
  },

  {
    id: 30014,
    answer_title: "Between a pole and a building",
    question: "Is it blocking a road or access to a home?",
    answers: commonWire
  },
  {
    id: 30002,
    answer_title: "Pole with wire(s) to a building",
    question: "Is it blocking a road or access to a home?",
    answers: commonWire
  },
  { id: 1111, answer_title: "Recent" },
  { answer_title: "More than a week", id: 43322232 },
  {},
  {
    id: `${TagType.POLE} - Pole Broken - Standing`,
    question:
      "Is the issue for a pole with wire(s) to another pole, or with wire(s) to a building?",
    answer_title: "Standing",
    answers: [{ id: 30001 }, { id: 30002 }]
  },
  {
    id: `${TagType.POLE} - Pole Broken - On The Ground`,
    answer_title: "On The Ground",
    question:
      "Is the issue for a pole with wire(s) to another pole, or with wire(s) to a building?",
    answers: [{ id: 30001 }, { id: 30002 }]
  },
  {
    id: `${TagType.POLE} - Pole Leaning`,
    question:
      "Is the issue for a pole with wire(s) to another pole, or with wire(s) to a building?",
    answer_title: "Pole Leaning",
    answers: [{ id: 30001 }, { id: 30002 }]
  },
  {
    id: 565,
    answer_title: "Something Else",
    question: "Something Else",
    answers: [
      {
        id: 8988
      },
      { id: 6754 }
    ]
  },
  {
    id: 8988,
    answer_title: "Equipment Arcing",
    activateEmergencyCall: true
  },
  { id: 6754, answer_title: "Anything Else", textInput: true },
  {
    id: 4334,
    answer_title: "Flashing/Seeing Arcing",
    activateEmergencyCall: true
  },
  {
    id: 3,
    answer_title: "Leaking / Seeing Liquid",
    activateEmergencyCall: true
  },
  {
    id: 98,
    answer_title: "Heard a bang / sounds",
    activateEmergencyCall: true
  },
  {
    id: 5001,
    answer_title: "Between a pole and a building",
    question: "Has the tree fallen or has it grown into the power lines?",
    answers: [
      { id: 5003 },
      {
        id: 5004
      }
    ]
  },
  {
    id: 5000,
    answer_title: "Wires between poles",
    question: "Has the tree fallen or has it grown into the power lines?",
    answers: [
      { id: 5003 },
      {
        id: 5004
      }
    ]
  },
  {
    id: 5003,
    answer_title: "Fallen",
    question: "Is it blocking a road or access to a home?",
    answers: [{ id: 5018 }, { id: 5019 }]
  },
  {
    id: 5004,
    answer_title: "Grown into power lines",
    question: "Is it blocking a road or driveway?",
    answers: [
      { id: 5020 },
      {
        id: 5021
      }
    ]
  },
  {
    id: 5020,
    answer_title: "Yes",
    question: "Wires Down?",
    answers: [
      { id: 5005 },
      {
        id: 5006
      }
    ]
  },
  {
    id: 5021,
    answer_title: "No",
    question: "Wires Down?",
    answers: [
      { id: 5005 },
      {
        id: 5006
      }
    ]
  },
  { ...emergencyCommon, id: 5005 },
  {
    id: 5006,
    answer_title: "No",
    question: "Wires Low?",
    answers: [
      { id: 5007 },
      {
        id: 5022
      }
    ]
  },
  { ...emergencyCommon, id: 5007 },
  {
    id: 5022,
    answer_title: "No",
    question: "Is there smoke / sparks?",
    answers: [{ id: 5023 }, { id: 5008 }]
  },
  { ...emergencyCommon, id: 5023 },
  {
    id: 5008,
    answer_title: "No",
    question: "Is it pulling on the mast?",
    answers: [
      { id: 5009 },
      {
        id: 5010
      }
    ]
  },
  { ...emergencyCommon, id: 5009 },
  {
    id: 5010,
    answer_title: "No",
    question: "Describe Additional Details",
    textInput: true
  },

  { ...emergencyCommon, id: 5018 },
  {
    id: 5019,
    answer_title: "No",
    question: "Wires Down?",
    answers: [
      { id: 5011 },
      {
        id: 5012
      }
    ]
  },
  { ...emergencyCommon, id: 5011 },
  {
    id: 5012,
    answer_title: "No",
    question: "Wires Low?",
    answers: [{ id: 5013 }, { id: 5024 }]
  },
  {
    id: 5024,
    answer_title: "No",
    question: "Is there smoke / sparks?",
    answers: [{ id: 5025 }, { id: 5014 }]
  },
  { ...emergencyCommon, id: 5025 },
  { ...emergencyCommon, id: 5013 },
  {
    id: 5014,
    answer_title: "No",
    question: "Is it pulling on the mast?",
    answers: [{ id: 5015 }, { id: 5016 }]
  },

  { ...emergencyCommon, id: 5015 },
  {
    id: 5016,
    answer_title: "No",
    textInput: true
  },
  { id: 60007, answer_title: "Light Not On" },
  { id: 60008, answer_title: "Light Flickering" },
  {
    id: 60009,
    answer_title: "Damage To Light",
    activateEmergencyCall: true
  },
  { id: 60006, answer_title: "Light on All Day" },
  {
    id: 900001,
    answer_title: "Damage to Meter",
    activateEmergencyCall: true
  },
  {
    id: 900002,
    answer_title: "Damage to Mast",
    activateEmergencyCall: true
  },
  {
    id: 1100011,
    answer_title: "Something Else",
    textInput: true
  },
  {
    id: TagType.POLE,
    question: "Pole Issues",
    answers: [
      { id: `${TagType.POLE} - Pole Broken` },
      { id: `${TagType.POLE} - Pole Leaning` },
      { id: 565 }
    ]
  },
  {
    id: TagType.TRANSFORMER,
    question: "Transformer Issues",
    answers: [{ id: 4334 }, { id: 3 }, { id: 98 }, { id: 1100011 }]
  },
  {
    id: TagType.TREE,
    question:
      "Is the tree causing issues for wires between poles or between a pole and a building?",
    answers: [{ id: 5001 }, { id: 5000 }]
  },
  {
    id: TagType.LIGHT,
    title: "Street/Area/Flood Light Checklist",
    question: "Light Issues",
    answers: [{ id: 60007 }, { id: 60008 }, { id: 60009 }, { id: 60006 }]
  },
  {
    id: TagType.METER,
    question: "Meter Issues",
    answers: [{ id: 900001 }, { id: 900002 }]
  },
  {
    id: TagType.WIRE,
    question:
      "Is the issue for wires between poles or between a pole and a building",
    answers: [{ id: 30013 }, { id: 30014 }]
  }
];
