import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  OutlinedInput
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { usersTableInfo, userTableSorter } from "./table-utils";
import { useTranslation } from "react-i18next";
import ManagementSection from "./management-section";
import ManagementEditModal from "./management-edit-modal";
import ChipAutoComplete from "../ui/ChipAutoComplete";
import ChecklistGroup from "../ui/ChecklistGroup";
import { isEqual } from "lodash";

const useStyles = makeStyles()(theme => ({
  formControl: {
    margin: theme.spacing(2)
    //minWidth: 500
  },
  selectEmpty: {
    minWidth: 240,
    width: "100%"
  },
  table: {
    marginTop: theme.spacing(2)
  },
  chips: {
    maxWidth: 700,
    margin: theme.spacing(1),
    minWidth: 500,
    width: "90%"
  }
}));

const GroupManagement = ({
  inspections,
  groupInfo = {
    group_inspection: "",
    group_members: [],
    group_name: "",
    group_csv_access: [],
    group_description: ""
  },
  onGroupManagementChange,
  users,
  nameError
}) => {
  const [inspection, setInspection] = useState(groupInfo.group_inspection);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [currentMembers, setCurrentMembers] = useState(groupInfo.group_members);
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const [csvList, setCsvList] = useState([
    {
      id: "daily-meter",
      label: "Daily Update - Meter",
      checked: !!(
        groupInfo.group_csv_access &&
        groupInfo.group_csv_access.find(item => item === "daily-meter")
      )
    },
    {
      id: "daily-all",
      label: "Daily Update - All Data",
      checked: !!(
        groupInfo.group_csv_access &&
        groupInfo.group_csv_access.find(item => item === "daily-all")
      )
    },
    {
      id: "nightly",
      label: "Full Image List",
      checked: !!(
        groupInfo.group_csv_access &&
        groupInfo.group_csv_access.find(item => item === "nightly")
      )
    },
    {
      id: "submission",
      label: "Full Submission List",
      checked: !!(
        groupInfo.group_csv_access &&
        groupInfo.group_csv_access.find(item => item === "submission")
      )
    }
  ]);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const onSave = () => {
    onGroupManagementChange({ ...groupInfo, group_members: currentMembers });
    setShowStatusModal(false);
  };

  const onCsvChange = ({ target: { name, checked } }) => {
    let list = [...csvList];
    const index = csvList.findIndex(item => item.id === name);
    list[index].checked = checked;
    setCsvList(list);
    let access = groupInfo.group_csv_access
      ? [...groupInfo.group_csv_access]
      : [];
    if (checked) {
      access.push(list[index].id);
    } else {
      access = groupInfo.group_csv_access.filter(
        item => item !== list[index].id
      );
    }
    onGroupManagementChange({ ...groupInfo, group_csv_access: access });
  };

  return (
    <form
      autoComplete="off"
      className={classes.formControl}
      name="group-management"
    >
      <Grid container direction="row" spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            id="group_name"
            label={t("common:name")}
            variant="outlined"
            onChange={({ target }) =>
              onGroupManagementChange({
                ...groupInfo,
                group_name: target.value
              })
            }
            defaultValue={groupInfo.group_name}
            className={classes.selectEmpty}
            helperText={nameError ? t("management:groupExists") : null}
            error={nameError}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormControl variant="outlined" className={classes.selectEmpty}>
            <InputLabel
              id="inspections-select-label"
              shrink
              ref={inputLabel}
              htmlFor="outlined-inspections-always-notched"
              style={{ backgroundColor: "white", padding: "0px 4px" }}
            >
              {t("management:inspections")}
            </InputLabel>
            <Select
              id="inspections-select"
              value={inspection}
              displayEmpty
              onChange={({ target }) => {
                setInspection(target.value);
                onGroupManagementChange({
                  ...groupInfo,
                  group_inspection: target.value
                });
              }}
              input={
                <OutlinedInput
                  notched={true}
                  name="inspections-input"
                  data-testID="inspection-select"
                  id="outlined-inspections-always-notched"
                  labelWidth={labelWidth}
                />
              }
              inputProps={{
                testID: "inspection-select"
              }}
            >
              <MenuItem key="" value="">
                {t("management:none")}
              </MenuItem>
              {inspections.map(inspect => (
                <MenuItem key={inspect.id} value={inspect.id}>
                  {inspect.inspection_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item className={classes.table}>
        <TextField
          id="group_description"
          label={t("common:description")}
          variant="outlined"
          onChange={({ target }) =>
            onGroupManagementChange({
              ...groupInfo,
              group_description: target.value
            })
          }
          defaultValue={groupInfo.group_description}
          className={classes.selectEmpty}
          multiline={true}
          fullWidth={true}
        />
      </Grid>
      <Grid container className={classes.table}>
        <ManagementSection
          info={groupInfo.group_members}
          tableInfo={usersTableInfo}
          onRowSelected={() => {}}
          sortAccessor={userTableSorter}
          title={t("management:members")}
          md={12}
          onAdd={() => setShowStatusModal(true)}
          buttonText={
            groupInfo.group_members.length ? t("common:edit") : t("common:add")
          }
        />
      </Grid>
      <div style={{ margin: `-16px` }}>
        <ChecklistGroup
          items={csvList}
          groupLabel="CSV Download Access"
          onCheckboxClick={onCsvChange}
          hideSearchBar={true}
        />
      </div>

      <ManagementEditModal
        open={showStatusModal}
        onClose={() => setShowStatusModal(false)}
        onCancel={() => {
          setCurrentMembers(groupInfo.group_members);
          setShowStatusModal(false);
        }}
        onSave={onSave}
        modalTitle={t("management:addMembers")}
        disabled={
          !currentMembers.length ||
          isEqual(currentMembers, groupInfo.group_members)
        }
        disableUnsavedChanges={
          !currentMembers.length ||
          isEqual(currentMembers, groupInfo.group_members)
        }
      >
        <Grid container item className={classes.chips}>
          <ChipAutoComplete
            options={users}
            fieldLabel={t("filters:users")}
            labelExtractor={option => option.user_display_name}
            onChange={setCurrentMembers}
            getOptionSelected={(option, value) =>
              value.user_email === option.user_email
            }
            value={currentMembers}
          />
        </Grid>
      </ManagementEditModal>
    </form>
  );
};

export default GroupManagement;
