import React, { useContext, useState, useEffect } from "react";
import { Paper, TableContainer, CircularProgress } from "@mui/material";
import GoogleMapReact from "google-map-react";
import AnimatedMarker from "../ui/AnimatedMarker";
import ClusterDialog from "./ClusterDialog";
import Cluster from "./Cluster";
import { MapContext, FiltersContext, SubmissionsContext } from "../../contexts";
import { makeStyles } from "tss-react/mui";

const { REACT_APP_MAP_API_KEY } = process.env;

const useStyles = makeStyles()({
  controls: {
    minWidth: "300px",
    margin: "8px",
    padding: "8px",
    borderRadius: "5px",
    display: "none"
  },
  root: {
    margin: "10px 0 ",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    minHeight: "600px"
  },
  progressContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.35)",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  progress: {
    strokeLinecap: "round",
    color: "#0775e3"
  }
});

const Map = ({ defaultZoom = 13 }) => {
  const { submissions } = useContext(SubmissionsContext);
  const {
    map,
    setMap,
    currentLocation,
    onMapPan,
    autocomplete,
    setAutoComplete,
    setCurrentLocation,
    onMapChange,
    loading,
    clusters,
    supercluster,
    setSearchCity,
    setLastQueriedBounds,
    recenterMap,
    backToMap
  } = useContext(MapContext);

  const { classes } = useStyles();

  const { assetId, active } = useContext(FiltersContext);

  const [clusterDialog, setClusterDialog] = useState(null);

  const [currentInfo, setCurrentInfo] = useState(null);

  useEffect(() => {
    if (currentInfo) {
      const contains = submissions.find(sub => sub.id === currentInfo.subId);

      if (!contains) {
        currentInfo.info.close();
      }
    }
  }, [submissions]);

  useEffect(() => {
    if (assetId && submissions.length === 1 && !active) {
      map.setCenter(
        new window.google.maps.LatLng(
          submissions[0].group_latitude,
          submissions[0].group_longitude
        )
      );
      onMapPan(map);
    } else if (assetId && submissions.length && !active) {
      recenterMap();
    }
  }, [submissions, active]);

  useEffect(() => {
    if ((map && !autocomplete) || (map && backToMap)) {
      const geocoder = new window.google.maps.Geocoder();
      const input = document.getElementById("search-places");
      geocoder.geocode({ address: "New Brunswick" }, results => {
        if (input) {
          input.style.display = "block";
          const options = {
            componentRestrictions: { country: "ca" },
            bounds: results[0].geometry.bounds,
            strictBounds: true
          };

          const searchBox = new window.google.maps.places.Autocomplete(
            input,
            options
          );
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

          searchBox.addListener("place_changed", () => {
            const placeObj = searchBox.getPlace();
            if (placeObj && placeObj.geometry) {
              setSearchCity(true);
              const place = placeObj.geometry;

              setLastQueriedBounds(place.viewport);
              map.fitBounds(place.viewport);
              setCurrentLocation({
                lat: place.location.lat(),
                lng: place.location.lng()
              });
            }
          });
          setAutoComplete(searchBox);
        }
      });
    }
  }, [map, backToMap]);

  const onClusterClick = ({ latitude, longitude, cluster }) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.id),
      22
    );

    if (expansionZoom >= 20) {
      const clusterSubs = supercluster.getLeaves(cluster.id, "infinity");
      const subArr = clusterSubs.map(sub => sub.properties.submission);
      setClusterDialog(subArr);
    } else {
      map.setZoom(expansionZoom);
      map.panTo({ lat: latitude, lng: longitude });
    }
  };

  const prepareMapComponents = (cluster, i) => {
    const [longitude, latitude] = cluster.geometry.coordinates;
    const {
      cluster: isCluster,
      point_count: pointCount,
      submission
    } = cluster.properties;

    return isCluster ? (
      <Cluster
        pointCount={pointCount}
        key={`cluster-${cluster.id}-${i}`}
        lat={latitude}
        lng={longitude}
        onClusterClick={() => onClusterClick({ latitude, longitude, cluster })}
      />
    ) : (
      <AnimatedMarker
        key={submission.id}
        lat={latitude}
        lng={longitude}
        submission={submission}
        currentInfo={currentInfo}
        setCurrentInfo={setCurrentInfo}
      />
    );
  };

  return (
    <TableContainer component={Paper} className={classes.root}>
      <input
        id="search-places"
        className={classes.controls}
        type="search"
        placeholder="Search By Location"
      />

      <GoogleMapReact
        bootstrapURLKeys={{
          key: REACT_APP_MAP_API_KEY,
          libraries: ["geometry", "places"]
        }}
        center={currentLocation}
        defaultZoom={defaultZoom}
        options={() => {
          return {
            mapTypeId: "hybrid",
            gestureHandling: "greedy",
            clickableIcons: false,
            streetViewControl: true
          };
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          setMap(map);
        }}
        onChange={onMapChange}
        onDragEnd={onMapPan}
      >
        {map && clusters.map(prepareMapComponents)}
      </GoogleMapReact>

      {!currentLocation || loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress
            size={70}
            thickness={4}
            className={classes.progress}
          />
        </div>
      ) : null}

      <ClusterDialog
        submissions={clusterDialog}
        onClose={() => setClusterDialog(null)}
      />
    </TableContainer>
  );
};
export default Map;
