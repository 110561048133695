import React, { useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  AccordionDetails,
  Chip,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ClickableIcon from "../ui/ClickableIcon";
import UnsavedChangesDialog from "../ui/UnsavedChangesDialog";
import { useTranslation } from "react-i18next";

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: "0px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const CategoryItem = ({
  category_name,
  answers,
  colour,
  onEdit,
  onDelete,
  id
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const { t } = useTranslation();
  const alternativeMessage = {
    title: t("inspection:deleteCat"),
    description: t("inspection:deleteMessage")
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ fontSize: "1rem", color: "rgba(255,255,255,0.8)" }}
          />
        }
        style={{
          backgroundColor: colour,
          margin: 0
        }}
        testID="Accordian"
      >
        <Typography style={{ color: "#fff" }}>{category_name}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexWrap: "wrap" }}>
        {answers.map((answer, i) => (
          <Chip
            key={`${answer}-${i}-chip`}
            label={answer}
            style={{ margin: 2 }}
          />
        ))}
        <Grid container direction="row" justifyContent="flex-end">
          <ClickableIcon
            title={t("common:edit")}
            IconImage={EditIcon}
            onClick={onEdit}
          />

          <ClickableIcon
            title={t("common:delete")}
            IconImage={DeleteIcon}
            onClick={() => setShowMessage(true)}
          />
        </Grid>
      </AccordionDetails>
      <UnsavedChangesDialog
        open={showMessage}
        onYes={() => {
          setShowMessage(false);
          onDelete(id);
        }}
        onNo={() => setShowMessage(false)}
        alternativeMessage={alternativeMessage}
      />
    </Accordion>
  );
};

export default CategoryItem;
