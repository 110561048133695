import React, { memo, useContext } from "react";
import { Slide, Snackbar, Fade, Grow } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { TransitionTypes } from "../constants";
import { SnackbarContext } from "../../contexts";

const TransitionComponent = props => {
  switch (props.type) {
    case TransitionTypes.FADE:
      return <Fade {...props} />;
    case TransitionTypes.GROW:
      return <Grow {...props} />;
    default:
      return <Slide {...props} direction="up" />;
  }
};

const CustomizableSnackbar = memo(
  ({ duration = 6000, type = TransitionTypes.SLIDE }) => {
    const { snackInfo, setSnackInfo } = useContext(SnackbarContext);
    const { open, type: severity, message } = snackInfo;
    const onCloseSnackbar = () => setSnackInfo({ ...snackInfo, open: false });
    return (
      <Snackbar
        open={open}
        autoHideDuration={duration}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={props => (
          <TransitionComponent {...props} type={type} />
        )}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={onCloseSnackbar}
          severity={severity}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    );
  }
);

export default CustomizableSnackbar;
