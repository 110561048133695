import React from "react";
import { TextField, Grid, FormHelperText, FormControl } from "@mui/material";

import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import ManagementEditModal from "./management-edit-modal";
import ChipAutoComplete from "../ui/ChipAutoComplete";
import { CirclePicker } from "react-color";

const useStyles = makeStyles()(() => ({
  selectEmpty: {
    minWidth: 240,
    width: "100%"
  },
  modalContainer: { padding: 16, width: 600 },
  chipsContainer: { marginLeft: "-2px" },
  formHelper: { marginLeft: "5px" }
}));

const CategoryModal = ({
  currentCategory,
  checklist,
  setCurrentCategory,
  setChecklist
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const onSaveCategory = () => {
    let info = { ...currentCategory };
    let list = [...checklist];
    if (!info.id) {
      info.id = Math.floor(Math.random() * 100000);
      list.push(info);
    } else {
      const index = list.findIndex(item => info.id === item.id);
      if (index > -1) {
        list[index] = info;
      }
    }
    setChecklist(list);
    setCurrentCategory(null);
  };

  return (
    <ManagementEditModal
      open={!!currentCategory}
      onClose={() => setCurrentCategory(null)}
      onCancel={() => setCurrentCategory(null)}
      onSave={onSaveCategory}
      modalTitle={
        currentCategory && currentCategory.id
          ? t("inspection:editCat")
          : t("inspection:addCat")
      }
      disabled={
        currentCategory &&
        (!currentCategory.category_name ||
          !currentCategory.answers.length ||
          !currentCategory.colour)
      }
    >
      <div className={classes.modalContainer}>
        <Grid container spacing={2}>
          <Grid container item direction="row" spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <TextField
                id="category_name"
                required
                label={t("common:name")}
                variant="outlined"
                onChange={({ target }) =>
                  setCurrentCategory({
                    ...currentCategory,
                    category_name: target.value
                  })
                }
                defaultValue={currentCategory && currentCategory.category_name}
                className={classes.selectEmpty}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CirclePicker
                onChangeComplete={({ hex }) =>
                  setCurrentCategory({ ...currentCategory, colour: hex })
                }
                color={currentCategory ? currentCategory.colour : "#000"}
                circleSize={20}
                circleSpacing={8}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.chipsContainer}>
            <FormControl className={classes.selectEmpty}>
              <ChipAutoComplete
                options={[]}
                fieldLabel={t("inspection:answers")}
                onChange={answers =>
                  setCurrentCategory({
                    ...currentCategory,
                    answers
                  })
                }
                freeSolo
                getOptionSelected={() => {}}
                value={currentCategory ? currentCategory.answers : []}
              />

              <FormHelperText
                className={classes.formHelper}
                id="my-helper-text"
              >
                Hit enter after typing to apply your answer
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </ManagementEditModal>
  );
};

export default CategoryModal;
