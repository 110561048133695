import moment from "moment";

export const descendingComparator = (a, b, orderBy, sortAccessor) => {
  const { aProp, bProp } = sortAccessor(a, b, orderBy);
  if (bProp < aProp) {
    return -1;
  }
  if (bProp > aProp) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy, sortAccessor) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, sortAccessor)
    : (a, b) => -descendingComparator(a, b, orderBy, sortAccessor);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const exportToCSV = (data, rowInfo, page) => {
  const csvDate = moment().format("YYYY/MM/DD HH:mm");
  let CSV = "";

  CSV += `Pano Explore ${page} Page Export ${csvDate} \r\n\n`;

  let row =
    "User, Submitted, Submission Type, Mode, Status, Reference Number, Location, Pano Explore Link";
  row = row.slice(0, -1);

  CSV += `${row} \r\n`;

  data.forEach(item => {
    let line = "";
    rowInfo.forEach(({ accessor }) => {
      const formattedLineItem = accessor(item);
      line += `"${formattedLineItem || ""}",`;
    });
    line += `https://pano-admin-frontend-dot-project-volta.uc.r.appspot.com/carousel/${item.group_id}`;
    line.slice(0, line.length - 1);
    CSV += `${line} \r\n`;
  });

  const csvFileName = `PanoExploreReport_${page.replace(
    / /g,
    ""
  )}_${csvDate.replace(/ /g, "-")}`;

  const uri = `data:text/csv;charset=utf-8,${encodeURI(CSV)}`;

  var csvLink = document.createElement("a");
  csvLink.href = uri;

  csvLink.style = "visibility:hidden";
  csvLink.download = csvFileName + ".csv";
  document.body.appendChild(csvLink);
  csvLink.click();
  document.body.removeChild(csvLink);
};
