export const groupNameSort = item => item.group_name.toLowerCase();
export const groupDescriptionSort = item =>
  item.group_description ? item.group_description.toLowerCase() : "";
export const groupTableSorter = (a, b, orderBy) => {
  let bProp = b[orderBy];
  let aProp = a[orderBy];
  switch (orderBy) {
    case "group":
      bProp = groupNameSort(b);
      aProp = groupNameSort(a);
      break;
    case "description":
      bProp = groupDescriptionSort(b);
      aProp = groupDescriptionSort(a);
      break;
    default:
      break;
  }
  return { aProp, bProp };
};

export const groupsTableInfo = {
  columns: [
    { id: "group", label: "management:groups" },
    { id: "description", label: "common:description" }
  ],
  rows: [
    {
      accessor: row => row.group_name,
      otherRowProps: { component: "th", scope: "row" }
    },

    {
      accessor: row => row.group_description
    }
  ],
  startOrder: "group",
  rowsPerPageOptions: [5, 10, 20, 30],
  search: row => row.group_name
};

export const inspectionNameSort = item => item.inspectionName.toLowerCase();
export const inspectionTableSorter = (a, b, orderBy) => {
  let bProp = b[orderBy];
  let aProp = a[orderBy];
  switch (orderBy) {
    case "inspection":
      bProp = inspectionNameSort(b);
      aProp = inspectionNameSort(a);
      break;
    default:
      break;
  }
  return { aProp, bProp };
};
export const inspectionsTableInfo = {
  columns: [{ id: "inspection", label: "management:inspections" }],
  rows: [
    {
      accessor: row => row.inspection_name,
      otherRowProps: { component: "th", scope: "row" }
    }
  ],
  startOrder: "inspections",
  rowsPerPageOptions: [5, 10, 20, 30],
  search: row => row.inspection_name
};

export const userSort = item => item.user_display_name.toLowerCase();
export const emailSort = item => item.user_email.toLowerCase();
export const statusSort = item => item.status;
export const userTableSorter = (a, b, orderBy) => {
  let bProp = b[orderBy];
  let aProp = a[orderBy];
  switch (orderBy) {
    case "displayName":
      bProp = userSort(b);
      aProp = userSort(a);
      break;
    case "email":
      bProp = emailSort(b);
      aProp = emailSort(a);
      break;
    case "status":
      bProp = statusSort(b);
      aProp = statusSort(a);
      break;
    default:
      break;
  }
  return { aProp, bProp };
};

export const usersTableInfo = {
  columns: [
    { id: "displayName", label: "management:displayName" },
    { id: "email", label: "management:email" },
    { id: "status", label: "table:status" }
  ],
  rows: [
    {
      accessor: row => row.user_display_name,
      otherRowProps: { component: "th", scope: "row" }
    },
    {
      accessor: row => row.user_email
    },
    {
      accessor: row => row.status,
      style: { textTransform: "capitalize" }
    }
  ],
  startOrder: "displayName",
  rowsPerPageOptions: [5, 10, 20, 30],
  search: row => row.user_display_name
};

export const checklistTableInfo = {
  columns: [
    { id: "category", label: "checklist:category" },
    { id: "answers", label: "checklist:answers" },
    { id: "colour", label: "checklist:colour" }
  ],
  rows: [
    {
      accessor: row => row.category,
      otherRowProps: { component: "th", scope: "row" }
    },
    {
      accessor: row => row.answers
    },
    {
      accessor: row => row.colour,
      style: { textTransform: "capitalize" }
    }
  ],
  startOrder: "category",
  rowsPerPageOptions: [5, 10, 20, 30],
  search: row => row.category
};
