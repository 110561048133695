import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Grid,
  Input,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import ManagementEditModal from "./management-edit-modal";
import firebase from "firebase";
import { useFirestore } from "reactfire";
import LoadingPage from "../ui/LoadingPage";

const useStyles = makeStyles()(() => ({
  selectEmpty: {
    minWidth: 240,
    width: "100%"
  },
  modalContainer: { padding: 16, minWidth: 900 },
  chipsContainer: { marginLeft: "-2px" },
  formHelper: { marginLeft: "5px" }
}));

const HelpSlideModal = ({
  currentSlide = {
    slide_text: "",
    slide_inspection: "",
    slide_text_fr: "",
    slide_title: "",
    slide_title_fr: ""
  },
  setCurrentSlide,
  setSlides,
  slides,
  inspections
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [image, setImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const firestore = useFirestore();
  const [inspection, setInspection] = useState(
    currentSlide ? currentSlide.slide_inspection : ""
  );
  const inputLabel = useRef();
  const [labelWidth, setLabelWidth] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (image) {
      const objectUrl = URL.createObjectURL(image);
      setImageSrc(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }

    // free memory when ever this component is unmounted
  }, [image]);

  useEffect(() => {
    if (!currentSlide) {
      setImageSrc(null);
      setInspection("");
    } else {
      setInspection(currentSlide.slide_inspection || "");
    }
  }, [currentSlide]);

  const doneSave = (list, id) => {
    setSlides(list);
    if (image) {
      const storage = firebase
        .app()
        .storage("gs://pano-prod-help-screenshots")
        .ref();

      storage
        .child(`slide-${id}-screenshot`)
        .put(image, {
          contentType: image.type
        })
        .then(uploadTask =>
          uploadTask.ref.getDownloadURL().then(download_url => {
            firestore
              .collection("help_slides")
              .doc(id)
              .set({ download_url }, { merge: true })
              .then(() => {
                setCurrentSlide(null);
                setLoading(false);
              });
          })
        );
    } else {
      setCurrentSlide(null);
      setLoading(false);
    }
  };

  const onSaveSlide = async () => {
    setLoading(true);
    let info = { ...currentSlide };
    let list = [...slides];
    if (!info.id) {
      info.order = slides.length;
    }
    const index = list.findIndex(item => info.id === item.id);
    if (index > -1) {
      firestore
        .collection("help_slides")
        .doc(info.id)
        .set(info, { merge: true })
        .then(() => {
          list[index] = info;
          doneSave(list, info.id);
        });
    } else {
      firestore
        .collection("help_slides")
        .add(info)
        .then(docRef => {
          info.id = docRef.id;
          list.push(info);
          doneSave(list, info.id);
        });
    }
  };

  return (
    <ManagementEditModal
      open={!!currentSlide}
      onClose={() => setCurrentSlide(null)}
      onCancel={() => setCurrentSlide(null)}
      onSave={onSaveSlide}
      modalTitle={
        currentSlide && currentSlide.id
          ? t("inspection:editCat")
          : t("inspection:addCat")
      }
      disabled={
        currentSlide &&
        (!currentSlide.slide_title ||
          !currentSlide.slide_text_fr ||
          !currentSlide.slide_text ||
          !currentSlide.slide_title_fr ||
          (!currentSlide.download_url && !image))
      }
    >
      <div className={classes.modalContainer}>
        {loading ? (
          <LoadingPage />
        ) : (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} sm={5}>
              <TextField
                id="slide_title"
                required
                label="Title"
                variant="outlined"
                onChange={({ target }) =>
                  setCurrentSlide({
                    ...currentSlide,
                    slide_title: target.value
                  })
                }
                defaultValue={currentSlide && currentSlide.slide_title}
                className={classes.selectEmpty}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="slide_title_fr"
                required
                label="French Title"
                variant="outlined"
                onChange={({ target }) =>
                  setCurrentSlide({
                    ...currentSlide,
                    slide_title_fr: target.value
                  })
                }
                defaultValue={currentSlide && currentSlide.slide_title_fr}
                className={classes.selectEmpty}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <TextField
                id="slide_text"
                required
                label="Description"
                variant="outlined"
                onChange={({ target }) =>
                  setCurrentSlide({
                    ...currentSlide,
                    slide_text: target.value
                  })
                }
                defaultValue={currentSlide && currentSlide.slide_text}
                className={classes.selectEmpty}
                multiline={true}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                id="slide_text_fr"
                required
                label="French Description"
                variant="outlined"
                onChange={({ target }) =>
                  setCurrentSlide({
                    ...currentSlide,
                    slide_text_fr: target.value
                  })
                }
                defaultValue={currentSlide && currentSlide.slide_text_fr}
                className={classes.selectEmpty}
                multiline={true}
              />
            </Grid>

            <Grid item sm={5} xs={12}>
              <FormControl variant="outlined" className={classes.selectEmpty}>
                <InputLabel
                  id="inspections-select-label"
                  shrink
                  ref={inputLabel}
                  htmlFor="outlined-inspections-always-notched"
                  style={{ backgroundColor: "white", padding: "0px 4px" }}
                >
                  {t("management:inspections")}
                </InputLabel>
                <Select
                  id="inspections-select"
                  value={inspection}
                  displayEmpty
                  onChange={({ target }) => {
                    setInspection(target.value);
                    setCurrentSlide({
                      ...currentSlide,
                      slide_inspection: target.value
                    });
                  }}
                  input={
                    <OutlinedInput
                      notched={true}
                      name="inspections-input"
                      data-testID="inspection-select"
                      id="outlined-inspections-always-notched"
                      labelWidth={
                        inputLabel.current ? inputLabel.current.offsetWidth : 75
                      }
                    />
                  }
                  inputProps={{
                    testID: "inspection-select"
                  }}
                >
                  <MenuItem key="" value="">
                    {t("management:none")}
                  </MenuItem>
                  <MenuItem key="all" value="all">
                    All
                  </MenuItem>
                  {inspections.map(inspect => (
                    <MenuItem key={inspect.id} value={inspect.id}>
                      {inspect.inspection_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid container item xs={12} sm={6} direction="row" spacing={2}>
              {currentSlide && (imageSrc || currentSlide.download_url) ? (
                <img
                  src={imageSrc || currentSlide.download_url}
                  style={{ width: 150, margin: "8px" }}
                />
              ) : null}
              <Input
                type="file"
                onChange={({ target }) => {
                  setImage(target.files[0]);
                }}
                disableUnderline
                inputProps={{ accept: "image/*" }}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </ManagementEditModal>
  );
};

export default HelpSlideModal;
