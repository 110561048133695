import React, { useState, useRef, useContext, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Dialog,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { StatusType, SeverityLevels } from "../../constants";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogTitle from "@mui/material/DialogTitle";
import EnhancedTable from "../EnhancedTable/EnhancedTable";
import { useFirestore, useAuth } from "reactfire";
import { tableSorter, tableInfo } from "./status-table-utils";
import { SnackbarContext } from "../../../contexts";

const useStyles = makeStyles()(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120
  },
  root: {
    padding: theme.spacing(2),
    minWidth: 325
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  tableContaienr: {
    margin: ` 0 ${theme.spacing(2)}`
  }
}));

const StatusModal = ({
  onClose,
  onCancel,
  open,
  currentStatus,
  statusHistory,
  id,
  reference
}) => {
  const { classes } = useStyles();
  const [status, setStatus] = useState(currentStatus);
  const [referenceNum, setReferenceNum] = useState(reference);
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();
  const firestore = useFirestore();
  const commentRef = useRef();
  const auth = useAuth();

  const { openSnackbar } = useContext(SnackbarContext);

  const saveChanges = () => {
    const comments = commentRef.current.value;
    let newHistory = statusHistory;

    let newHistoryItem = {
      comments,
      time_modified: new Date(),
      modified_by: {
        email: auth.currentUser.providerData[0].email,
        display_name: auth.currentUser.providerData[0].displayName
      },
      changed_to: status
    };

    let result = {
      trouble_status: status,
      trouble_status_history: newHistory
    };
    if (referenceNum) {
      result.trouble_status_reference_number = referenceNum;
      newHistoryItem.reference_number = referenceNum;
    }
    newHistory.push(newHistoryItem);

    firestore
      .collection("submissions")
      .doc(id)
      .set(result, { merge: true })
      .then(
        () => {
          openSnackbar({
            type: SeverityLevels.SUCCESS,
            open: true,
            message: "Updated Successfully"
          });
          onClose({
            trouble_status: status,
            trouble_status_history: newHistory,
            trouble_status_reference_number: referenceNum
          });
        },
        () =>
          openSnackbar({
            type: SeverityLevels.ERROR,
            open: true,
            message: "Something went wrong. Please try again later"
          })
      );
  };

  useEffect(() => {
    if (referenceNum !== reference) {
      setDisabled(false);
    } else {
      setDisabled(status === currentStatus);
    }
  }, [status, referenceNum]);

  return (
    <>
      <Dialog
        onClose={() => {
          setStatus(currentStatus);
          onCancel();
        }}
        aria-labelledby="status_dialog"
        open={open}
        maxWidth={false}
        className={classes.root}
      >
        <MuiDialogTitle disableTypography>
          <Typography variant="h5">Status History</Typography>
          {onClose ? (
            <IconButton
              aria-label="close-status-modal"
              className={classes.closeButton}
              onClick={() => {
                setStatus(currentStatus);
                onCancel();
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <Grid className={classes.tableContaienr}>
          <EnhancedTable
            info={statusHistory}
            tableInfo={tableInfo}
            sortAccessor={tableSorter}
          />
        </Grid>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={status}
            onChange={event => setStatus(event.target.value)}
            label="Status"
          >
            {StatusType.allTypes.map(type => (
              <MenuItem key={type} value={type}>
                {t(`status:${type}`)}
              </MenuItem>
            ))}
          </Select>
          <TextField
            style={{ marginTop: 10 }}
            label="Reference Number"
            variant="outlined"
            defaultValue={referenceNum}
            onChange={({ target }) => setReferenceNum(target.value)}
          />
          <TextField
            style={{ marginTop: 10 }}
            label="Comments"
            variant="outlined"
            inputRef={commentRef}
          />
        </FormControl>

        <DialogActions>
          <Button onClick={saveChanges} color="primary" disabled={disabled}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StatusModal;
