import React, { useContext, useState, useEffect } from "react";
import { Grid, CircularProgress, Typography, Button } from "@mui/material";
import {
  groupsTableInfo,
  usersTableInfo,
  inspectionsTableInfo,
  userTableSorter,
  groupTableSorter,
  inspectionTableSorter
} from "./table-utils";
import { useTranslation } from "react-i18next";
import { FiltersContext, UserContext, SnackbarContext } from "../../contexts";
import { useFirestore } from "reactfire";
import ManagementEditModal from "./management-edit-modal";
import ManagementSection from "./management-section";
import GroupManagement from "./group-management";
import UserManagement from "./user-management";
import InspectionManagement from "./inspection-management";
import HelpManagement from "./help-management";
import LoadingPage from "../ui/LoadingPage";
import {
  SeverityLevels,
  CameraDetectionType,
  InspectionIconType
} from "../constants";
import { isEqual } from "lodash";
import { EMAIL_REG } from "../constants";

const Management = () => {
  const { t } = useTranslation();
  const { users } = useContext(FiltersContext);
  const firestore = useFirestore();
  const { adminGroup, userLoading } = useContext(UserContext);

  const [groups, setGroups] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentInfo, setCurrentInfo] = useState(null);
  const [currentForm, setCurrentForm] = useState({ title: null, form: null });

  const { openSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const updateGroups = querySnapshot => {
      const snapGroups = [];
      querySnapshot.forEach(async doc => {
        const sub = doc.data();
        sub.id = doc.id;

        snapGroups.push(sub);
      });
      setGroups(snapGroups);
    };

    const unsubscribe = firestore
      .collection("user_groups")
      .onSnapshot(updateGroups);
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const updateInspections = querySnapshot => {
      const snapInspections = [];
      querySnapshot.forEach(async doc => {
        const sub = doc.data();
        sub.id = doc.id;

        snapInspections.push(sub);
      });
      setInspections(snapInspections);
    };

    const unsubscribe = firestore
      .collection("inspections")
      .onSnapshot(updateInspections);
    return () => {
      unsubscribe();
    };
  }, []);

  const resetModal = () => {
    openSnackbar({
      type: SeverityLevels.SUCCESS,
      open: true,
      message: "Changes Successfully Saved"
    });
    setShowStatusModal(false);
    setCurrentForm({ title: null, form: null });
    setCurrentInfo(null);
    setSaving(false);
  };

  const onError = error => {
    openSnackbar({
      type: SeverityLevels.ERROR,
      open: true,
      message: error.message
    });
    setSaving(false);
  };

  const getCollectionId = () => {
    switch (currentForm.form) {
      case "group":
        return "user_groups";
      case "user":
        return "users";
      case "inspection":
        return "inspections";
      default:
        return null;
    }
  };

  const addUserToGroups = information => {
    const new_groups = [];
    information.groups.forEach(group => {
      const included = groups.find(
        gr =>
          gr.group_members.find(mem => mem.id === information.id) &&
          gr.id === group.id
      );

      if (!included) {
        let gr = group;
        let user_info = { ...information };
        delete user_info.groups;
        gr.group_members.push(user_info);

        new_groups.push(gr);
      } else {
        let gr = included;
        let index = gr.group_members.findIndex(
          mem => mem.id === information.id
        );
        if (
          index > -1 &&
          (gr.group_members[index].user_display_name !==
            information.user_display_name ||
            gr.group_members[index].user_email !== information.user_email)
        ) {
          gr.group_members[index] = {
            ...gr.group_members[index],
            user_display_name: information.user_display_name,
            user_email: information.user_email
          };
          new_groups.push(gr);
        }
      }
    });
    groups.forEach(group => {
      const removed = group.group_members.find(
        mem =>
          mem.id === currentInfo.id &&
          !currentInfo.groups.find(gr => gr.id === group.id)
      );

      if (removed) {
        const newMembers = group.group_members.filter(
          mem => mem.id !== currentInfo.id
        );
        let gr = group;
        gr.group_members = newMembers;
        new_groups.push(gr);
      }
    });

    const collection = firestore.collection("user_groups");
    new_groups.forEach(group => {
      collection.doc(group.id).update(group);
    });

    resetModal();
  };

  const onSave = () => {
    let collectionId = getCollectionId();
    let information = { ...currentInfo };
    let groups = currentInfo.groups;
    if (collectionId) {
      const collection = firestore.collection(collectionId);
      delete information.groups;
      information.id
        ? collection
            .doc(information.id)
            .update(information)
            .then(
              () =>
                collectionId === "users" && groups
                  ? addUserToGroups({ ...information, groups })
                  : resetModal(),
              onError
            )
        : collection
            .add(information)
            .then(
              ({ id }) =>
                collectionId === "users" && groups
                  ? addUserToGroups({ ...information, id, groups })
                  : resetModal(),
              onError
            );
    }
  };

  const showGroupManagement = info => {
    setCurrentInfo(info);
    setCurrentForm({
      form: "group",
      title: info.id ? t("management:editGroup") : t("management:addGroup"),
      originalInfo: { ...info }
    });
    setShowStatusModal(true);
  };

  const showUserManagement = info => {
    const currMemb = [];

    groups.forEach(group => {
      const hasMembership = group.group_members.find(
        member => member.id && info.id === member.id
      );
      if (hasMembership) {
        currMemb.push(group);
      }
    });
    setCurrentInfo({ ...info, groups: currMemb });
    setCurrentForm({
      form: "user",
      title: info.id ? t("management:editUser") : t("management:addUser"),
      originalInfo: { ...info, groups: currMemb }
    });
    setShowStatusModal(true);
  };

  const showInspectionManagement = info => {
    setCurrentInfo(info);
    setCurrentForm({
      form: "inspection",
      title: info.id
        ? t("management:editInspection")
        : t("management:addInspection"),

      originalInfo: { ...info }
    });
    setShowStatusModal(true);
  };

  const getEmailError = () => {
    let emailError = null;
    if (
      (!currentInfo.id &&
        !!users.find(user => user.user_email === currentInfo.user_email)) ||
      (currentInfo.id &&
        !!users.find(
          user =>
            user.user_email === currentInfo.user_email &&
            user.id !== currentInfo.id
        ))
    ) {
      emailError = t("errorMessages:existingUserEmail");
    } else if (
      currentInfo.user_email.length &&
      !!!EMAIL_REG.test(currentInfo.user_email.toLowerCase())
    ) {
      emailError = t("errorMessages:invalidEmail");
    }
    return emailError;
  };

  const getDisabled = () => {
    let disabled = false;
    switch (currentForm.form) {
      case "group":
        disabled =
          !currentInfo.group_name.length ||
          !currentInfo.group_members.length ||
          (!currentInfo.id &&
            !!groups.find(
              group => group.group_name === currentInfo.group_name
            )) ||
          (currentInfo.id &&
            !!groups.find(
              group =>
                group.group_name === currentInfo.group_name &&
                group.id !== currentInfo.id
            ));
        break;
      case "user":
        disabled =
          !currentInfo.user_display_name.length ||
          !currentInfo.user_email.length ||
          !!getEmailError();
        break;
      case "inspection":
        disabled =
          !currentInfo.inspection_name.length ||
          (currentInfo.checklist_enabled &&
            !currentInfo.checklist_template.length);
        break;
      default:
        disabled = false;
        break;
    }

    return (
      disabled ||
      (currentForm.originalInfo &&
        isEqual(currentInfo, currentForm.originalInfo))
    );
  };

  const startSave = () => {
    setSaving(true);
    setTimeout(onSave, 500);
  };

  const onUserDeleted = user => {
    const collection = firestore.collection("user_groups");
    groups.forEach(group => {
      const inGroup = group.group_members.find(
        member => member.user_email === user.user_email
      );
      if (inGroup) {
        let gr = { ...group };
        gr.group_members = gr.group_members.filter(
          member => member.user_email !== user.user_email
        );
        collection.doc(group.id).update(gr);
      }
    });
  };

  const onDelete = (selected, toolbar) => {
    let collectionId = toolbar.collectionId;
    if (collectionId) {
      const collection = firestore.collection(collectionId);
      selected.forEach(item => {
        const user = users.find(person => person.id === item);
        collection
          .doc(item)
          .delete()
          .then(() => {
            if (collectionId === "users") {
              onUserDeleted(user);
            }
          });
      });
    }
  };

  return userLoading ? (
    <LoadingPage />
  ) : adminGroup.length ? (
    <Grid container direction="row" spacing={2} style={{ padding: 6 }}>
      <ManagementSection
        info={groups}
        tableInfo={groupsTableInfo}
        onRowSelected={showGroupManagement}
        sortAccessor={groupTableSorter}
        toolbar={{
          title: t("management:groupManagement"),
          onDelete,
          collectionId: "user_groups"
        }}
        onAdd={() => {
          const info = {
            group_name: "",
            group_members: [],
            group_inspection: ""
          };
          showGroupManagement(info);
        }}
      />
      <ManagementSection
        info={inspections}
        tableInfo={inspectionsTableInfo}
        onRowSelected={showInspectionManagement}
        toolbar={{
          title: t("management:inspectionManagement"),
          onDelete,
          collectionId: "inspections"
        }}
        sortAccessor={inspectionTableSorter}
        onAdd={() => {
          const info = {
            inspection_name: "",
            checklist_enabled: false,
            checklist_template: [],
            camera_type: CameraDetectionType.TEXT,
            inspection_icon: InspectionIconType.METER,
            asset_scanner_enabled: false,
            gis_pole_layer: false,
            padmounts_layer: false
          };
          showInspectionManagement(info);
        }}
      />
      <ManagementSection
        info={users}
        tableInfo={usersTableInfo}
        onRowSelected={showUserManagement}
        toolbar={{
          title: t("management:userManagement"),
          onDelete,
          collectionId: "users"
        }}
        sortAccessor={userTableSorter}
        onAdd={() => {
          const info = {
            user_display_name: "",
            user_email: ""
          };
          showUserManagement(info);
        }}
      />
      <Grid container item xs={6} justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCurrentForm({
              form: "help",
              title: "Edit Help Tutorial"
            });
            setShowStatusModal(true);
          }}
          style={{ height: 50 }}
        >
          Edit Help Tutorial
        </Button>
      </Grid>
      <ManagementEditModal
        open={showStatusModal}
        onCancel={() => setShowStatusModal(false)}
        onSave={startSave}
        modalTitle={currentForm.title}
        disabled={getDisabled()}
        disableUnsavedChanges={
          currentForm.originalInfo &&
          isEqual(currentInfo, currentForm.originalInfo)
        }
      >
        {saving ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minWidth: 400, minHeight: 400 }}
          >
            <CircularProgress />
          </Grid>
        ) : currentForm.form === "group" ? (
          <GroupManagement
            users={users}
            inspections={inspections}
            groupInfo={currentInfo}
            onGroupManagementChange={setCurrentInfo}
            nameError={
              (!currentInfo.id &&
                !!groups.find(
                  group => group.group_name === currentInfo.group_name
                )) ||
              (currentInfo.id &&
                !!groups.find(
                  group =>
                    group.group_name === currentInfo.group_name &&
                    group.id !== currentInfo.id
                ))
            }
          />
        ) : currentForm.form === "user" ? (
          <UserManagement
            userInfo={currentInfo}
            onUserManagementChange={setCurrentInfo}
            groups={groups}
            emailErrorMessage={getEmailError()}
          />
        ) : currentForm.form === "inspection" ? (
          <InspectionManagement
            inspectionInfo={currentInfo}
            onInspectionManagementChange={setCurrentInfo}
          />
        ) : null}
      </ManagementEditModal>

      <HelpManagement
        inspections={inspections}
        resetModal={resetModal}
        onCancel={() => {
          setShowStatusModal(false);
          setCurrentForm({ title: null, form: null });
        }}
        visible={currentForm.form === "help"}
      />
    </Grid>
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ padding: 6 }}
    >
      <Typography>Page Access Denied</Typography>{" "}
    </Grid>
  );
};

export default Management;
