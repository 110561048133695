import React, { useState, useEffect } from "react";
import { UserContext } from "../contexts";
import { useFirestore, useUser } from "reactfire";

const TROUBLE_STATUS_GROUP = "i3czV9TW6kZAjYPMzbUk";
const ADMIN_GROUP = "8XROwM0IYTvuvUeGP5iQ";

export const UserStore = ({ children }) => {
  const [user, setUser] = useState({
    current_user: null
  });
  const [token, setToken] = useState(null);
  const [adminGroup, setGroups] = useState([]);
  const [accessList, setAccessList] = useState([]);
  const [userLoading, setUserLoading] = useState(true);
  const [troubleEditor, setTroubleEditor] = useState(false);
  const firestore = useFirestore();
  const userInfo = useUser();

  const getUserGroups = user => {
    const updateGroups = querySnapshot => {
      let hasTroubleStatusEdit = false;
      const snapGroups = [];

      let list = [];
      querySnapshot.forEach(async doc => {
        const sub = doc.data();
        sub.id = doc.id;

        if (user) {
          const email = user.providerData[0].email;
          const isMember = sub.group_members.find(
            mem => mem.user_email === email
          );
          if (isMember) {
            if (sub.group_csv_access) {
              list = [...new Set([...list, ...sub.group_csv_access])];
            }
            if (sub.id === ADMIN_GROUP) {
              snapGroups.push(sub);
              hasTroubleStatusEdit = true;
            }
            if (sub.id === TROUBLE_STATUS_GROUP) {
              hasTroubleStatusEdit = true;
            }
          }
        }
      });
      setAccessList(list);
      setGroups(snapGroups);
      setUserLoading(false);
      setTroubleEditor(hasTroubleStatusEdit);
    };

    const unsubscribe = firestore
      .collection("user_groups")
      .onSnapshot(updateGroups);
    return () => {
      unsubscribe();
    };
  };

  useEffect(() => {
    if (userInfo.data) {
      getUserGroups(userInfo.data);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        accessList,
        adminGroup,
        getUserGroups,
        userLoading,
        troubleEditor
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserStore;
