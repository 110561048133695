export default {
  main: "#E26C24",
  btnMain: "#7CBCD2",
  fontMain: "#fff",
  background: "#415B6C",
  darkBackground: "#10212A",
  black: "#000",
  fadedBlack: "rgba(0, 0, 0, 0.8)",
  nearlyTransparent: "rgba(0, 0, 0, 0.2)",
  transparent: "rgba(0, 0, 0, 0)",
  lightGrey: "rgba(110, 110, 110, 0.8)",
  green: "#1BAA10",
  red: "#DF1313",
  orange: "#E26C24",
  lightBlue: "#7CBCD2",
  fadedGreen: "#1BAA1050",
  fadedRed: "#DF131350",
  fadedOrange: "#E26C2450",
  fadedBlue: "#7CBCD250",
  fadedWhite: "#FFFFFF40",
  lightGreen: "#CAE8CA",
  lightRed: "#E8CACA",
  aqua: "#C0E0EB",
  brightBlue: "#1594C1",
  toggleGray: "#C9C9C9",
  fadedGray: "#C9C9C940",
  warning: "#ffce42",
  purple: "#b042f4",
  lightOrange: "#F26E21",
  sand: "#F2F0EE",
  panoPinBlue: "#0775e3",
  panoPinRed: "#c41415",
  brown: "#654321",
  tagGrey: "#909090",
  aiBlue: "#51b9d0",
  aiGray: "#d9d9d9",
  panoBeige: "#eeeae9",
  headerGray: "#a5a5a5",
  yellow: "#EFFD5F",
  flamingo: "#F79AC0",
  defect: "#010030",
  pink: "#f542e3",
  dijon: "#aba809",
  darkSeafoam: "#079156",
  violet: "#120791",
  magenta: "#8a077d",
  crimson: "#750606",
  darkSalmon: "#a35555",
  sienna: "#7d3802"
};
