import React from "react";
import {
  TableRow,
  TableFooter,
  TablePagination,
  Button,
  TableCell
} from "@mui/material";
import TablePaginationActions from "./TablePaginationActions";

const EnhancedTableFooter = ({
  count,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  rowsPerPageOptions = [5, 10, 25, { label: "All", value: -1 }],
  exportToCSV
}) => (
  <TableFooter>
    <TableRow>
      {exportToCSV ? (
        <TableCell>
          <Button
            onClick={exportToCSV}
            variant="text"
            style={{ justifyContent: "center" }}
          >
            Export
          </Button>
        </TableCell>
      ) : null}
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        colSpan={6}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true
        }}
        onPageChange={setPage}
        onRowsPerPageChange={event => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
        ActionsComponent={TablePaginationActions}
      />
    </TableRow>
  </TableFooter>
);

export default EnhancedTableFooter;
