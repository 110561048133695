import React from "react";
import GoogleMapReact from "google-map-react";
import { calculateBearing } from "./util";
const { REACT_APP_MAP_API_KEY } = process.env;

const StreetView = ({ location, trouble }) => {
  const onMapLoaded = ({ map }) => {
    const sv = new window.google.maps.StreetViewService();
    const panorama = map.getStreetView();
    new window.google.maps.Marker({
      position: location,
      map,
      icon: trouble
        ? require("../../images/pin-red.png")
        : require("../../images/pin-sm.png")
    });
    const processSVData = ({ data: { location: currentLocal } }) => {
      panorama.setPano(currentLocal.pano);

      const { heading, pitch } = calculateBearing({
        locationA: {
          lat: currentLocal.latLng.lat(),
          lng: currentLocal.latLng.lng()
        },
        locationB: location
      });
      panorama.setPov({
        heading,
        pitch
      });
      panorama.setOptions({
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true,
        enableCloseButton: false
      });
      panorama.setZoom(1);
      panorama.setVisible(true);
    };
    sv.getPanorama({
      location,
      radius: 100
    }).then(processSVData);
  };

  return (
    <div id="pano" style={{ height: 200, width: `100%` }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: REACT_APP_MAP_API_KEY
        }}
        center={location}
        defaultZoom={20}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onMapLoaded}
        options={() => {
          return {
            mapTypeId: "hybrid",
            rotateControl: false
          };
        }}
      />
    </div>
  );
};
export default StreetView;
