import React, { useState, useEffect, useContext } from "react";
import {
  List,
  ListItem,
  DialogTitle,
  Dialog,
  Divider,
  Grid,
  Typography,
  DialogContent
} from "@mui/material";
import SubmissionInfoWindow from "../ui/SubmissionInfoWindow";
import { useFirestore } from "reactfire";
import { CarouselContext, MapContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const ClusterListItem = ({ submission }) => {
  const firestore = useFirestore();
  const [images, setImages] = useState([]);

  const { setCarouselInfo } = useContext(CarouselContext);
  const history = useHistory();
  const { liveFeed } = useContext(MapContext);

  useEffect(() => {
    const updateImages = querySnapshot => {
      const snapImages = [];
      querySnapshot.forEach(async doc => {
        const sub = doc.data();
        sub.id = doc.id;

        snapImages.push(sub);
      });
      setImages(snapImages);
    };
    const unsubscribe = firestore
      .collection("submission_files")
      .where("group_id", "==", submission.group_id)
      .onSnapshot(updateImages);
    return () => {
      unsubscribe();
    };
  }, [firestore, submission.group_id]);

  return (
    <Grid key={submission.id}>
      <SubmissionInfoWindow
        submission={submission}
        images={images}
        direction="row"
        onImageClick={imageId => {
          const beginning = liveFeed ? "/live-feed" : "";
          history.push(`${beginning}/carousel/${submission.group_id}`);
          setCarouselInfo(submission, images, imageId);
        }}
      />
      <Divider />
    </Grid>
  );
};

const ClusterDialog = ({ onClose, submissions }) => (
  <Dialog
    onClose={onClose}
    aria-labelledby="simple-dialog-title"
    open={Boolean(submissions)}
    fullWidth={true}
  >
    <DialogTitle id="simple-dialog-title">
      Submissions
      <IconButton
        aria-label="close"
        style={{ position: "absolute", right: "4px", top: "6px" }}
        onClick={onClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent dividers style={{ paddingHorizontal: "10px" }}>
      {submissions &&
        submissions
          .sort(
            (a, b) =>
              moment.unix(b.time_created.seconds) -
              moment.unix(a.time_created.seconds)
          )
          .map(submission => <ClusterListItem submission={submission} />)}
    </DialogContent>
  </Dialog>
);

export default ClusterDialog;
