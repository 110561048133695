import React, { useState, useRef, useEffect, useContext } from "react";

import { makeStyles } from "tss-react/mui";
import {
  Dialog,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogTitle from "@mui/material/DialogTitle";
import EnhancedTable from "../EnhancedTable/EnhancedTable";
import { tableSorter, tableInfo } from "./status-table-utils";
import { AssetType, SeverityLevels } from "../../constants";
import { getLatestCorrectedBbox } from "./draw-rectangle";

import { useFirestore, useAuth } from "reactfire";
import { SnackbarContext } from "../../../contexts";

const useStyles = makeStyles()(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 500
  },
  root: {
    padding: theme.spacing(2),
    minWidth: 500,
    height: "100%",
    ".MuiDialog-paperWidthLg": {
      height: "100&"
    }
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  tableContaienr: {
    margin: ` 0 ${theme.spacing(2)}`
  }
}));

const AnnotationModal = ({
  onClose,
  onCancel,
  selection,
  onSave,
  imageRef,
  annotations,
  id
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [assetType, setAssetType] = useState(AssetType.POLE);
  const [history, setHistory] = useState([]);
  const commentRef = useRef();
  const auth = useAuth();
  const firestore = useFirestore();
  const { openSnackbar } = useContext(SnackbarContext);

  const saveChanges = () => {
    const IMAGE_TO_SCREEN_Y =
      imageRef.current.height / imageRef.current.naturalHeight;
    const IMAGE_TO_SCREEN_X =
      imageRef.current.width / imageRef.current.naturalWidth;
    const newBbox = {
      x: selection.x1 / IMAGE_TO_SCREEN_X,
      y: selection.y1 / IMAGE_TO_SCREEN_Y,
      width: (selection.x2 - selection.x1) / IMAGE_TO_SCREEN_X,
      height: (selection.y2 - selection.y1) / IMAGE_TO_SCREEN_Y,
      time_created: new Date(),
      corrected_type: assetType
    };
    const corrected_bbox = selection.corrected_bbox || [];
    corrected_bbox.push(newBbox);

    const comments = commentRef.current.value;
    let asset_candidate_changelog = history;
    asset_candidate_changelog.push({
      comments,
      time_modified: new Date(),
      modified_by: {
        email: auth.currentUser.providerData[0].email,
        display_name: auth.currentUser.providerData[0].displayName
      },
      changed_to: assetType
    });

    let currAnn = annotations;
    let index = currAnn.findIndex(ann => ann.id === selection.id);
    if (selection.id) {
      currAnn[index] = {
        ...currAnn[index],
        corrected_bbox,
        asset_candidate_changelog,
        feedback_sent: selection.feedback_sent || null
      };
      delete currAnn[index].id;
      delete currAnn[index].isEdited;
    } else {
      currAnn.push({
        bbox: newBbox,
        type: assetType,
        asset_candidate_changelog,
        feedback_sent: selection.feedback_sent || null
      });
      index = currAnn.length - 1;
    }

    firestore
      .collection("submission_files")
      .doc(id)
      .set(
        { time_updated: new Date(), asset_candidates: currAnn },
        { merge: true }
      )
      .then(
        () => {
          openSnackbar({
            type: SeverityLevels.SUCCESS,
            open: true,
            message: selection.id
              ? "Asset candidate updated successfully"
              : "Asset candidate added successfully"
          });
          currAnn[index] = {
            ...currAnn[index],
            id: selection.id || Math.random() * 100,
            isEdited: false
          };
          onSave(currAnn);
        },
        () => {
          openSnackbar({
            type: SeverityLevels.ERROR,
            open: true,
            message: "Something went wrong. Please try again later"
          });
        }
      );
  };

  useEffect(() => {
    if (selection) {
      const type = selection.type
        ? selection.corrected_bbox
          ? getLatestCorrectedBbox(selection.corrected_bbox).corrected_type
          : selection.type
        : AssetType.POLE;
      setAssetType(type);
      const his = selection.asset_candidate_changelog || [];
      setHistory(his);
    }
  }, [selection]);

  return (
    <>
      <Dialog
        onClose={() => {
          onCancel();
        }}
        aria-labelledby="status_dialog"
        open={!!selection}
        maxWidth={false}
        className={classes.root}
      >
        <MuiDialogTitle disableTypography>
          <Typography variant="h5">
            {selection && selection.bbox ? "Edit Asset" : "Add Missing Asset"}
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                onCancel();
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
        <Grid className={classes.tableContaienr}>
          <EnhancedTable
            info={history}
            tableInfo={tableInfo}
            sortAccessor={tableSorter}
          />
        </Grid>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Asset</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={assetType}
            onChange={event => setAssetType(event.target.value)}
            label="Asset"
          >
            {AssetType.allTypes.map(type => (
              <MenuItem key={type} value={type}>
                {t(`asset:${type}`)}
              </MenuItem>
            ))}
          </Select>
          <TextField
            style={{ marginTop: 10 }}
            label="Comments"
            variant="outlined"
            inputRef={commentRef}
          />
        </FormControl>

        <DialogActions>
          <Button onClick={saveChanges} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AnnotationModal;
