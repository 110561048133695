import React from "react";
import clsx from "clsx";
import { lighten } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { Toolbar, Typography, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchBar from "./SearchBar";

const useToolbarStyles = makeStyles()(theme => ({
  root: {
    paddingLeft: 2,
    paddingRight: 2
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  },
  margin: {
    justifyContent: "flex-end",
    flex: "1 1 100%"
  }
}));

const EnhancedTableToolbar = ({
  numSelected,
  title,
  onDelete,
  onSearch,
  searchLabel
}) => {
  const { classes } = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={onDelete} size="large">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div className={classes.margin}>
          <SearchBar onSearch={onSearch} searchLabel={searchLabel} />
        </div>
      )}
    </Toolbar>
  );
};
export default EnhancedTableToolbar;
