import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Checkbox
} from "@mui/material";
import EnhancedTableHeader from "./EnhancedTableHeader";
import EnhancedTableFooter from "./EnhancedTableFooter";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import { stableSort, getComparator, exportToCSV } from "./utils";
import DeleteDialog from "./DeleteDialog";

const useStyles = makeStyles()({
  table: {
    minWidth: 650
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
});

const EnhancedTable = ({
  info,
  onRowSelected,
  tableInfo: {
    columns,
    rows,
    startOrder,
    tableStyles,
    rowsPerPageOptions,
    numRows = 5,
    search,
    startDirection
  },
  searchLabel = "Search",
  sortAccessor,
  toolbar,
  clearSearch,
  exportCSV
}) => {
  const { classes } = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(numRows);

  const [order, setOrder] = useState(startDirection || "asc");
  const [orderBy, setOrderBy] = useState(startOrder);
  const [selected, setSelected] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const numPages = Math.max(0, Math.ceil(info.length / rowsPerPage) - 1);
    if (info && numPages < page) {
      setPage(numPages);
    }
    const data = searchTerm
      ? info.filter(item =>
          search(item).toLowerCase().includes(searchTerm.toLowerCase())
        )
      : info;
    setTableData(data);
  }, [info]);

  useEffect(() => {
    if (tableData.length <= rowsPerPage) {
      setPage(0);
    }
  }, [tableData]);

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleCheckbox = id => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = ({ target: { checked } }) => {
    if (checked) {
      const newSelecteds = tableData.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onDeleteItems = () => {
    setShowDeleteDialog(false);
    if (toolbar && toolbar.onDelete) {
      toolbar.onDelete(selected, toolbar);
    }
    setSelected([]);
  };

  const onSearch = ({ target: { value } }) => {
    const searched = info.filter(item =>
      search(item).toLowerCase().includes(value.toLowerCase())
    );
    setTableData(searched);
    setSearchTerm(value);
  };

  return (
    <Grid>
      {toolbar ? (
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={toolbar.title}
          onDelete={() => setShowDeleteDialog(true)}
          onSearch={onSearch}
          clearSearch={clearSearch}
          searchLabel={searchLabel}
        />
      ) : null}
      <TableContainer style={{ ...tableStyles }} component={Paper}>
        <Table
          className={classes.table}
          aria-label="simple table"
          size="small"
          stickyHeader
        >
          <EnhancedTableHeader
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
            toolbar={toolbar}
            onSelectAllClick={handleSelectAllClick}
            rowCount={tableData.length}
            numSelected={selected.length}
          />
          <TableBody>
            {tableData
              ? stableSort(
                  tableData,
                  getComparator(order, orderBy, sortAccessor)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow
                      key={item.id}
                      style={{
                        cursor: onRowSelected ? "pointer" : "default",
                        backgroundColor: item.high_priority
                          ? "rgba(255,0,0,0.7)"
                          : "transparent"
                      }}
                    >
                      {toolbar && !toolbar.hideCheckbox ? (
                        <TableCell padding="checkbox">
                          {!item.group_unremoveable ? (
                            <Checkbox
                              checked={isSelected(item.id)}
                              inputProps={{
                                "aria-labelledby": `enhanced-table-checkbox-${index}`
                              }}
                              onChange={() => handleCheckbox(item.id)}
                            />
                          ) : null}
                        </TableCell>
                      ) : null}
                      {rows.map(({ accessor, style, otherRowProps }, i) => (
                        <TableCell
                          key={`${item.id} - ${i}`}
                          style={style}
                          onClick={() =>
                            onRowSelected ? onRowSelected(item) : {}
                          }
                          {...otherRowProps}
                          padding="normal"
                        >
                          {accessor(item)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              : null}
          </TableBody>
          <EnhancedTableFooter
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            exportToCSV={
              exportCSV ? () => exportToCSV(info, rows, exportCSV) : null
            }
          />
        </Table>
      </TableContainer>
      <DeleteDialog
        open={showDeleteDialog}
        onNo={() => setShowDeleteDialog(false)}
        onYes={onDeleteItems}
      />
    </Grid>
  );
};

export default EnhancedTable;
