import React, { useContext, useEffect, useState } from "react";
import ClickableIcon from "../ClickableIcon";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { CarouselContext } from "../../../contexts";
import { useEventListener } from "./useEventListener";
import { usePan } from "./CarousalPan";
import { imageZoom } from "./image-zoom";

export const CarouselImage = ({
  image,
  panEditMode,
  setPanEditMode,
  showAnnotations,
  setZoomVisible,
  imagePositionReset,
  setImagePositionReset,
  ImageCanvas,
  zoomVisible
}) => {
  const { contentComponentRef, imageRef, setPanState } =
    useContext(CarouselContext);
  const [offset, startPan] = usePan();
  const [scale, setScale] = useState(1);

  const MIN_SCALE = 1;
  const MAX_SCALE = 8;

  const updateScale = ({ direction, interval }) => {
    let newscale;

    // Adjusting up to scale.
    if (direction === "up" && scale + interval < MAX_SCALE) {
      newscale = scale + interval;
    } else if (direction === "up") {
      newscale = MAX_SCALE;
    } else if (direction === "down" && scale - interval > MIN_SCALE) {
      newscale = scale - interval;
    } else if (direction === "down") {
      newscale = MIN_SCALE;
    } else {
      newscale = scale;
    }

    setZoomVisible(newscale === 1 && !showAnnotations);
    setScale(newscale);
  };

  // Event listener for  `onwheel` scaling update.
  useEventListener(contentComponentRef, "wheel", e => {
    e.preventDefault();
    setImagePositionReset(false);

    updateScale({
      direction: e.deltaY > 0 ? "up" : "down",
      interval: 0.1
    });
  });

  useEventListener(contentComponentRef, "dblclick", e => {
    e.preventDefault();
    setImagePositionReset(false);

    updateScale({
      direction: "up",
      interval: 0.1
    });
  });

  const onImageHover = imageId => {
    imageZoom(imageId, "zoomed-image");
  };

  return (
    <>
      <div>
        <div
          onMouseEnter={() => {
            !showAnnotations && scale === 1 && setZoomVisible(true);
          }}
          onMouseOver={() => {
            !showAnnotations && onImageHover(`${image.download_link}-carousel`);
          }}
          onMouseLeave={() => {
            !showAnnotations && setZoomVisible(false);
          }}
        >
          <div
            id="lens"
            style={
              zoomVisible && scale === 1
                ? { display: "block" }
                : { display: "none" }
            }
          />
          <div
            ref={contentComponentRef}
            style={{
              transform: imagePositionReset ? `scale(1)` : `scale(${scale})`,
              position: "relative",
              left: `${-offset.x}px`,
              top: `${-offset.y}px`,
              right: `${-offset.x}px`,
              bottom: `${-offset.y}px`
            }}
          >
            <img
              id={`${image.download_link}-carousel`}
              src={image.download_link}
              alt={image.download_link}
              style={{
                width: "auto",
                maxHeight: window.innerHeight - 15,
                maxWidth: window.innerWidth - 400
              }}
              ref={imageRef}
            />
            <ImageCanvas scale={scale} />
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              zIndex: 100,
              display: "flex",
              flexDirection: "column"
            }}
          >
            <ClickableIcon
              title={"Recenter"}
              IconImage={RotateLeftIcon}
              onClick={() => {
                setPanState({ x: 0, y: 0 });
                setScale(1);
              }}
              style={{
                fill: "grey",
                padding: "5px 5px"
              }}
              iconStyle={{ color: "rgba(255, 255, 255, 1)", fontSize: "3rem" }}
            />
            <ClickableIcon
              title={!panEditMode ? "Enable panning" : "Disable Panning"}
              IconImage={FlipToFrontIcon}
              onClick={() => {
                setPanEditMode(!panEditMode);
              }}
              style={{
                fill: "grey",
                padding: "5px 5px"
              }}
              iconStyle={{
                color: !panEditMode
                  ? "rgba(128,128,128,1)"
                  : "rgba(255, 255, 255, 1)",
                fontSize: "3rem"
              }}
            />
            <ClickableIcon
              title="Zoom in"
              IconImage={AddBoxIcon}
              onClick={() => {
                updateScale({
                  direction: "up",
                  interval: 0.1
                });
              }}
              style={{
                padding: "5px 5px"
              }}
              iconStyle={{ color: "rgba(255, 255, 255, 1)", fontSize: "3rem" }}
            />
            <ClickableIcon
              title="Zoom out"
              IconImage={IndeterminateCheckBoxIcon}
              onClick={() => {
                scale !== 1 &&
                  updateScale({
                    direction: "down",
                    interval: 0.1
                  });
              }}
              style={{
                padding: "5px 5px"
              }}
              iconStyle={{ color: "rgba(255, 255, 255, 1)", fontSize: "3rem" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselImage;
