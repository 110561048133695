import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useTranslation } from "react-i18next";

const DeleteDialog = ({ open, onYes, onNo }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onNo}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("deleteDialog:title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("deleteDialog:confirmMessage")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo} color="primary">
          {t("common:no")}
        </Button>
        <Button onClick={onYes} color="primary" autoFocus>
          {t("common:yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
