import React from "react";
import { IconButton, Tooltip, Badge } from "@mui/material";

const ClickableIcon = ({
  title,
  onClick,
  IconImage,
  style,
  iconStyle,
  badgeCount
}) => (
  <Tooltip title={title}>
    <IconButton onClick={onClick} style={style} size="large">
      <Badge color="error" invisible={!badgeCount} badgeContent={badgeCount}>
        <IconImage style={iconStyle} />
      </Badge>
    </IconButton>
  </Tooltip>
);

export default ClickableIcon;
