import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";

const UnsavedChangesDialog = ({ open, onYes, onNo, alternativeMessage }) => (
  <Dialog
    open={open}
    onClose={onNo}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {alternativeMessage ? alternativeMessage.title : "Unsaved Changes"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {alternativeMessage
          ? alternativeMessage.description
          : "Any unsaved changes will be lost. Do you wish to continue?"}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onNo} color="primary">
        No
      </Button>
      <Button onClick={onYes} color="primary" autoFocus>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default UnsavedChangesDialog;
