import React from "react";
import { Grid, Typography } from "@mui/material";
import ClickableIcon from "../ClickableIcon";

export const SubmissionInfoItemTitle = ({ icon, header, secondIcon }) => (
  <Grid style={{ display: "flex" }}>
    <Typography
      style={{
        fontWeight: "bold",
        fontSize: "1.2rem"
      }}
    >
      {header}
    </Typography>

    {secondIcon ? (
      <ClickableIcon
        title={secondIcon.title}
        IconImage={secondIcon.image}
        onClick={secondIcon.onClick}
        style={{ padding: "0 5px" }}
        iconStyle={{ fontSize: "1.2rem" }}
      />
    ) : null}

    {icon ? (
      <ClickableIcon
        title={icon.title}
        IconImage={icon.image}
        onClick={icon.onClick}
        style={{ padding: "0 5px" }}
        iconStyle={{ fontSize: "1.2rem" }}
      />
    ) : null}
  </Grid>
);

export const SubmissionInfoItem = ({ header, infoItem, style, icon }) => (
  <Grid
    item
    style={{
      width: "205px",
      padding: "8px"
    }}
  >
    <SubmissionInfoItemTitle icon={icon} header={header} />
    <Typography style={{ fontSize: "0.9rem", ...style }}>{infoItem}</Typography>
  </Grid>
);
