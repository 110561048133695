import React, { useState, useEffect, useContext } from "react";
import { SubmissionsContext, FiltersContext } from "../contexts";
import { checkFilters } from "../components/home/submission-utils";
import { useFirestore } from "reactfire";

export const SubmissionsStore = ({ children }) => {
  const firestore = useFirestore();
  const [submissions, setSubmissions] = useState([]);
  const [lastQueriedSubmissions, setLastQueriedSubmissions] = useState([]);
  const [submissionsInBounds, setSubmissionsInBounds] = useState([]);

  const { filters, setResetting, resetting, assetId } =
    useContext(FiltersContext);
  const [inspections, setInspections] = useState([]);

  useEffect(() => {
    const updateInspections = querySnapshot => {
      const snapInspections = [];
      querySnapshot.forEach(async doc => {
        const sub = doc.data();
        sub.id = doc.id;

        snapInspections.push(sub);
      });
      setInspections(snapInspections);
    };

    firestore.collection("inspections").onSnapshot(updateInspections);
  }, []);

  const updateSubmissionState = submission => {
    let subs = submissions;
    let index = subs.findIndex(item => item.id === submission.id);
    subs[index] = {
      ...subs[index],
      new: false
    };

    setSubmissions(subs);
  };

  useEffect(() => {
    if (!resetting) {
      const filteredSubs = [];
      lastQueriedSubmissions.forEach(sub => {
        const valid = checkFilters({
          sub,
          filters,
          isAsset: !!assetId,
          inspections
        });
        if (valid) {
          filteredSubs.push(sub);
        }
      });
      setSubmissions(filteredSubs);
    } else {
      setSubmissions(lastQueriedSubmissions);
      setResetting(false);
    }
  }, [filters]);

  return (
    <SubmissionsContext.Provider
      value={{
        submissions,
        setSubmissions,
        updateSubmissionState,
        submissionsInBounds,
        lastQueriedSubmissions,
        setLastQueriedSubmissions,
        setSubmissionsInBounds,
        inspections
      }}
    >
      {children}
    </SubmissionsContext.Provider>
  );
};

export default SubmissionsStore;
