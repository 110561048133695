import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import {
  Dialog,
  DialogActions,
  Button,
  Typography,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import MuiDialogTitle from "@mui/material/DialogTitle";
import UnsavedChangesDialog from "../ui/UnsavedChangesDialog";

const useStyles = makeStyles()(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    justifyContent: "center",
    display: "flex",
    alignItems: "center"
  },
  root: {
    padding: theme.spacing(2),
    minWidth: 325
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  tableContaienr: {
    margin: ` 0 ${theme.spacing(2)}`
  }
}));

const ManagementEditModal = ({
  onCancel,
  open,
  children,
  onSave,
  modalTitle,
  disabled,
  disableUnsavedChanges
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [showUnsavedChanges, setShowUnsavedChanges] = useState(false);

  return (
    <>
      <Dialog
        aria-labelledby="management_modal"
        open={open}
        maxWidth={false}
        className={classes.root}
      >
        <MuiDialogTitle disableTypography>
          <Typography variant="h5">{modalTitle}</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            data-testID="close"
            onClick={() => {
              !disableUnsavedChanges ? setShowUnsavedChanges(true) : onCancel();
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        {children}

        <DialogActions>
          <Button onClick={onSave} color="primary" disabled={disabled}>
            {t("common:saveChanges")}
          </Button>
        </DialogActions>
      </Dialog>

      <UnsavedChangesDialog
        open={showUnsavedChanges}
        onYes={() => {
          setShowUnsavedChanges(false);
          onCancel();
        }}
        onNo={() => setShowUnsavedChanges(false)}
      />
    </>
  );
};

export default ManagementEditModal;
