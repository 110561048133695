export const getMarkerStyle = points => {
  if (points >= 50) {
    return {
      width: 60,
      height: 60,
      size: 40,
      fontSize: 20
    };
  }

  if (points >= 25) {
    return {
      width: 55,
      height: 55,
      size: 35,
      fontSize: 19
    };
  }

  if (points >= 15) {
    return {
      width: 49,
      height: 49,
      size: 31,
      fontSize: 18
    };
  }

  if (points >= 10) {
    return {
      width: 43,
      height: 43,
      size: 27,
      fontSize: 17
    };
  }

  if (points >= 8 || points === "backend") {
    return { width: 37, height: 37, size: 23, fontSize: 17 };
  }

  if (points >= 4) {
    return {
      width: 51,
      height: 51,
      size: 37,
      fontSize: 16
    };
  }

  return {
    width: 45,
    height: 45,
    size: 33,
    fontSize: 15
  };
};

export const degToRad = angle => angle * (Math.PI / 180);

export const radToDeg = rad => rad * (180 / Math.PI);

export const distanceBetweenLocations = (latA, longA, latB, longB) => {
  const R = 6371e3; // metres
  const φ1 = latA;
  const φ2 = latB;
  const Δφ = latB - latA;
  const Δλ = longB - longA;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
};

export const calculateBearing = ({ locationA, locationB }) => {
  const latA = degToRad(locationA.lat);
  const longA = degToRad(locationA.lng);
  const latB = degToRad(locationB.lat);
  const longB = degToRad(locationB.lng);

  const x = Math.cos(latB) * Math.sin(longB - longA);
  const y =
    Math.cos(latA) * Math.sin(latB) -
    Math.sin(latA) * Math.cos(latB) * Math.cos(longB - longA);

  const heading = radToDeg(Math.atan2(x, y));
  const distance = distanceBetweenLocations(latA, longA, latB, longB);

  let pitch = 0;
  if (distance < 3) {
    pitch = -60;
  } else if (distance < 6) {
    pitch = -45;
  }

  return { heading, pitch };
};
