import i18n from "i18next";
import moment from "moment";

export const troubleStatusSort = item => i18n.t(`status:${item.changed_to}`);
export const timeModifiedSort = item =>
  item.time_modified.toDate ? item.time_modified.toDate() : item.time_modified;
export const userSort = item =>
  item.modified_by.display_name || item.modified_by.email;
export const referenceSort = item => item.reference_number;

export const tableSorter = (a, b, orderBy) => {
  let bProp = b[orderBy];
  let aProp = a[orderBy];
  switch (orderBy) {
    case "changed_to":
      bProp = troubleStatusSort(b);
      aProp = troubleStatusSort(a);
      break;
    case "time_modified":
      bProp = timeModifiedSort(b);
      aProp = timeModifiedSort(a);
      break;
    case "reference":
      bProp = referenceSort(b);
      aProp = referenceSort(a);
      break;
    case "modified_by":
      bProp = userSort(b);
      aProp = userSort(a);
      break;
    default:
      break;
  }
  return { aProp, bProp };
};

export const tableInfo = {
  columns: [
    { id: "time_modified", label: "statusTable:timeModified" },
    { id: "modified_by", label: "statusTable:modifiedBy" },
    { id: "changed_to", label: "statusTable:changedTo" },
    { id: "reference", label: "statusTable:referenceNumber" },
    { id: "comments", label: "statusTable:comments" }
  ],
  rows: [
    {
      accessor: row =>
        moment(
          row.time_modified.toDate
            ? row.time_modified.toDate()
            : row.time_modified
        ).format("YYYY/MM/DD HH:mm")
    },
    {
      accessor: userSort,
      otherRowProps: { component: "th", scope: "row" }
    },
    {
      accessor: troubleStatusSort,
      style: { textTransform: "capitalize" }
    },
    {
      accessor: row => row.reference_number
    },
    {
      accessor: row => row.comments
    }
  ],
  startOrder: "time_modified",
  rowsPerPageOptions: [4, 8, 12],
  numRows: 4
};
