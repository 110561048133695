export class TagType {
  static TREE = "tree";
  static POLE = "pole";
  static WIRE = "wire";
  static OTHER = "other";
  static TRANSFORMER = "transformer";
  static LIGHT = "light";
  static ANIMAL = "animal";
  static METER = "meter";
  static FIELDURGENT = "field-urgent";
  static FIELDPLANNED = "field-planned";

  static normalModeTypes = [
    TagType.POLE,
    TagType.WIRE,
    TagType.TRANSFORMER,
    TagType.LIGHT,
    TagType.METER,
    TagType.FIELDURGENT,
    TagType.FIELDPLANNED,
    TagType.OTHER
  ];
  static troubleModeTypes = [
    TagType.TREE,
    TagType.WIRE,
    TagType.POLE,
    TagType.TRANSFORMER,
    TagType.LIGHT,
    TagType.METER,
    TagType.FIELDURGENT,
    TagType.FIELDPLANNED,
    TagType.ANIMAL,
    TagType.OTHER
  ];

  static liveFeedTypes = [
    TagType.TREE,
    TagType.WIRE,
    TagType.POLE,
    TagType.TRANSFORMER,
    TagType.LIGHT,
    TagType.METER,
    TagType.FIELDURGENT,
    TagType.ANIMAL,
    TagType.OTHER
  ];

  static allTypes = [
    TagType.TREE,
    TagType.WIRE,
    TagType.POLE,
    TagType.LIGHT,
    TagType.TRANSFORMER,
    TagType.METER,
    TagType.FIELDURGENT,
    TagType.FIELDPLANNED,
    TagType.ANIMAL,
    TagType.OTHER
  ];
}

export class ChecklistType {
  static ACCESSIBILITY = "accessibility";
  static SAFETY = "safety";
  static PROPANE_NATURAL_GAS = "propane_natural_gas";
  static OTHER_ISSUES = "other_issues";
}

export class ChecklistAnswers {
  static LOCKED_GATE = "locked_gate";
  static LOCKED_DOOR = "locked_door";
  static ALARM = "alarm";
  static OBSTRUCTED = "obstructed";
  static HOUSEKEEPING = "housekeeping";
  static SIDING = "siding";
  static OTHER = "other";
  static DOG = "dog";
  static MOLD = "mold";
  static PROPANE = "propane";
  static NATURAL_GAS = "natural_gas";
  static ELECTRICAL_ENTRANCE = "electrical_entrance";

  static accessibilityAns = [
    ChecklistAnswers.LOCKED_GATE,
    ChecklistAnswers.LOCKED_DOOR,
    ChecklistAnswers.ALARM,
    ChecklistAnswers.OBSTRUCTED,
    ChecklistAnswers.HOUSEKEEPING,
    ChecklistAnswers.SIDING,
    ChecklistAnswers.OTHER
  ];

  static safetyAns = [
    ChecklistAnswers.DOG,
    ChecklistAnswers.MOLD,
    ChecklistAnswers.OTHER
  ];

  static propaneNatAns = [
    ChecklistAnswers.PROPANE,
    ChecklistAnswers.NATURAL_GAS,
    ChecklistAnswers.OTHER
  ];

  static otherIssAns = [ChecklistAnswers.ELECTRICAL_ENTRANCE];
}

export class StatusType {
  static SUBMITTED = "submitted";
  static PENDING_REVIEW = "pending_review";
  static COMPLETE_NO_ACTION = "complete_no_action";
  static COMPLETE_ACTION_WORK_ORDER = "complete_action_work_order";
  static COMPLETE_ACTION_FIELD_STAFF = "complete_action_field_staff";
  static COMPLETE_ACTION_CUSTOMER_VERIFICATION =
    "complete_action_customer_verification";
  static UPDATED = "updated";

  static allTypes = [
    StatusType.SUBMITTED,
    StatusType.PENDING_REVIEW,
    StatusType.COMPLETE_ACTION_WORK_ORDER,
    StatusType.COMPLETE_ACTION_FIELD_STAFF,
    StatusType.COMPLETE_ACTION_CUSTOMER_VERIFICATION,
    StatusType.COMPLETE_NO_ACTION
  ];

  static allTypesFilters = [
    StatusType.SUBMITTED,
    StatusType.PENDING_REVIEW,
    StatusType.COMPLETE_ACTION_WORK_ORDER,
    StatusType.COMPLETE_ACTION_FIELD_STAFF,
    StatusType.COMPLETE_ACTION_CUSTOMER_VERIFICATION,
    StatusType.COMPLETE_NO_ACTION,
    StatusType.UPDATED
  ];
}

export class SeverityLevels {
  static SUCCESS = "success";
  static INFO = "info";
  static ERROR = "error";
  static WARNING = "warning";
}

export class TransitionTypes {
  static SLIDE = "slide";
  static GROW = "grow";
  static FADE = "fade";
}

export const getGeolocationErrorMessage = value => {
  switch (value) {
    case 1:
      return "Permission Denied. Please enable location services to view the map.";
    case 2:
      return "Position Unavailable. Please try again later.";
    case 3:
      return "Timeout fetching location. Please try again later.";
    default:
      return "An error has occurred. Please try again later";
  }
};

export class AssetType {
  static POLE = "pole";
  static FRAME = "frame";
  static LIGHT = "light";
  static GUYWIRE = "guywire";
  static TRANSFORMER = "transformer";
  static SWITCH = "switch";
  static INSULATOR = "insulator";
  static ELECTRICAL_PRIMARY_WIRE = "electrical_primary_wire";
  static ELECTRICAL_SECONDARY_WIRE = "electrical_secondary_wire";
  static ELECTRICAL_NEUTRAL_WIRE = "electrical_neutral_wire";
  static TELECOM_WIRE = "telecom_wire";
  static TELECOM_SPLICE_ENCLOSURE = "telecom_splice_enclosure";
  static STREET_LIGHT = "street_light";
  static AREA_LIGHT = "area_light";
  static FALSE_POSITIVE = "false_positive";
  static DEFECT = "defect";

  static allTypes = [
    AssetType.POLE,
    AssetType.FRAME,
    AssetType.LIGHT,
    AssetType.TRANSFORMER,
    AssetType.GUYWIRE,
    AssetType.SWITCH,
    AssetType.INSULATOR,
    AssetType.ELECTRICAL_PRIMARY_WIRE,
    AssetType.ELECTRICAL_SECONDARY_WIRE,
    AssetType.ELECTRICAL_NEUTRAL_WIRE,
    AssetType.TELECOM_WIRE,
    AssetType.TELECOM_SPLICE_ENCLOSURE,
    AssetType.STREET_LIGHT,
    AssetType.AREA_LIGHT,
    AssetType.FALSE_POSITIVE,
    AssetType.DEFECT
  ];
}

export class CameraDetectionType {
  static TEXT = "text";
  static MANUAL = "manual";

  static allTypes = [CameraDetectionType.TEXT, CameraDetectionType.MANUAL];
}

export class InspectionIconType {
  static METER = "speedometer-outline";
  static BUSINESS = "business-outline";
  static BUILD = "build-outline";
  static CONSTRUCT = "construct-outline";
  static EARTH = "earth-outline";
  static FINGERPRINT = "finger-print-outline";
  static FLASK = "flask-outline";
  static WATER = "water-outline";
  static QR = "qr-code-outline";
  static BULB = "bulb-outline";
  static BUS = "bus-outline";
  static BRIEFCASE = "briefcase-outline";
  static FLAME = "flame-outline";
  static FISH = "fish-outline";
  static FLOWER = "flower-outline";
  static LEAF = "leaf-outline";
  static MAGNET = "magnet-outline";
  static MEGAPHONE = "megaphone-outline";
  static PAW = "paw-outline";
  static PULSE = "pulse-outline";
  static RADIO = "radio-outline";
  static SCALE = "scale-outline";
  static TRAIL = "trail-sign-outline";

  static allTypes = [
    InspectionIconType.METER,
    InspectionIconType.BUSINESS,
    InspectionIconType.BUILD,
    InspectionIconType.CONSTRUCT,
    InspectionIconType.EARTH,
    InspectionIconType.FINGERPRINT,
    InspectionIconType.FLASK,
    InspectionIconType.WATER,
    InspectionIconType.QR,
    InspectionIconType.BULB,
    InspectionIconType.BUS,
    InspectionIconType.BRIEFCASE,
    InspectionIconType.FLAME,
    InspectionIconType.FISH,
    InspectionIconType.FLOWER,
    InspectionIconType.LEAF,
    InspectionIconType.MAGNET,
    InspectionIconType.MEGAPHONE,
    InspectionIconType.PAW,
    InspectionIconType.PULSE,
    InspectionIconType.RADIO,
    InspectionIconType.SCALE,
    InspectionIconType.TRAIL
  ];
}

export const EMAIL_REG =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
