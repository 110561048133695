import React from "react";
import "./App.css";
import Routes from "./routes";
import { FirebaseAppProvider } from "reactfire";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Stores from "./context-stores";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

export const muiCache = createCache({
  key: "mui",
  prepend: true
});
const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID
};

const App = () => (
  <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
    <Stores>
      <I18nextProvider i18n={i18n}>
        <CacheProvider value={muiCache}>
          <Routes />
        </CacheProvider>
      </I18nextProvider>
    </Stores>
  </FirebaseAppProvider>
);
export default App;
