import { useCallback, useRef, useContext } from "react";
import { CarouselContext } from "../../../contexts";

const ORIGIN = Object.freeze({ x: 0, y: 0 });

export const usePan = () => {
  const { contentComponentRef, panState, setPanState } = useContext(
    CarouselContext
  );

  // Track the last observed mouse position on pan.
  const lastPointRef = useRef(ORIGIN);

  const pan = useCallback(MouseEvent => {
    const lastPoint = lastPointRef.current;
    const point = { x: MouseEvent.pageX, y: MouseEvent.pageY };
    lastPointRef.current = point;

    setPanState(panState => {
      const delta = {
        x: lastPoint.x - point.x,
        y: lastPoint.y - point.y
      };
      const offset = {
        x: panState.x + delta.x,
        y: panState.y + delta.y
      };

      return offset;
    });
  }, []);

  const endPan = useCallback(() => {
    document.removeEventListener("mousemove", pan);
    document.removeEventListener("mouseup", endPan);
  }, [pan]);

  const startPan = useCallback(
    e => {
      if (!contentComponentRef.current) {
        return;
      } else {
        document.addEventListener("mousemove", pan);
        document.addEventListener("mouseup", endPan);
        lastPointRef.current = { x: e.pageX, y: e.pageY };
      }
    },
    [pan, endPan]
  );

  return [panState, startPan];
};

//Referenced the code and ideas from https://www.jclem.net/posts/pan-zoom-canvas-react
