import { AssetType } from "../../constants";
import colors from "../colors";

var lineOffset = 4;
var anchrSize = 4;

var mousedown = false;
var clickedArea = { box: -1, pos: "o" };
var x1 = -1;
var y1 = -1;
var x2 = -1;
var y2 = -1;

var boxes = [];
var tmpBox = null;
var currentSelection = null;
var currentSaving = { id: null };
var image = null;
var saving = false;
var zoomLevel = 1;

export const getCurrentSelection = () => currentSelection;

export const findEdits = () => boxes.find(box => box.isEdited);

export const resetCurrentlySaving = () => {
  saving = false;
  currentSaving = { id: null };
};

export const resetSaving = () => {
  saving = false;
};

export const hideConfirmBox = () => {
  const active = document.getElementById("active-box");
  active.style.display = "none";
};

export const doneEditingBox = () => {
  currentSaving = currentSelection;
  currentSelection = null;
  saving = true;
  hideConfirmBox();
  return currentSaving;
};

export const checkForUnsavedBoxes = () =>
  boxes.find(box => box.isEdited === true);

export const getAssetColor = asset => {
  switch (asset) {
    case AssetType.FRAME:
      return colors.green;
    case AssetType.TRANSFORMER:
      return colors.red;
    case AssetType.POLE:
      return colors.lightBlue;
    case AssetType.LIGHT:
      return colors.warning;
    case AssetType.GUYWIRE:
      return colors.purple;
    case AssetType.SWITCH:
      return colors.brown;
    case AssetType.INSULATOR:
      return colors.pink;
    case AssetType.ELECTRICAL_PRIMARY_WIRE:
      return colors.crimson;
    case AssetType.ELECTRICAL_SECONDARY_WIRE:
      return colors.darkSalmon;
    case AssetType.ELECTRICAL_NEUTRAL_WIRE:
      return colors.sienna;
    case AssetType.AREA_LIGHT:
      return colors.dijon;
    case AssetType.STREET_LIGHT:
      return colors.darkSeafoam;
    case AssetType.TELECOM_WIRE:
      return colors.magenta;
    case AssetType.TELECOM_SPLICE_ENCLOSURE:
      return colors.violet;
    case AssetType.FALSE_POSITIVE:
      return colors.black;
    case AssetType.DEFECT:
      return colors.defect;
    default:
      return colors.orange;
  }
};

const mouseDown = e => {
  const { offsetX, offsetY } = e;
  mousedown = true;
  clickedArea = findCurrentArea(offsetX, offsetY);
  x1 = offsetX;
  y1 = offsetY;
  x2 = offsetX;
  y2 = offsetY;
};

const showConfirm = box => {
  const active = document.getElementById("active-box");
  active.style.display = "flex";
  currentSelection = box;
};

const mouseUp = () => {
  if (clickedArea.box === -1 && tmpBox !== null) {
    boxes.push(tmpBox);
    showConfirm(tmpBox);
    currentSelection = tmpBox;
  } else if (clickedArea.box !== -1) {
    var selectedBox = boxes[clickedArea.box];
    if (selectedBox.x1 > selectedBox.x2) {
      var previousX1 = selectedBox.x1;
      selectedBox.x1 = selectedBox.x2;
      selectedBox.x2 = previousX1;
    }
    if (selectedBox.y1 > selectedBox.y2) {
      var previousY1 = selectedBox.y1;
      selectedBox.y1 = selectedBox.y2;
      selectedBox.y2 = previousY1;
    }
    showConfirm(selectedBox);
  }
  clickedArea = { box: -1, pos: "o" };
  tmpBox = null;
  mousedown = false;
};

const mouseOut = () => {
  if (clickedArea.box === -1 && tmpBox !== null) {
    boxes.push(tmpBox);
    showConfirm(tmpBox);
    currentSelection = tmpBox;
  } else if (clickedArea.box !== -1) {
    var selectedBox = boxes[clickedArea.box];
    if (selectedBox.x1 > selectedBox.x2) {
      var previousX1 = selectedBox.x1;
      selectedBox.x1 = selectedBox.x2;
      selectedBox.x2 = previousX1;
    }
    if (selectedBox.y1 > selectedBox.y2) {
      var previousY1 = selectedBox.y1;
      selectedBox.y1 = selectedBox.y2;
      selectedBox.y2 = previousY1;
    }
    mousedown = false;
    showConfirm(selectedBox);
  }

  mousedown = false;
  clickedArea = { box: -1, pos: "o" };
  tmpBox = null;
};

const mouseMove = e => {
  const { offsetX, offsetY } = e;
  const currentlyActive = boxes.findIndex(box => box.isFocused);
  if (currentlyActive !== -1 && clickedArea.box !== currentlyActive) {
    boxes[currentlyActive].isFocused = false;
    const curr_type = boxes[currentlyActive].corrected_bbox
      ? getLatestCorrectedBbox(boxes[currentlyActive].corrected_bbox)
          .corrected_type
      : boxes[currentlyActive].type;
    boxes[currentlyActive].color = getAssetColor(curr_type);
  }
  if (mousedown && clickedArea.box === -1) {
    x2 = offsetX;
    y2 = offsetY;
    redraw();
  } else if (mousedown && clickedArea.box !== -1) {
    x2 = offsetX;
    y2 = offsetY;
    const xOffset = x2 - x1;
    const yOffset = y2 - y1;
    x1 = x2;
    y1 = y2;

    switch (clickedArea.pos) {
      case "i":
        boxes[clickedArea.box].x1 += xOffset;
        boxes[clickedArea.box].y1 += yOffset;
        boxes[clickedArea.box].x2 += xOffset;
        boxes[clickedArea.box].y2 += yOffset;
        break;
      case "tl":
        boxes[clickedArea.box].x1 += xOffset;
        boxes[clickedArea.box].y1 += yOffset;
        break;
      case "l":
        boxes[clickedArea.box].x1 += xOffset;
        break;
      case "bl":
        boxes[clickedArea.box].x1 += xOffset;
        boxes[clickedArea.box].y2 += yOffset;
        break;
      case "t":
        boxes[clickedArea.box].y1 += yOffset;
        break;
      case "tr":
        boxes[clickedArea.box].y1 += yOffset;
        boxes[clickedArea.box].x2 += xOffset;
        break;
      case "r":
        boxes[clickedArea.box].x2 += xOffset;
        break;
      case "br":
        boxes[clickedArea.box].x2 += xOffset;
        boxes[clickedArea.box].y2 += yOffset;
        break;
      case "b":
        boxes[clickedArea.box].y2 += yOffset;
        break;
      default:
        break;
    }

    boxes[clickedArea.box].color = colors.flamingo;
    boxes[clickedArea.box].isFocused = true;
    boxes[clickedArea.box].isEdited = true;
    redraw();
  }
};

export const redraw = () => {
  const canvas = document.getElementById("canvas");
  var context = canvas.getContext("2d");
  context.clearRect(0, 0, canvas.width, canvas.height);

  boxes.map(box => drawBoxOn(box, context));

  if (clickedArea.box === -1) {
    tmpBox = newBox({ x1, y1, x2, y2, isFocused: true, isEdited: true });
    if (tmpBox) {
      drawBoxOn(tmpBox, context);
    }
  }
};

const findCurrentArea = (x, y) => {
  let area = { box: -1, pos: "o" };
  boxes.forEach((box, i) => {
    const xCenter = box.x1 + (box.x2 - box.x1) / 2;
    const yCenter = box.y1 + (box.y2 - box.y1) / 2;
    if (box.x1 - lineOffset < x && x < box.x1 + lineOffset) {
      if (box.y1 - lineOffset < y && y < box.y1 + lineOffset) {
        area = { box: i, pos: "tl" };
      } else if (box.y2 - lineOffset < y && y < box.y2 + lineOffset) {
        area = { box: i, pos: "bl" };
      } else if (yCenter - lineOffset < y && y < yCenter + lineOffset) {
        area = { box: i, pos: "l" };
      }
    } else if (box.x2 - lineOffset < x && x < box.x2 + lineOffset) {
      if (box.y1 - lineOffset < y && y < box.y1 + lineOffset) {
        area = { box: i, pos: "tr" };
      } else if (box.y2 - lineOffset < y && y < box.y2 + lineOffset) {
        area = { box: i, pos: "br" };
      } else if (yCenter - lineOffset < y && y < yCenter + lineOffset) {
        area = { box: i, pos: "r" };
      }
    } else if (xCenter - lineOffset < x && x < xCenter + lineOffset) {
      if (box.y1 - lineOffset < y && y < box.y1 + lineOffset) {
        area = { box: i, pos: "t" };
      } else if (box.y2 - lineOffset < y && y < box.y2 + lineOffset) {
        area = { box: i, pos: "b" };
      } else if (box.y1 - lineOffset < y && y < box.y2 + lineOffset) {
        area = { box: i, pos: "i" };
      }
    } else if (box.x1 - lineOffset < x && x < box.x2 + lineOffset) {
      if (box.y1 - lineOffset < y && y < box.y2 + lineOffset) {
        area = { box: i, pos: "i" };
      }
    }
  });
  return area;
};

export const newBox = ({ x1, y1, x2, y2, isFocused, type, ...otherProps }) => {
  const boxX1 = x1 < x2 ? x1 : x2;
  const boxY1 = y1 < y2 ? y1 : y2;
  const boxX2 = x1 > x2 ? x1 : x2;
  const boxY2 = y1 > y2 ? y1 : y2;
  const curr_type = otherProps.corrected_bbox
    ? getLatestCorrectedBbox(otherProps.corrected_bbox).corrected_type
    : type;
  const color = getAssetColor(curr_type);
  return {
    x1: boxX1,
    y1: boxY1,
    x2: boxX2,
    y2: boxY2,
    lineWidth: 2,
    color: isFocused ? colors.flamingo : color,
    isFocused,
    type,
    ...otherProps
  };
};

export const drawBoxOn = (box, context, hideAnchors) => {
  if (box) {
    var xCenter = box.x1 + (box.x2 - box.x1) / 2;
    var yCenter = box.y1 + (box.y2 - box.y1) / 2;

    context.strokeStyle = box.color;
    context.fillStyle = box.color;

    context.beginPath();
    box.isEdited ? context.setLineDash([10, 10]) : context.setLineDash([]);

    context.rect(box.x1, box.y1, box.x2 - box.x1, box.y2 - box.y1);
    context.lineWidth = box.lineWidth / zoomLevel + 0.5;
    context.shadowBlur = 0;
    context.imageRendering = "pixelated";
    context.stroke();
    let anchor = anchrSize / zoomLevel + 0.5;

    if (!hideAnchors) {
      context.fillRect(
        box.x1 - anchor,
        box.y1 - anchor,
        2 * anchor,
        2 * anchor
      );
      context.fillRect(
        box.x1 - anchor,
        yCenter - anchor,
        2 * anchor,
        2 * anchor
      );
      context.fillRect(
        box.x1 - anchor,
        box.y2 - anchor,
        2 * anchor,
        2 * anchor
      );
      context.fillRect(
        xCenter - anchor,
        box.y1 - anchor,
        2 * anchor,
        2 * anchor
      );
      context.fillRect(
        xCenter - anchor,
        yCenter - anchor,
        2 * anchor,
        2 * anchor
      );
      context.fillRect(
        xCenter - anchor,
        box.y2 - anchor,
        2 * anchor,
        2 * anchor
      );
      context.fillRect(
        box.x2 - anchor,
        box.y1 - anchor,
        2 * anchor,
        2 * anchor
      );
      context.fillRect(
        box.x2 - anchor,
        yCenter - anchor,
        2 * anchor,
        2 * anchor
      );
      context.fillRect(
        box.x2 - anchor,
        box.y2 - anchor,
        2 * anchor,
        2 * anchor
      );
    }
  }
};

export const getLatestCorrectedBbox = corrected_bbox => {
  let latest = corrected_bbox[0];
  corrected_bbox.forEach(bbox => {
    if (bbox.time_created > latest.time_created) {
      latest = bbox;
    }
  });
  return latest;
};

export const loadBoxes = ({
  assets,
  screenX,
  screenY,
  editEnabled,
  imageRef,
  panEditMode,
  showAssets,
  showDefects,
  scale
}) => {
  image = imageRef;
  const canvas = document.getElementById("canvas");
  const context = canvas.getContext("2d");
  const oldBoxes = boxes;
  boxes = [];
  context.clearRect(0, 0, canvas.width, canvas.height);
  zoomLevel = scale;

  assets.forEach(assetCan => {
    const { x, y, width, height, corrected_type } = assetCan.corrected_bbox
      ? getLatestCorrectedBbox(assetCan.corrected_bbox)
      : assetCan.bbox;

    if (assetCan.defects && showDefects && !editEnabled) {
      assetCan.defects.forEach(defect => {
        let boundBox = {
          x1: defect.bbox.x * screenX,
          y1: defect.bbox.y * screenY,
          x2: (defect.bbox.x + defect.bbox.width) * screenX,
          y2: (defect.bbox.y + defect.bbox.height) * screenY
        };

        const boxInfo = newBox({
          ...boundBox,
          isFocused: false,
          isEdited: false,
          type: AssetType.DEFECT
        });

        if (boxInfo) {
          boxes.push(boxInfo);
          drawBoxOn(boxInfo, context, !editEnabled);
        }
      });
    }

    if (
      (((assetCan.corrected_bbox &&
        corrected_type &&
        corrected_type === AssetType.FALSE_POSITIVE) ||
        (assetCan.type === AssetType.FALSE_POSITIVE && !corrected_type) ||
        (corrected_type && corrected_type === AssetType.FALSE_POSITIVE)) &&
        !editEnabled) ||
      (!showAssets && !editEnabled)
    ) {
      return;
    }
    let boundBox = {
      x1: x * screenX,
      y1: y * screenY,
      x2: (x + width) * screenX,
      y2: (y + height) * screenY
    };
    const prevBox = oldBoxes.find(box => box && box.id === assetCan.id);
    let isEdited = !!assetCan.isEdited;
    if (
      editEnabled &&
      prevBox &&
      !prevBox.isFocused &&
      prevBox.isEdited &&
      (currentSaving.id !== prevBox.id ||
        (currentSaving.id === prevBox.id && saving))
    ) {
      isEdited = true;
      boundBox = {
        x1: prevBox.x1,
        x2: prevBox.x2,
        y1: prevBox.y1,
        y2: prevBox.y2
      };
    }

    const boxInfo =
      currentSelection && currentSelection.id === assetCan.id
        ? currentSelection
        : newBox({
            ...boundBox,
            isFocused: false,
            ...assetCan,
            isEdited
          });

    if (boxInfo) {
      boxes.push(boxInfo);
      drawBoxOn(boxInfo, context, !editEnabled);
    }
  });

  if (editEnabled) {
    oldBoxes.forEach(box => {
      if (!box.id) {
        let isSame = boxes.find(
          b =>
            b.x1.toFixed(0) === box.x1.toFixed(0) &&
            b.x2.toFixed(0) === box.x2.toFixed(0) &&
            b.y1.toFixed(0) === box.y1.toFixed(0) &&
            b.y2.toFixed(0) === box.y2.toFixed(0)
        );
        !isSame && box.type !== AssetType.DEFECT && boxes.push(box);
        !isSame &&
          box.type !== AssetType.DEFECT &&
          drawBoxOn(box, context, !editEnabled);
      }
    });
    !panEditMode && canvas.addEventListener("mousedown", mouseDown, false);
    !panEditMode && canvas.addEventListener("mouseup", mouseUp, false);
    !panEditMode && canvas.addEventListener("mousemove", mouseMove, false);
    !panEditMode && canvas.addEventListener("mouseout", mouseOut, false);
  }
};

export const clearUnsavedBoxes = () => {
  let newBoxes = [];
  boxes.forEach(box => box && !box.isEdited && newBoxes.push(box));
  boxes = newBoxes;
};
