import React, { useRef, useEffect, memo } from "react";
import "../../../App.css";
import { loadBoxes } from "./draw-rectangle";

const Canvas = memo(
  ({
    showAnnotations,
    asset_candidates,
    imageRef,
    editEnabled,
    panEditMode,
    showAssets,
    showDefects,
    scale
  }) => {
    const canvasRef = useRef(null);
    useEffect(() => {
      if (asset_candidates && canvasRef.current && showAnnotations) {
        const IMAGE_TO_SCREEN_Y =
          imageRef.current.height / imageRef.current.naturalHeight;
        const IMAGE_TO_SCREEN_X =
          imageRef.current.width / imageRef.current.naturalWidth;

        loadBoxes({
          assets: asset_candidates,
          screenX: IMAGE_TO_SCREEN_X,
          screenY: IMAGE_TO_SCREEN_Y,
          editEnabled,
          imageRef,
          panEditMode,
          showAssets,
          showDefects,
          scale
        });
      }
    }, [showAnnotations, editEnabled, asset_candidates, panEditMode, scale]);

    return (
      <canvas
        style={{
          position: "absolute",
          display: showAnnotations ? "block" : "none",
          top: 0,
          left: 0,
          imageRendering: "pixelated"
        }}
        ref={canvasRef}
        id="canvas"
        width={imageRef.current ? imageRef.current.width : 500}
        height={imageRef.current ? imageRef.current.height : 1000}
      ></canvas>
    );
  }
);

export default Canvas;
