import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Typography,
  Divider,
  Switch,
  FormControlLabel
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { CameraDetectionType, InspectionIconType } from "../constants";
import CategoryItem from "./category-item";
import CategoryModal from "./category-modal";
import { EMAIL_REG } from "../constants";

const useStyles = makeStyles()(theme => ({
  formControl: {
    margin: theme.spacing(2),
    width: 1100
  },
  selectEmpty: {
    minWidth: 240,
    width: "100%"
  },
  input: {
    minHeight: 35,
    display: "flex",
    alignItems: "center"
  },
  iconSelect: {
    width: "100%"
  },
  label: {
    transform: "translate(14px, 27px) scale(1)"
  },
  menuPaper: {
    maxHeight: 200
  }
}));

const InspectionManagement = ({
  inspectionInfo = {
    inspection_name: "",
    checklist_enabled: false,
    checklist_template: [],
    camera_type: CameraDetectionType.TEXT,
    inspection_icon: InspectionIconType.METER,
    asset_scanner_enabled: false,
    gis_pole_layer: false,
    padmounts_layer: false
  },
  onInspectionManagementChange
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [camera, setCamera] = useState(inspectionInfo.camera_type);
  const [icon, setIcon] = useState(inspectionInfo.inspection_icon);
  const [checklistEnabled, setChecklistEnabled] = useState(
    inspectionInfo.checklist_enabled
  );
  const [currentCategory, setCurrentCategory] = useState(null);
  const [checklist, setChecklist] = useState(inspectionInfo.checklist_template);
  const [emailError, setEmailError] = useState(false);

  const [showAssetScanner, setShowAssetScanner] = useState(
    inspectionInfo.asset_scanner_enabled
  );
  const [showGIS, setShowGIS] = useState(inspectionInfo.gis_pole_layer);
  const [showPadmounts, setShowPadmounts] = useState(
    inspectionInfo.padmounts_layer
  );

  const onCategoryDeletion = id => {
    setChecklist(checklist.filter(item => item.id !== id));
  };

  useEffect(() => {
    onInspectionManagementChange({
      ...inspectionInfo,
      checklist_template: checklist
    });
  }, [checklist]);

  return (
    <form noValidate autoComplete="off" className={classes.formControl}>
      <Grid container spacing={2}>
        <Grid
          container
          item
          direction="row"
          spacing={2}
          style={{ paddingBottom: "16px" }}
        >
          <Grid item sm={4} xs={12}>
            <TextField
              id="inspection_name"
              required
              label={t("common:name")}
              variant="outlined"
              onChange={({ target }) =>
                onInspectionManagementChange({
                  ...inspectionInfo,
                  inspection_name: target.value
                })
              }
              defaultValue={inspectionInfo.inspection_name}
              className={classes.selectEmpty}
            />
          </Grid>

          <Grid item sm={2}>
            <FormControl
              variant="outlined"
              className={classes.iconSelect}
              size="small"
            >
              <InputLabel id="inspection-icon-label">
                {t("inspection:icon")}
              </InputLabel>
              <Select
                labelId="inspection-icon-label"
                id="inspection-icon"
                value={icon}
                onChange={({ target }) => {
                  setIcon(target.value);

                  onInspectionManagementChange({
                    ...inspectionInfo,
                    inspection_icon: target.value
                  });
                }}
                label={t("inspection:icon")}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                {InspectionIconType.allTypes.map(type => (
                  <MenuItem key={type} value={type}>
                    <ion-icon name={type} size="large"></ion-icon>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              id="inspection_trouble_email"
              label={t("common:troubleEmail")}
              variant="outlined"
              onChange={({ target }) =>
                onInspectionManagementChange({
                  ...inspectionInfo,
                  inspection_trouble_email: target.value
                })
              }
              defaultValue={inspectionInfo.inspection_trouble_email}
              error={emailError}
              helperText={emailError ? t("errorMessages:invalidEmail") : null}
              type="email"
              onBlur={() =>
                setEmailError(
                  inspectionInfo.inspection_trouble_email &&
                    inspectionInfo.inspection_trouble_email.length &&
                    !!!EMAIL_REG.test(
                      inspectionInfo.inspection_trouble_email.toLowerCase()
                    )
                )
              }
              onFocus={() => setEmailError(false)}
              className={classes.selectEmpty}
            />
          </Grid>

          <Grid container item direction="row" spacing={2}>
            <Grid container item direction="row">
              <FormControlLabel
                control={
                  <Switch
                    checked={checklistEnabled}
                    onChange={({ target }) => {
                      setChecklistEnabled(target.checked);
                      onInspectionManagementChange({
                        ...inspectionInfo,
                        checklist_enabled: target.checked
                      });
                    }}
                    name="showChecklist"
                    color="primary"
                  />
                }
                label={t("inspection:trouble")}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showGIS}
                    onChange={({ target }) => {
                      setShowGIS(target.checked);
                      onInspectionManagementChange({
                        ...inspectionInfo,
                        gis_pole_layer: target.checked
                      });
                    }}
                    name="showGIS"
                    color="primary"
                  />
                }
                label="GIS Pole Layer"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showPadmounts}
                    onChange={({ target }) => {
                      setShowPadmounts(target.checked);
                      onInspectionManagementChange({
                        ...inspectionInfo,
                        padmounts_layer: target.checked
                      });
                    }}
                    name="showPadmounts"
                    color="primary"
                  />
                }
                label="Padmounts Layer"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={showAssetScanner}
                    onChange={({ target }) => {
                      setShowAssetScanner(target.checked);
                      onInspectionManagementChange({
                        ...inspectionInfo,
                        asset_scanner_enabled: target.checked
                      });
                    }}
                    name="showAssetScanner"
                    color="primary"
                  />
                }
                label="Asset Scanner"
              />
            </Grid>
          </Grid>

          <Grid item sm={4}>
            {showAssetScanner ? (
              <FormControl
                variant="outlined"
                className={classes.selectEmpty}
                style={{ marginTop: "8px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("inspection:cameraDetection")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={camera}
                  onChange={({ target }) => {
                    setCamera(target.value);

                    onInspectionManagementChange({
                      ...inspectionInfo,
                      camera_type: target.value
                    });
                  }}
                  label={t("inspection:cameraDetection")}
                  classes={{ outlined: classes.input }}
                >
                  {CameraDetectionType.allTypes.map(type => (
                    <MenuItem key={type} value={type}>
                      {t(`camera:${type}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
        </Grid>
        {checklistEnabled && (
          <Grid container item direction="column">
            <Grid item style={{ padding: 4 }}>
              <Typography variant="h6">{t("inspection:checklist")} </Typography>
              <Divider />
            </Grid>
            <Grid item style={{ width: `100%` }}>
              {checklist &&
                checklist.map(category => (
                  <CategoryItem
                    onEdit={() => {
                      setCurrentCategory(category);
                    }}
                    key={category.id}
                    classes={classes}
                    onDelete={onCategoryDeletion}
                    {...category}
                  />
                ))}
            </Grid>

            <Grid item>
              <Button
                onClick={() =>
                  setCurrentCategory({
                    category_name: "",
                    answers: [],
                    colour: "#f44336"
                  })
                }
              >
                {t("inspection:addCat")}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <CategoryModal
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        checklist={checklist}
        setChecklist={setChecklist}
      />
    </form>
  );
};

export default InspectionManagement;
