import React from "react";
import moment from "moment";

export const nameSort = item => item.name;

export const dateSort = item =>
  item.date.toDate ? item.date.toDate() : item.date;
export const creationSort = item =>
  item.creation.toDate ? item.creation.toDate() : item.creation;
export const csvTableSorter = (a, b, orderBy) => {
  let bProp = b[orderBy];
  let aProp = a[orderBy];
  switch (orderBy) {
    case "name":
      bProp = nameSort(b);
      aProp = nameSort(a);
      break;
    case "date":
      bProp = dateSort(b);
      aProp = dateSort(a);
      break;
    case "creation":
      bProp = creationSort(b);
      aProp = creationSort(a);
      break;
    default:
      break;
  }
  return { aProp, bProp };
};

export const csvTableInfo = {
  columns: [
    { id: "name", label: "common:name" },
    { id: "date", label: "common:date" },
    { id: "creation", label: "dataDownload:creationDate" },
    { id: "download", label: "" }
  ],
  rows: [
    {
      accessor: row => row.name,
      otherRowProps: { component: "th", scope: "row" }
    },
    {
      accessor: row => moment(row.date).format("YYYY/MM/DD")
    },
    {
      accessor: row => moment(row.creation).format("YYYY/MM/DD HH:mm")
    },
    {
      accessor: row => <a href={row.url}>Download</a>
    }
  ],
  startOrder: "date",
  rowsPerPageOptions: [10, 20, 30],
  search: row => row.name,
  startDirection: "desc",
  numRows: 10
};
