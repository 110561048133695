import i18n from "i18next";
import moment from "moment";

export const troubleStatusSort = item =>
  item && item.mode && item.mode === "normal"
    ? "Normal"
    : item.mode
    ? i18n.t(`status:${item.trouble_status || "submitted"}`)
    : "";
export const timeCreatedSort = item =>
  item && item.time_created ? moment.unix(item.time_created.seconds) : "";
export const userSort = item =>
  item && item.user_display_name
    ? item.user_display_name
    : item.user_email
    ? item.user_email
    : "";
export const addressSort = item => {
  if (!item || (item && !item.geo_location_summary)) {
    return "";
  } else {
    const street_address = item.geo_location_summary.street_address;
    const municipality = item.geo_location_summary.municipality || "";
    return street_address ? `${street_address} ${municipality}` : municipality;
  }
};

export const referenceSort = item =>
  item && item.trouble_status_reference_number
    ? item.trouble_status_reference_number
    : "";

export const tableSorter = (a, b, orderBy) => {
  let bProp = b[orderBy];
  let aProp = a[orderBy];

  switch (orderBy) {
    case "trouble_status":
      bProp = troubleStatusSort(b);
      aProp = troubleStatusSort(a);
      break;
    case "time_created":
      bProp = timeCreatedSort(b);
      aProp = timeCreatedSort(a);
      break;
    case "submittedBy":
      bProp = userSort(b);
      aProp = userSort(a);
      break;
    case "address":
      bProp = addressSort(b);
      aProp = addressSort(a);
      break;
    case "reference":
      bProp = referenceSort(b);
      aProp = referenceSort(a);
      break;
    default:
      break;
  }
  return { aProp, bProp };
};
