import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import ClickableIcon from "../ui/ClickableIcon";
import HelpSlideModal from "./help-slide-modal";
import { useFirestore } from "reactfire";
import firebase from "firebase";
import UnsavedChangesDialog from "../ui/UnsavedChangesDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import ManagementEditModal from "./management-edit-modal";

const DraggableListItem = ({ item, index, setCurrentSlide, onDelete }) => {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState(false);
  const alternativeMessage = {
    title: "Delete Slide",
    description: "Are you sure you wish to delete this slide?"
  };
  return (
    <Draggable draggableId={item.id} index={index}>
      {provided => (
        <Accordion
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ fontSize: "1rem" }} />}
            style={{
              margin: 0
            }}
            testID="Accordian"
          >
            <Typography>
              {item.slide_title} / {item.slide_title_fr}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexWrap: "wrap" }}>
            <Grid container direction="row">
              <Grid item sm={4}>
                <img
                  src={item.download_url}
                  style={{ width: 300, backgroundColor: "lightgrey" }}
                  resizeMode="contain"
                />
              </Grid>
              <Grid container item sm={8} spacing={2}>
                <Grid item xs={6}>
                  <Typography>{item.slide_text}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography>{item.slide_text_fr}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
              <ClickableIcon
                title={t("common:edit")}
                IconImage={EditIcon}
                onClick={() => setCurrentSlide(item)}
              />
              <ClickableIcon
                title={t("common:delete")}
                IconImage={DeleteIcon}
                onClick={() => setShowMessage(true)}
              />
            </Grid>
          </AccordionDetails>

          <UnsavedChangesDialog
            open={showMessage}
            onYes={() => {
              setShowMessage(false);
              onDelete(item.id);
            }}
            onNo={() => setShowMessage(false)}
            alternativeMessage={alternativeMessage}
          />
        </Accordion>
      )}
    </Draggable>
  );
};

const DraggableList = React.memo(
  ({ items, onDragEnd, setCurrentSlide, onDelete }) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <DraggableListItem
                  item={item}
                  index={index}
                  key={item.id}
                  setCurrentSlide={setCurrentSlide}
                  onDelete={onDelete}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

const useStyles = makeStyles()(theme => ({
  formControl: {
    margin: theme.spacing(2),
    width: 900
  },
  selectEmpty: {
    minWidth: 240,
    width: "100%"
  },
  table: {
    marginTop: theme.spacing(2)
  },
  chips: {
    maxWidth: 700,
    margin: theme.spacing(1),
    minWidth: 500,
    width: "90%"
  }
}));

const HelpManagement = ({ inspections, visible, onCancel, resetModal }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [currentSlide, setCurrentSlide] = useState(null);
  const [items, setItems] = useState([]);
  const firestore = useFirestore();

  const onDelete = id => {
    firestore.collection("help_slides").doc(id).delete();
  };

  useEffect(() => {
    const updateItems = async querySnapshot => {
      const snapSlides = [];
      querySnapshot.forEach(async doc => {
        const sub = doc.data();
        sub.id = doc.id;

        snapSlides.push(sub);
      });
      setItems(snapSlides);
    };
    const unsubscribe = firestore
      .collection("help_slides")
      .orderBy("order")
      .onSnapshot(updateItems);
    return () => unsubscribe();
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    let result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  };

  const saveOrder = () => {
    items.forEach((item, i) => {
      firestore
        .collection("help_slides")
        .doc(item.id)
        .update({ order: i }, { merge: true });
    });
    resetModal();
  };

  return (
    <ManagementEditModal
      open={visible}
      onCancel={onCancel}
      onSave={saveOrder}
      modalTitle="Edit Help Tutorial"
    >
      <Grid style={{ minWidth: 900, padding: 8 }} container direction="column">
        <DraggableList
          items={items}
          onDragEnd={onDragEnd}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          onDelete={onDelete}
        />
        <Grid item>
          <Button
            onClick={() =>
              setCurrentSlide({
                slide_title: "",
                slide_text: "",
                icon: null
              })
            }
          >
            Add Slide
          </Button>
        </Grid>
        <HelpSlideModal
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          slides={items}
          setSlides={setItems}
          inspections={inspections}
        />
      </Grid>
    </ManagementEditModal>
  );
};

export default HelpManagement;
