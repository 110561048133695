import { useEffect } from "react";

export const useEventListener = (ref, event, listener, options) => {
  useEffect(() => {
    const node = ref.current;

    if (!node) {
      return;
    }

    const listenerWrapper = (e) => listener(e);

    node.addEventListener(event, listenerWrapper, options);

    return () => node.removeEventListener(event, listenerWrapper);
  }, [ref, event, listener, options]);
};

//Referenced the code and ideas from https://www.jclem.net/posts/pan-zoom-canvas-react