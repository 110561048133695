import moment from "moment";

export const checkFilters = ({
  sub,
  filters: {
    modes,
    tags,
    users,
    dates,
    status,
    submissionTypes,
    highPriority,
    troubleStatusUsers,
    districts
  },
  isAsset,
  inspections,
  map
}) => {
  if (modes && modes.length && !modes.includes(sub.mode)) {
    return false;
  }
  if (tags && tags.length) {
    const common = tags.some(
      tag =>
        (sub.mode_tags && sub.mode_tags.indexOf(tag) >= 0) ||
        sub.inspection_type === tag
    );
    if (!common) {
      return false;
    }
  }
  if (users && users.length && !users.includes(sub.user_email)) {
    return false;
  }
  let troubleModifier = null;
  if (
    sub.trouble_status_history &&
    sub.trouble_status_history.length &&
    troubleStatusUsers &&
    troubleStatusUsers.length
  ) {
    sub.trouble_status_history.forEach(historyItem => {
      if (troubleStatusUsers.includes(historyItem.modified_by.email)) {
        troubleModifier = historyItem.modified_by.email;
      }
    });
  }
  if (troubleStatusUsers && troubleStatusUsers.length && !troubleModifier) {
    return false;
  }
  if (dates && isAsset) {
    const { toDate, fromDate } = dates;

    const subTimeCreated = moment
      .unix(sub.time_created.seconds)
      .format("MMM D, YYYY h:mm a");

    if (fromDate && subTimeCreated < moment(fromDate).startOf("day").toDate()) {
      return false;
    }
    if (toDate && subTimeCreated > moment(toDate).endOf("day").toDate()) {
      return false;
    }
  }

  if (
    status &&
    status.length &&
    (sub.mode === "normal" ||
      (sub.trouble_status && !status.includes(sub.trouble_status)) ||
      (!sub.trouble_status && !status.includes("submitted")))
  ) {
    return false;
  }

  let inspection = null;

  if (sub.inspection_id && inspections) {
    inspection = inspections.find(inspect => inspect.id === sub.inspection_id);
  }

  if (
    submissionTypes &&
    submissionTypes.length &&
    !submissionTypes.includes("inspection") &&
    submissionTypes.includes("3phase") &&
    (!inspection || (inspection && inspection.inspection_type !== "3phase"))
  ) {
    return false;
  }
  if (
    submissionTypes &&
    submissionTypes.length &&
    submissionTypes.includes("inspection") &&
    !submissionTypes.includes("3phase") &&
    inspection &&
    inspection.inspection_type === "3phase"
  ) {
    return false;
  }
  if (
    submissionTypes &&
    submissionTypes.length &&
    !submissionTypes.includes(sub.submission_type) &&
    (!submissionTypes.includes("3phase") ||
      (submissionTypes.includes("3phase") && submissionTypes.length > 1))
  ) {
    return false;
  }
  if (highPriority && !sub.high_priority) {
    return false;
  }

  if (districts.length) {
    let inBounds = districts.find(dist => {
      const subLatLng = new window.google.maps.LatLng(
        sub.group_latitude,
        sub.group_longitude
      );

      const containsLoc = window.google.maps.geometry.poly.containsLocation(
        subLatLng,
        dist.polygon
      );
      return containsLoc;
    });

    if (!inBounds) {
      return false;
    }
  }
  return true;
};

export const getUniqueUsers = ({ submissions, users }) => {
  const subUserArr = submissions.map(sub => {
    return {
      user_display_name: sub.user_display_name || sub.user_email,
      user_email: sub.user_email
    };
  });
  const restUsers = [...users, ...subUserArr];
  return restUsers.filter(
    (user, index, self) =>
      index ===
      self.findIndex(
        t =>
          t.user_display_name === user.user_display_name &&
          t.user_email === user.user_email
      )
  );
};

export const checkSubmission = ({
  sub,
  filters,
  currDate,
  isAsset,
  inspections,
  map
}) => {
  const subTimeCreated = moment
    .unix(sub.time_created.seconds)
    .format("MMM D, YYYY h:mm a");

  if (subTimeCreated > currDate) {
    sub.new = true;
  }
  const valid = checkFilters({ sub, filters, isAsset, inspections, map });
  if (valid) {
    return sub;
  }
  return;
};
