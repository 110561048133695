import React, { useEffect, useState, useContext } from "react";
import Map from "../map";
import StatsDash from "../ui/StatsDash";
import Filters from "../ui/Filters";
import EnhancedTable from "../ui/EnhancedTable/EnhancedTable";
import PhotoCarousel from "../ui/PhotoCarousel";
import { useFirestore } from "reactfire";
import { Grid, CircularProgress } from "@mui/material";
import {
  MapContext,
  CarouselContext,
  SubmissionsContext
} from "../../contexts";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import {
  tableSorter,
  userSort,
  addressSort,
  troubleStatusSort
} from "./table-utils";
import moment from "moment";

const Home = () => {
  const firestore = useFirestore();
  const location = useLocation();
  const { visible, setCarouselInfo } = useContext(CarouselContext);
  const { setLiveFeed } = useContext(MapContext);

  useEffect(() => {
    setLiveFeed(false);
    const match = matchPath(location.pathname, {
      path: "/carousel/:id",
      exact: true,
      strict: false
    });

    const loadSubmissionInfo = promisedResults => {
      const images = [];
      let submission;
      promisedResults.forEach(results => {
        results.forEach(async doc => {
          const sub = doc.data();
          sub.id = doc.id;
          if (sub.download_link) {
            document.getElementById("preloader").src = sub.download_link;
            images.push(sub);
          } else {
            submission = sub;
          }
        });
      });
      if (submission) {
        setCarouselInfo(submission, images, 0);
      }
      setLoadingSubmission(false);
    };

    if (match && match.isExact) {
      setLoadingSubmission(true);
      const id = match.params.id;
      Promise.all([
        firestore.collection("submissions").where("group_id", "==", id).get(),
        firestore
          .collection("submission_files")
          .where("group_id", "==", id)
          .get()
      ]).then(loadSubmissionInfo);
    }
  }, []);

  const { submissionsInBounds, inspections } = useContext(SubmissionsContext);

  const { zoomToSubmission } = useContext(MapContext);

  const [loadingSubmission, setLoadingSubmission] = useState(false);

  const determineInspection = submission => {
    if (submission.submission_type === "general") {
      return submission.submission_type;
    } else {
      let title = "Inspection";
      if (submission.inspection_id) {
        let inspection = inspections.find(
          inspect => inspect.id === submission.inspection_id
        );
        return inspection ? `${title} - ${inspection.inspection_name}` : title;
      } else {
        return `${title} - Meter`;
      }
    }
  };

  const tableInfo = {
    columns: [
      { id: "submittedBy", label: "table:submittedBy" },
      { id: "time_created", label: "table:submitted" },
      { id: "type", label: "filters:submissionType" },
      { id: "mode", label: "filters:mode" },
      {
        id: "trouble_status",
        label: "table:status"
      },
      {
        id: "reference",
        label: "statusTable:referenceNumber"
      },
      { id: "address", label: "table:address" }
    ],
    rows: [
      {
        accessor: userSort,
        otherRowProps: { component: "th", scope: "row" }
      },
      {
        accessor: row =>
          moment.unix(row.time_created.seconds).format("YYYY/MM/DD HH:mm")
      },
      {
        accessor: row => determineInspection(row),
        style: { textTransform: "capitalize" }
      },
      {
        accessor: row => row.mode,
        style: { textTransform: "capitalize" }
      },
      {
        accessor: troubleStatusSort
      },
      {
        accessor: row => row.trouble_status_reference_number
      },
      {
        accessor: addressSort
      }
    ],
    startOrder: "submittedBy",
    rowsPerPageOptions: [10, 20, 30],
    tableStyles: { maxHeight: 275, overflow: "auto" },
    numRows: 10
  };

  const checkTableSort = (a, b, orderBy) => {
    if (orderBy === "type") {
      let bProp = determineInspection(b);
      let aProp = determineInspection(a);
      return { aProp, bProp };
    } else {
      return tableSorter(a, b, orderBy);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row"
      }}
    >
      {loadingSubmission ? (
        <CircularProgress />
      ) : (
        <>
          <Grid
            container
            style={{
              marginLeft: "10px"
            }}
          >
            <Map />
          </Grid>
          <Grid
            container
            direction="column"
            style={{
              margin: "10px"
            }}
          >
            <StatsDash />
            <Filters />
            <EnhancedTable
              info={submissionsInBounds}
              tableInfo={tableInfo}
              onRowSelected={zoomToSubmission}
              sortAccessor={checkTableSort}
              exportCSV="Home"
            />
          </Grid>
        </>
      )}
      {visible && <PhotoCarousel />}
      <img id="preloader" alt="preloader" style={{ display: "none" }} />
    </div>
  );
};
export default Home;
