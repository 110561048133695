import firebase from "firebase";
import moment from "moment";

const request = async ({ endpoint, body, method = "GET" }) => {
  return firebase
    .auth()
    .currentUser.getIdTokenResult(true)
    .then(res => {
      const { token } = res;
      const headers = new Headers();
      headers.append("Authorization", token);
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Credentials", true);
      headers.append("Cookie", `token=${token}`);

      return fetch(`https://nb-power-pano-prod.appspot.com/${endpoint}`, {
        method,
        headers,
        credentials: "include",
        body: body ? JSON.stringify(body) : null
      })
        .then(async response => {
          const json = await response.json();

          if (response.status === 200) {
            return json;
          } else {
            console.log(response);
          }
        })
        .catch(error => {
          console.log(error.message);
        });
    })
    .catch(reason => {
      //alert(reason);
      console.log(reason);
    });
};

export const revokeSession = () => {
  const action = "signout";
  const endpoint = `revokeSession?action=${action}`;
  return request({
    endpoint
  });
};

export const getImageCount = ({ user, bounds, zoom, filters, liveFeed }) => {
  let filterString = `maxLat=${bounds.maxLat}&maxLng=${bounds.maxLng}&minLat=${bounds.minLat}&minLng=${bounds.minLng}&zoomLevel=${zoom}&`;
  //TODO: revisit filters
  if (filters.dates) {
    const { fromDate, toDate } = filters.dates;
    const queryFromDate = fromDate
      ? `dateFrom=${moment(fromDate).startOf("day").unix()}&`
      : "";
    const queryToDate = toDate
      ? `dateTo=${moment(toDate).endOf("day").unix()}&`
      : "";
    filterString += `${queryFromDate}${queryToDate}`;
  }
  if (liveFeed) {
    if (filters.tags) {
      filters.tags.map(tag => {
        filterString += `${tag}=true&`;
      });
    }
    filterString += `mode=trouble&`;
  }

  const endpoint = `image-count?${filterString}`;
  return request({
    user,
    endpoint
  });
};
