export const calculateRadiusBasedOnZoom = map => {
  const bounds = map.getBounds();
  const height = window.google.maps.geometry.spherical.computeDistanceBetween(
    new window.google.maps.LatLng(bounds.toJSON().north, bounds.toJSON().east),
    new window.google.maps.LatLng(bounds.toJSON().south, bounds.toJSON().east)
  );

  const width = window.google.maps.geometry.spherical.computeDistanceBetween(
    new window.google.maps.LatLng(bounds.toJSON().north, bounds.toJSON().east),
    new window.google.maps.LatLng(bounds.toJSON().north, bounds.toJSON().west)
  );
  const radiusInMeters =
    Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 2;

  return radiusInMeters / 1000 / 2;
};

export const calculatePointInBounds = ({ map, submissions }) => {
  const bounds = map.getBounds();
  const inBounds = submissions.filter(submission => {
    return bounds.contains(
      new window.google.maps.LatLng(
        submission.group_latitude,
        submission.group_longitude
      )
    );
  });
  return inBounds;
};
