import React, {
  useContext,
  useState,
  Fragment,
  useRef,
  useEffect
} from "react";
import { useTranslation } from "react-i18next";
import colors from "./colors";
import { TagType, StatusType } from "../constants";
import {
  TextField,
  Grid,
  Paper,
  Button,
  Divider,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ChipAutoComplete from "./ChipAutoComplete";
import {
  FiltersContext,
  MapContext,
  SubmissionsContext,
  UserContext
} from "../../contexts";
import moment from "moment";
import { debounce } from "lodash";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const useStyles = makeStyles()((theme, _params, classes) => ({
  root: {
    padding: theme.spacing(1)
  },
  textField: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTextField-root": {
      minWidth: "10ch",
      width: "100%",
      margin: "4px 0"
    }
  },
  dateContainer: {
    minWidth: "10ch",
    width: "100%",
    margin: "0 2px"
  },
  recenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 4
  },
  marginCom: {
    display: "flex",
    justifyContent: "space-between"
  },
  divider: {
    margin: theme.spacing(1, 0),
    width: "100%"
  },
  dataPeriodError: {
    alignSelf: "start",
    color: colors.red,
    height: 2,
    marginLeft: 10,
    fontSize: 12
  }
}));

const modes = [
  { label: "Trouble", value: "trouble" },
  { label: "Normal", value: "normal" }
];

const surveys = [
  { label: "Inspection", value: "inspection" },
  { label: "Inspection - 3 Phase", value: "3phase" },
  { label: "General", value: "general" }
];

const InputField = ({ params, type, label }) => {
  const { t } = useTranslation();
  return (
    <TextField
      {...params}
      label={label || t(`filters:${type}`)}
      variant="outlined"
    />
  );
};

const DateFields = ({ fromDate, toDate, setFromDate, setToDate }) => {
  const { setDateFilters, showPeriodInvalid, setShowPeriodInvalid } =
    useContext(FiltersContext);
  const { recenterMap } = useContext(MapContext);
  const { submissions } = useContext(SubmissionsContext);
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const fromRef = useRef();
  const toRef = useRef();
  const validFromDate = fromDate && moment(fromDate).isValid();
  const validToDate = toDate && moment(toDate).isValid();

  const changeFromDate = date => {
    setShowPeriodInvalid(false);
    const newDate = moment(date);
    let currentToDate = moment(toDate);
    if ((newDate.isValid() && !newDate.isAfter()) || (fromDate && !date)) {
      let newFilters = { toDate, fromDate: date };
      if (toDate && currentToDate < newDate) {
        currentToDate = moment(date).add(1, "day");
        setToDate(currentToDate.toDate());
        newFilters.toDate = currentToDate.toDate();
      }
      checkDatePeriod(newFilters);
    }
    setFromDate(date);
  };

  const changeToDate = date => {
    setShowPeriodInvalid(false);
    const newDate = moment(date);
    let currentFromDate = moment(fromDate);
    if ((newDate.isValid() && !newDate.isAfter()) || (toDate && !date)) {
      let newFilters = { toDate: date, fromDate };
      if (fromDate && currentFromDate > newDate) {
        currentFromDate = moment(date).subtract(1, "day");
        setFromDate(currentFromDate.toDate());
        newFilters.fromDate = currentFromDate.toDate();
      }

      checkDatePeriod(newFilters);
    }

    setToDate(date);
  };

  const checkDatePeriod = newFilters => {
    const fromDate = newFilters.fromDate
      ? moment(newFilters.fromDate)
      : moment();
    const toDate = newFilters.toDate ? moment(newFilters.toDate) : moment();
    let fromDateEmpty = Math.abs(fromDate - moment());
    fromDateEmpty = Math.ceil(fromDateEmpty / (1000 * 60 * 60 * 24));
    const diffTime = Math.abs(toDate - fromDate);
    const diffDays = fromDateEmpty
      ? Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      : 61;
    if (diffDays <= 90) {
      setDateFilters(newFilters);
    } else {
      setShowPeriodInvalid(true);
    }
  };
  return (
    <Fragment>
      <Grid container spacing={0}>
        <Grid item className={classes.marginCom} md={12} xs={6}>
          <div ref={fromRef} className={classes.dateContainer}>
            <DatePicker
              variant="inline"
              inputFormat="MM/dd/yyyy"
              label="From"
              margin="normal"
              value={fromDate}
              onChange={changeFromDate}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              inputVariant="outlined"
              disableFuture={true}
              id="from"
              PopoverProps={{
                anchorEl
              }}
              onOpen={() => setAnchorEl(fromRef.current)}
              className={classes.textField}
              autoOk={true}
              renderInput={props => (
                <TextField {...props} className={classes.textField} />
              )}
            />
          </div>
          <div ref={toRef} className={classes.dateContainer}>
            <DatePicker
              variant="inline"
              label="To"
              inputFormat="MM/dd/yyyy"
              margin="normal"
              value={toDate}
              onChange={changeToDate}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              inputVariant="outlined"
              disableFuture={true}
              id="to"
              PopoverProps={{
                anchorEl
              }}
              onOpen={() => setAnchorEl(toRef.current)}
              className={classes.textField}
              autoOk={true}
              renderInput={props => (
                <TextField {...props} className={classes.textField} />
              )}
            />
          </div>
          <div className={classes.recenter}>
            <Button
              variant="contained"
              disabled={
                (!toDate && !fromDate) ||
                !submissions.length ||
                showPeriodInvalid
              }
              onClick={recenterMap}
            >
              Recenter
            </Button>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            {showPeriodInvalid && (validFromDate || validToDate) ? (
              <p className={classes.dataPeriodError}>
                Date period should not be more than 3 months
              </p>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const Filters = ({ liveView, disabledFilters = [] }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    setModes,
    setTags,
    setUserFilter,
    users,
    setSubmissionType,
    setAssetId,
    assetRef,
    setActive,
    clearFilters,
    filters,
    setStatus,
    assetId,
    onHighPriority,
    setTroubleStatusUserFilter,
    troubleStatusUsers,
    districts,
    setDistrictsFiltersArr
  } = useContext(FiltersContext);
  const [assetValue, setAssetValue] = useState(assetId || "");
  const [fromDate, setFromDate] = useState(
    filters.dates
      ? filters.dates.fromDate
        ? filters.dates.fromDate
        : null
      : null
  );
  const [toDate, setToDate] = useState(
    filters.dates ? (filters.dates.toDate ? filters.dates.toDate : null) : null
  );
  const { recenterLivefeed, liveFeed, map } = useContext(MapContext);
  const { submissions } = useContext(SubmissionsContext);
  const { troubleEditor } = useContext(UserContext);

  const tagAccessor = tag => {
    return {
      label: t(`tags:${tag}`),
      value: tag
    };
  };
  const [tagOptions, setTagOptions] = useState(
    TagType.allTypes.map(tagAccessor)
  );

  const statusAccessor = status => {
    return {
      label: t(`status:${status}`),
      value: status
    };
  };

  useEffect(() => {
    setTagOptions(
      liveFeed
        ? TagType.liveFeedTypes.map(tagAccessor)
        : TagType.allTypes.map(tagAccessor)
    );
  }, [liveFeed]);

  const resetDistricts = () => {
    map.data.setStyle(() => {
      return {
        fillOpacity: 0,
        strokeWeight: 0
      };
    });
  };

  const onDistrictsChange = chips => {
    setDistrictsFiltersArr(chips);
    map.data.setStyle(feature => {
      var value = feature.getProperty("DISTRICTNUMBER");
      const districtFeature = chips.find(chip => chip.value === value);
      if (districtFeature) {
        return {
          fillOpacity: 0.4,
          fillColor: colors.panoBeige,
          strokeWeight: 2
        };
      } else
        return {
          fillOpacity: 0,
          strokeWeight: 0
        };
    });
  };

  const getTagType = modes => {
    if (modes.includes("normal") && !modes.includes("trouble")) {
      return TagType.normalModeTypes.map(tagAccessor);
    } else if (modes.includes("trouble") && !modes.includes("normal")) {
      return TagType.troubleModeTypes.map(tagAccessor);
    }
    return TagType.allTypes.map(tagAccessor);
  };

  const onModeChange = options => {
    const modes = options.map(item => item.value);
    const tags = getTagType(modes);
    setTagOptions(tags);
    setModes(modes);
  };

  const debounceAsset = debounce(setAssetId, 500);

  const onAssetChange = event => {
    const asset = event.target.value;
    if (asset.length > 3) {
      debounceAsset(asset);
    } else {
      setAssetId(null);
    }
    setAssetValue(asset);
  };

  const onAssetEnter = event => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };

  return (
    <Paper style={{ margin: "10px 0" }}>
      <Grid container direction="column">
        <Grid
          item
          style={{
            padding: "10px",
            background: colors.headerGray,
            color: "white",
            textAlign: "center",
            fontWeight: "bold",
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5
          }}
        >
          {t("filters:filters")}
        </Grid>
        <Grid container className={classes.root}>
          {!liveView && (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateFields
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                />
              </LocalizationProvider>
              <Divider className={classes.divider} />
            </>
          )}
          <Grid item className={classes.textField} md={12} xs={6}>
            <ChipAutoComplete
              options={users}
              fieldLabel={t("filters:users")}
              labelExtractor={option => option.user_display_name}
              onChange={setUserFilter}
              getOptionSelected={(option, value) =>
                value.user_email === option.user_email
              }
              value={filters.users.map(user =>
                users.find(u => u.user_email === user)
              )}
            />
            {!liveView ? (
              <ChipAutoComplete
                options={modes}
                fieldLabel={t("filters:mode")}
                labelExtractor={option => option.label}
                onChange={onModeChange}
                value={filters.modes.map(mode =>
                  modes.find(m => m.value === mode)
                )}
                disabled={!!disabledFilters.find(filter => filter === "mode")}
              />
            ) : (
              <ChipAutoComplete
                options={districts}
                fieldLabel={t("filters:district")}
                labelExtractor={option => option.label}
                onChange={onDistrictsChange}
                value={filters.districts.map(district =>
                  districts.find(m => m.value === district.value)
                )}
              />
            )}
          </Grid>
          <Grid item className={classes.textField} md={12} xs={6}>
            {!liveView && (
              <ChipAutoComplete
                options={surveys}
                fieldLabel={t("filters:submissionType")}
                labelExtractor={option => option.label}
                onChange={setSubmissionType}
                value={filters.submissionTypes.map(subType =>
                  surveys.find(s => s.value === subType)
                )}
                disabled={
                  !!disabledFilters.find(filter => filter === "submissionType")
                }
              />
            )}

            {!liveView ? (
              <InputField
                params={{
                  onChange: onAssetChange,
                  onBlur: () => setActive(false),
                  onFocus: () => setActive(true),
                  type: "search",
                  onKeyPress: onAssetEnter,
                  style: { marginLeft: "2px", marginRight: "2px" },
                  inputRef: assetRef,
                  value: assetValue
                }}
                type="asset"
                label="Asset ID / Reference Number"
              />
            ) : null}
          </Grid>

          <Grid item className={classes.textField} md={12} xs={6}>
            <ChipAutoComplete
              options={tagOptions}
              fieldLabel={t("filters:tags")}
              labelExtractor={option => option.label}
              onChange={setTags}
              value={filters.tags.map(tag =>
                tagOptions.find(t => t.value === tag)
              )}
            />
            {!liveView ? (
              <ChipAutoComplete
                options={StatusType.allTypesFilters.map(statusAccessor)}
                fieldLabel={t("table:status")}
                labelExtractor={option => option.label}
                onChange={setStatus}
                value={filters.status.map(tag =>
                  StatusType.allTypesFilters
                    .map(statusAccessor)
                    .find(t => t.value === tag)
                )}
                disabled={!!disabledFilters.find(filter => filter === "status")}
              />
            ) : (
              <FormControlLabel
                key="high-priority"
                control={
                  <Checkbox
                    name="high-priority"
                    onChange={onHighPriority}
                    checked={filters.highPriority}
                  />
                }
                label="High Priority"
                style={{ minWidth: "135px", margin: 10 }}
              />
            )}
          </Grid>
          <Grid item className={classes.textField} md={12} xs={6}>
            {!liveFeed && troubleEditor ? (
              <ChipAutoComplete
                options={troubleStatusUsers}
                fieldLabel={t("filters:troubleStatusEditors")}
                labelExtractor={option =>
                  option.user_display_name || option.user_email
                }
                onChange={setTroubleStatusUserFilter}
                getOptionSelected={(option, value) =>
                  value.user_email === option.user_email
                }
                value={filters.troubleStatusUsers.map(user =>
                  troubleStatusUsers.find(u => u.user_email === user)
                )}
              />
            ) : null}
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "8px"
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                resetDistricts();
                setAssetId(null);
                clearFilters();
                setAssetValue("");
                setFromDate(null);
                setToDate(null);
              }}
              style={{
                marginRight: "10px"
              }}
            >
              Clear
            </Button>

            {liveFeed ? (
              <Button
                variant="contained"
                disabled={!submissions.length}
                onClick={recenterLivefeed}
              >
                Recenter
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Filters;
