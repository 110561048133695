import React, { useState, useRef } from "react";
import { CarouselContext } from "../contexts";

export const CarouselStore = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const contentComponentRef = useRef();
  const wrapperComponentRef = useRef();
  const [panState, setPanState] = useState({ x: 0, y: 0 });

  const imageRef = useRef();

  const [information, setInformation] = useState({
    submission: null,
    images: [],
    index: 0
  });

  const setCarouselInfo = (submission, images, index) => {
    setInformation({
      submission,
      images,
      index
    });
    setVisible(Boolean(submission));
  };

  return (
    <CarouselContext.Provider
      value={{
        visible,
        setVisible,
        information,
        setCarouselInfo,
        contentComponentRef,
        wrapperComponentRef,
        imageRef,
        panState,
        setPanState
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
};

export default CarouselStore;
