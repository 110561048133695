import React, { useState, useEffect } from "react";
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Grid
} from "@mui/material";

import { makeStyles } from "tss-react/mui";

import SearchBar from "../ui/EnhancedTable/SearchBar";

const useStyles = makeStyles()(theme => ({
  formControl: {
    margin: theme.spacing(2)
  }
}));

const ChecklistGroup = ({
  items = [],
  groupLabel,
  onCheckboxClick,
  hideSearchBar
}) => {
  const { classes } = useStyles();
  const [checklistItems, setChecklistItems] = useState(items);
  const search = item => item.label;

  const onSearch = ({ target: { value } }) => {
    const searched = items.filter(item =>
      search(item).toLowerCase().includes(value.toLowerCase())
    );
    setChecklistItems(searched);
  };

  useEffect(() => {
    setChecklistItems(items);
  }, [items]);

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <Grid container style={{ alignItems: "center" }}>
        <Typography
          style={{ flex: 1, paddingLeft: 3 }}
          variant="h6"
          id="managementSectionTitle"
          component="div"
        >
          {groupLabel}
        </Typography>
        {!hideSearchBar && (
          <Grid item style={{ marginBottom: 5 }}>
            <SearchBar onSearch={onSearch} />
          </Grid>
        )}
      </Grid>
      <FormGroup row={true} style={{ margin: 8 }}>
        {checklistItems.map(({ id, label, checked }) => (
          <FormControlLabel
            key={id}
            control={
              <Checkbox
                name={id}
                checked={checked}
                onChange={onCheckboxClick}
              />
            }
            label={label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default ChecklistGroup;
