import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import ChecklistGroup from "../ui/ChecklistGroup";

const useStyles = makeStyles()(theme => ({
  formControl: {
    margin: theme.spacing(2),
    width: 900
  },
  selectEmpty: {
    minWidth: 240,
    width: "100%"
  },
  table: {
    marginTop: theme.spacing(2)
  },
  chips: {
    maxWidth: 700,
    margin: theme.spacing(1),
    minWidth: 500,
    width: "90%"
  }
}));

const UserManagement = ({
  userInfo = {
    user_display_name: "",
    user_email: "",
    id: "",
    groups: []
  },
  onUserManagementChange,
  groups,
  emailErrorMessage
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [memberships, setMemberships] = useState(userInfo.groups);
  const [allGroups, setAllGroups] = useState([]);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    const currMemb = [];

    groups.forEach(group => {
      const hasMembership = group.group_members.find(
        member => member.id && userInfo.id === member.id
      );
      if (hasMembership) {
        currMemb.push(group);
      }
    });
    setMemberships(currMemb);
  }, [groups]);

  useEffect(() => {
    const all = groups.map(group => {
      return {
        id: group.id,
        label: group.group_name,
        checked: !!memberships.find(membership => group.id === membership.id)
      };
    });
    setAllGroups(all);
  }, [memberships]);

  const onMembershipChange = ({ target: { name } }) => {
    const isMember = !!memberships.find(membership => membership.id === name);
    let membs = memberships;
    if (isMember) {
      membs = membs.filter(mem => mem.id !== name);
    } else {
      const group = groups.find(item => item.id === name);

      if (group) {
        membs.push(group);
      }
    }
    setMemberships(membs);
    const all = groups.map(group => {
      return {
        id: group.id,
        label: group.group_name,
        checked: !!membs.find(membership => group.id === membership.id)
      };
    });
    setAllGroups(all);
    onUserManagementChange({ ...userInfo, groups: membs });
  };

  return (
    <form noValidate autoComplete="off" className={classes.formControl}>
      <Grid container direction="row" spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            id="display_name"
            required
            label={t("common:name")}
            variant="outlined"
            onChange={({ target }) =>
              onUserManagementChange({
                ...userInfo,
                user_display_name: target.value,
                groups: memberships
              })
            }
            defaultValue={userInfo.user_display_name}
            className={classes.selectEmpty}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            id="email"
            required
            label={t("common:email")}
            variant="outlined"
            onChange={({ target }) =>
              onUserManagementChange({
                ...userInfo,
                user_email: target.value,
                groups: memberships
              })
            }
            defaultValue={userInfo.user_email}
            className={classes.selectEmpty}
            error={emailError}
            helperText={emailError ? emailErrorMessage : null}
            type="email"
            onBlur={() => setEmailError(!!emailErrorMessage)}
            onFocus={() => setEmailError(false)}
          />
        </Grid>
        <ChecklistGroup
          items={allGroups}
          groupLabel="Group Memberships"
          onCheckboxClick={onMembershipChange}
        />
      </Grid>
    </form>
  );
};

export default UserManagement;
