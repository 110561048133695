import React, { useState, useRef, useEffect } from "react";
import { FiltersContext } from "../contexts";
import { useFirestore, useUser } from "reactfire";
import moment from "moment";

export const FiltersStore = ({ children }) => {
  const [filters, setFilters] = useState({
    modes: [],
    tags: [],
    users: [],
    submissionTypes: [],
    status: [],
    troubleStatusUsers: [],
    districts: []
  });
  const [users, setUsers] = useState([]);
  const [troubleStatusUsers, setTroubleStatusUsers] = useState([]);
  const [assetId, setAssetId] = useState(null);
  const assetRef = useRef();
  const [active, setActive] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [filterRemoved, setFilterRemoved] = useState(false);
  const firestore = useFirestore();
  const userInfo = useUser();
  const [showPeriodInvalid, setShowPeriodInvalid] = useState(false);
  const [districts, setDistricts] = useState([]);

  const getUsers = () => {
    const updateUsers = querySnapshot => {
      const snapUsers = [];
      querySnapshot.forEach(async doc => {
        const sub = doc.data();
        sub.id = doc.id;

        snapUsers.push(sub);
      });
      setUsers(snapUsers);
    };
    const unsubscribe = firestore.collection("users").onSnapshot(updateUsers);
    return () => {
      unsubscribe();
    };
  };

  const getTroubleStatusEditors = () => {
    const updateSubs = querySnapshot => {
      const snapUsers = [];
      querySnapshot.forEach(async doc => {
        const sub = doc.data();
        sub.trouble_status_history.forEach(historyItem => {
          const hasUser = snapUsers.find(
            item => item.user_email === historyItem.modified_by.email
          );
          if (!hasUser && historyItem.modified_by.email) {
            snapUsers.push({
              user_email: historyItem.modified_by.email,
              user_display_name: historyItem.modified_by.display_name
            });
          }
        });
      });
      setTroubleStatusUsers(snapUsers);
    };
    const unsubscribe = firestore
      .collection("submissions")
      .where("trouble_status_history", "!=", "[]")
      .onSnapshot(updateSubs);
    return () => {
      unsubscribe();
    };
  };

  useEffect(() => {
    if (userInfo.data) {
      getUsers();
    }
    getTroubleStatusEditors();
  }, []);

  const setTags = chips => {
    const tags = chips.map(value => value.value);
    setFilters({ ...filters, tags });
  };

  const setStatus = chips => {
    const status = chips.map(value => value.value);
    setFilters({ ...filters, status, modes: ["trouble"] });
  };

  const setModes = modes => {
    setFilters({
      ...filters,
      modes,
      tags: modes.length === 1 ? [] : filters.tags
    });
  };

  const setUserFilter = chips => {
    const usersArr = chips.map(value => value.user_email);
    setFilters({ ...filters, users: usersArr });
  };

  const setTroubleStatusUserFilter = chips => {
    const usersArr = chips.map(value => value.user_email);
    setFilters({ ...filters, troubleStatusUsers: usersArr });
  };

  const setDateFilters = dates => {
    let { toDate, fromDate } = dates;
    if (toDate) {
      toDate = moment(toDate);
      toDate.hour(23).minute(59);
      toDate = toDate.toDate();
    }
    if (fromDate) {
      fromDate = moment(fromDate);
      fromDate.hour(0).minute(0);
      fromDate = fromDate.toDate();
    }
    dates = { fromDate, toDate };
    setFilters({ ...filters, dates });
  };

  const setSubmissionType = types => {
    const submissionTypes = types.map(value => value.value);
    setFilters({ ...filters, submissionTypes });
  };

  const clearFilters = () => {
    setResetting(true);
    setFilterRemoved(true);
    setShowPeriodInvalid(false);
    setFilters({
      modes: [],
      tags: [],
      users: [],
      submissionTypes: [],
      status: [],
      highPriority: false,
      troubleStatusUsers: [],
      districts: []
    });
  };

  const onHighPriority = ({ target }) => {
    setFilters({
      ...filters,
      highPriority: target.checked
    });
  };

  const setDistrictsFiltersArr = chips => {
    setFilters({ ...filters, districts: chips });
  };
  return (
    <FiltersContext.Provider
      value={{
        filters,
        setTags,
        setModes,
        setUserFilter,
        setUsers,
        users,
        setDateFilters,
        setSubmissionType,
        setAssetId,
        assetId,
        assetRef,
        active,
        setActive,
        clearFilters,
        setStatus,
        resetting,
        setResetting,
        filterRemoved,
        setFilterRemoved,
        showPeriodInvalid,
        setShowPeriodInvalid,
        setFilters,
        getUsers,
        onHighPriority,
        setTroubleStatusUserFilter,
        troubleStatusUsers,
        setDistricts,
        districts,
        setDistrictsFiltersArr
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersStore;
