import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../components/home";
import Login from "../components/login";
import Management from "../components/management";
import LiveFeed from "../components/live-feed";
import DataDownload from "../components/data-download";
import { AuthCheck } from "reactfire";
import Header from "../components/ui/Header";
import CustomizableSnackbar from "../components/ui/CustomizableSnackbar";

const AuthenticatedRoute = ({ path, RouteComponent }, otherProps) => (
  <Route path={path} {...otherProps}>
    <AuthCheck otherProps fallback={<Login />}>
      <RouteComponent />
    </AuthCheck>
  </Route>
);

const Routes = () => (
  <Router>
    <Header />
    <CustomizableSnackbar />
    <Switch>
      <AuthenticatedRoute path="/live-feed" RouteComponent={LiveFeed} />
      <AuthenticatedRoute
        path="/management"
        RouteComponent={Management}
        exact
      />
      <AuthenticatedRoute
        path="/data-download"
        RouteComponent={DataDownload}
        exact
      />
      <Route path="/login">
        <Login />
      </Route>
      <AuthenticatedRoute path="/*" RouteComponent={Home} />
    </Switch>
  </Router>
);

export default Routes;
