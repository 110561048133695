import React from "react";
import { TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';

const ChipAutoComplete = ({
  options,
  labelExtractor,
  fieldLabel,
  onChange,
  getOptionSelected,
  value,
  ...otherOptions
}) => (
  <Autocomplete
    {...otherOptions}
    multiple
    options={options}
    value={value}
    getOptionLabel={labelExtractor}
    style={{
      margin: "0 2px",
      width: "100%",
      maxWidth: "100%"
    }}
    filterSelectedOptions
    onChange={(event, newValue) => onChange && onChange(newValue)}
    renderInput={params => (
      <TextField {...params} variant="outlined" label={fieldLabel} />
    )}
    isOptionEqualToValue={
      getOptionSelected
        ? getOptionSelected
        : (option, value) => value.value === option.value
    }
  />
);

export default ChipAutoComplete;
