import React, { useState, useContext, useEffect, memo, useRef } from "react";
import Carousel from "react-material-ui-carousel";
import {
  Grid,
  Typography,
  Dialog,
  Switch,
  FormControlLabel,
  FormGroup,
  IconButton,
  Checkbox
} from "@mui/material";
import { CarouselContext, MapContext, UserContext } from "../../../contexts";
import moment from "moment";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import StatusModal from "./StatusModal";
import { useHistory } from "react-router-dom";
import { usePan } from "./CarousalPan";
import "../../../App.css";
import {
  doneEditingBox,
  getAssetColor,
  checkForUnsavedBoxes,
  resetCurrentlySaving,
  hideConfirmBox,
  getLatestCorrectedBbox,
  resetSaving,
  clearUnsavedBoxes,
  findEdits
} from "./draw-rectangle";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AnnotationModal from "./AnnotationModal";
import UnsavedChangesDialog from "../UnsavedChangesDialog";
import CarouselImage from "./CarouselImage";
import StreetView from "../../map/StreetView";
import TroubleChecklist from "./TroubleChecklist";
import Canvas from "./Canvas";
import {
  SubmissionInfoItem,
  SubmissionInfoItemTitle
} from "./submission-info-ui";

const useStyles = makeStyles()(theme => ({
  typography: {
    padding: theme.spacing(1)
  },
  root: {
    display: "flex",
    minWidth: 325,
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
    maxHeight: window.innerHeight - 15,
    overflow: "scroll",
    overflowX: "hidden"
  },
  zoomed_image: {
    boxShadow: "1px 1px rgba(0,0,0,0.4)",
    position: "absolute",
    right: 0,
    width: "425px",
    height: "320px",
    zIndex: 100
  },
  top: {
    maxWidth: "420px",
    marginLeft: "2px",
    marginTop: "2px",
    width: "100%",
    height: "100%"
  },
  infoRow: {
    display: "flex",
    maxWidth: "425px",
    flexWrap: "wrap"
  },
  infoItem: {
    width: "170px"
  },
  typInfoItem: {
    width: "205px",
    padding: theme.spacing(1)
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.2rem"
  },
  info: {
    fontSize: "0.9rem"
  },
  image: {
    width: "auto",
    maxHeight: window.innerHeight - 15,
    maxWidth: window.innerWidth - 400
  },
  paperProps: {
    margin: "0px",
    maxHeight: window.innerHeight - 15
  },
  boundingRect: {
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "#FF6600",
    border: `2px solid #FF6600 `,
    zIndex: 100
  },
  iconsContainer: {
    display: "block",
    width: "100%",
    height: "100%",
    padding: "2.5px",
    backgroundClip: "content-box"
  },

  confirm: {
    position: "absolute",
    display: "none",
    width: 26.7,
    height: 90,
    backgroundColor: "white",
    borderRadius: 4,
    opacity: "98%",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
    zIndex: 100,
    left: "88.5%",
    top: "10%",
    flexDirection: "column",
    margin: "10px",
    padding: "0 5px",
    animation: "glow 1s infinite alternate"
  },
  zoonIcon: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "2rem"
  },
  closeButton: {
    position: "absolute",
    right: "4px",
    top: "4px"
  }
}));

const PhotoCarousel = memo(() => {
  const { classes } = useStyles();
  const {
    information,
    setVisible,
    visible,
    wrapperComponentRef,
    imageRef,
    setPanState
  } = useContext(CarouselContext);
  const { liveFeed } = useContext(MapContext);
  const { troubleEditor } = useContext(UserContext);
  const { images, submission, index } = information;
  const [currentIndex, setCurrentIndex] = useState(index);
  const [zoomVisible, setZoomVisible] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showAnnotations, setShowAnnotations] = useState(false);
  const [currentStatus, setCurrentStatus] = useState({
    trouble_status: submission.trouble_status,
    trouble_status_reference_number: submission.trouble_status_reference_number
  });
  const [annotations, setAnnotations] = useState([]);
  const [editAnno, setEditAnno] = useState(false);
  const [imagePositionReset, setImagePositionReset] = useState(false);
  const [panEditMode, setPanEditMode] = useState(false);
  const [showAssets, setShowAssets] = useState(true);
  const [showDefects, setShowDefects] = useState(false);
  const [offset, startPan] = usePan();

  const { t } = useTranslation();
  const history = useHistory();

  const [activeBox, setActiveBox] = useState(null);
  const [showUnsavedDialog, setShowUnsavedDialog] = useState({
    open: false,
    onYes: () => {}
  });

  const onCancelEdit = () => {
    const active = document.getElementById("active-box");
    active.style.display = "none";
    setShowUnsavedDialog({ open: false, onYes: () => {} });
    setEditAnno(false);
    images[currentIndex].asset_candidates = annotations;
  };

  const onCancelSavingAnno = async () => {
    resetCurrentlySaving();
    setActiveBox(null);
  };

  useEffect(() => {
    if (images[currentIndex]) {
      let candidates = images[currentIndex].asset_candidates;
      candidates = candidates
        ? candidates.map(can => {
            return { ...can, id: Math.random() * 100 };
          })
        : [];
      setAnnotations(candidates);
    }
  }, [currentIndex]);

  const onDoneEditing = () => {
    const box = doneEditingBox();
    setActiveBox(box);
  };

  useEffect(() => {
    setPanState({ x: 0, y: 0 });
  }, [imageRef]);

  const onAnnotationChangeSave = newAnnotations => {
    resetSaving();
    setAnnotations(newAnnotations);
    setActiveBox(null);
  };

  const handleClose = () => {
    const cleanup = () => {
      setVisible(false);
      const loc = liveFeed ? "/live-feed" : "/";
      history.replace(loc);
    };
    const unsavedChange = editAnno ? checkForUnsavedBoxes() : false;
    unsavedChange
      ? setShowUnsavedDialog({ open: true, onYes: cleanup })
      : cleanup();
  };

  const onStatusModalClose = ({
    trouble_status,
    trouble_status_history,
    trouble_status_reference_number
  }) => {
    if (!submission.trouble_status && !submission.trouble_status_history) {
      submission.trouble_status = trouble_status;
      submission.trouble_status_history = trouble_status_history;
      submission.trouble_status_reference_number =
        trouble_status_reference_number;
    }
    setCurrentStatus({ trouble_status, trouble_status_reference_number });
    setShowStatusModal(false);
  };

  const onEditAnnoClick = () => {
    if (editAnno) {
      const unsavedChange = checkForUnsavedBoxes();
      unsavedChange
        ? setShowUnsavedDialog({ open: true, onYes: onCancelEdit })
        : onCancelEdit();
    } else {
      setPanEditMode(false);
      setEditAnno(!editAnno);
    }
  };

  const ImageCanvas = ({ scale }) => (
    <>
      <Canvas
        showAnnotations={showAnnotations}
        imageRef={imageRef}
        asset_candidates={annotations}
        editEnabled={editAnno}
        panEditMode={panEditMode}
        showAssets={showAssets}
        showDefects={showDefects}
        scale={scale}
      />
    </>
  );

  const onClear = () => {
    const active = document.getElementById("active-box");
    active.style.display = "none";
    setShowUnsavedDialog({ open: false, onYes: () => {} });
    clearUnsavedBoxes();
  };

  const AssetCanadidateView = () => {
    let types = [];
    let defects = [];
    annotations.forEach(item => {
      const curr_type = item.corrected_bbox
        ? getLatestCorrectedBbox(item.corrected_bbox).corrected_type
        : item.type;
      const index = types.findIndex(type => type.type === curr_type);
      index >= 0
        ? types[index].count++
        : types.push({
            type: curr_type,
            count: 1
          });
      if (item.defects) {
        item.defects.forEach(defect => {
          const index = defects.findIndex(type => defect.type === type.type);
          index >= 0
            ? defects[index].count++
            : defects.push({
                type: defect.type,
                count: 1
              });
        });
      }
    });
    return (
      <Grid className={classes.typography}>
        {defects.length ? (
          <FormGroup row style={{ marginTop: -15 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAssets}
                  onChange={() => setShowAssets(!showAssets)}
                  name="checkedA"
                  color="primary"
                  disabled={editAnno}
                />
              }
              label="Assets"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showDefects}
                  onChange={() => setShowDefects(!showDefects)}
                  name="checkedB"
                  color="primary"
                  disabled={editAnno}
                />
              }
              label="Defects"
            />
          </FormGroup>
        ) : null}
        {showAssets ? (
          <>
            <SubmissionInfoItemTitle
              icon={{
                title: editAnno ? "Done" : "Edit",
                image: editAnno ? CloseIcon : EditIcon,
                onClick: onEditAnnoClick
              }}
              secondIcon={
                editAnno
                  ? {
                      title: "Clear Unsaved Edits",
                      image: LayersClearIcon,
                      onClick: () => {
                        const hasEdits = findEdits();
                        hasEdits &&
                          setShowUnsavedDialog({
                            open: true,
                            onYes: onClear
                          });
                      }
                    }
                  : null
              }
              header="Detected Assets"
            />
            {types.length ? (
              types.map((type, i) => (
                <Grid key={type.type + i}>
                  <Typography
                    className={classes.info}
                    style={{
                      textTransform: "capitalize",
                      color: getAssetColor(type.type)
                    }}
                  >
                    {t(`asset:${type.type}`)}: {type.count}
                  </Typography>
                </Grid>
              ))
            ) : (
              <Typography
                className={classes.info}
                style={{
                  textTransform: "capitalize"
                }}
              >
                {t(`asset:no_detected`)}
              </Typography>
            )}{" "}
          </>
        ) : null}
        {showDefects ? (
          <div style={{ marginTop: 8 }}>
            <SubmissionInfoItemTitle header="Detected Defects" />
            {defects.map((defect, i) => {
              const label = defect.type.replace(/-/g, " ");
              return (
                <Grid key={defect.type + i}>
                  <Typography
                    className={classes.info}
                    style={{
                      textTransform: "capitalize",
                      color: getAssetColor("defect")
                    }}
                  >
                    {label}: {defect.count}
                  </Typography>
                </Grid>
              );
            })}
          </div>
        ) : null}
      </Grid>
    );
  };

  return (
    <>
      <Dialog
        open={visible}
        onClose={handleClose}
        maxWidth={false}
        PaperProps={{
          className: classes.paperProps
        }}
      >
        <Grid container direction="row" justifyContent="center">
          <Grid
            item
            style={{ position: "relative" }}
            ref={wrapperComponentRef}
            onMouseDown={e => {
              setImagePositionReset(false);
              panEditMode && startPan(e);
            }}
          >
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                backgroundColor: "darkgrey",
                maxHeight: window.innerHeight - 15
              }}
            >
              <Carousel
                autoPlay={false}
                indicators={false}
                index={index}
                onChange={index1 => {
                  showAnnotations && setShowAnnotations(false);
                  setCurrentIndex(index1);
                  setShowAssets(true);
                  setShowDefects(false);
                }}
                fullHeightHover={false}
                navButtonsAlwaysInvisible={images.length === 1 || editAnno}
                navButtonsAlwaysVisible={images.length > 1 && !editAnno}
              >
                {images.map((image, i) =>
                  image.download_link.indexOf(".mov") > 0 ? (
                    <video className={classes.image} controls key={i}>
                      <source src={image.download_link} type="video/mp4" />
                    </video>
                  ) : (
                    <CarouselImage
                      image={image}
                      key={i}
                      panEditMode={panEditMode}
                      setPanEditMode={setPanEditMode}
                      showAnnotations={showAnnotations}
                      setZoomVisible={setZoomVisible}
                      imagePositionReset={imagePositionReset}
                      setImagePositionReset={setImagePositionReset}
                      ImageCanvas={ImageCanvas}
                      zoomVisible={zoomVisible}
                    />
                  )
                )}
              </Carousel>
            </div>
            <div id="active-box" className={classes.confirm}>
              <IconButton
                aria-label="close"
                onClick={hideConfirmBox}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="ok"
                color="primary"
                onClick={onDoneEditing}
                size="large"
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item className={classes.root}>
            <div
              className={classes.zoomed_image}
              style={
                zoomVisible
                  ? {
                      display: "block"
                    }
                  : { display: "none" }
              }
            >
              <div id="zoomed-image" className={classes.zoomed_image} />
            </div>
            <div className={classes.top}>
              {images[currentIndex] &&
              submission.submission_type !== "meter" &&
              submission.submission_type !== "inspection" &&
              !(images[currentIndex].download_link.indexOf(".mov") > 0) &&
              !(images[currentIndex].download_link.indexOf(".mp4") > 0) ? (
                <>
                  <FormGroup row className={classes.typography}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showAnnotations}
                          disabled={editAnno}
                          onChange={event =>
                            setShowAnnotations(event.target.checked)
                          }
                          name="checkedB"
                        />
                      }
                      label="Detected Assets (Beta)"
                    />
                  </FormGroup>
                  {showAnnotations ? <AssetCanadidateView /> : null}
                </>
              ) : null}
            </div>
            {submission ? (
              <Grid item>
                <Grid item className={classes.infoRow}>
                  {submission.time_created && (
                    <SubmissionInfoItem
                      header="Time Created"
                      infoItem={moment
                        .unix(submission.time_created.seconds)
                        .format("YYYY/MM/DD HH:mm")}
                    />
                  )}
                  <SubmissionInfoItem
                    header="Created By"
                    infoItem={
                      submission.user_display_name || submission.user_email
                    }
                  />

                  {submission.geo_location_summary ? (
                    <SubmissionInfoItem
                      header="Address"
                      infoItem={
                        submission.geo_location_summary.street_address
                          ? `${submission.geo_location_summary.street_address} ${submission.geo_location_summary.municipality}`
                          : submission.geo_location_summary.municipality
                      }
                    />
                  ) : null}
                  <SubmissionInfoItem
                    header="Mode"
                    infoItem={submission.mode}
                    style={{ textTransform: "capitalize" }}
                  />
                  {submission.inspection_type === "field-urgent" ||
                  submission.inspection_type === "field-planned" ||
                  (submission.mode_tags && submission.mode_tags.length) ? (
                    <SubmissionInfoItem
                      header="Tags"
                      infoItem={
                        submission.inspection_type === "field-urgent" ||
                        submission.inspection_type === "field-planned"
                          ? submission.inspection_type
                          : submission.mode_tags.join(", ")
                      }
                      style={{ textTransform: "capitalize" }}
                    />
                  ) : null}
                  {submission.note && (
                    <SubmissionInfoItem
                      header="Notes"
                      infoItem={submission.note}
                    />
                  )}
                  {submission.asset_id && submission.asset_id !== "unknown" && (
                    <SubmissionInfoItem
                      header="Pole Asset ID"
                      infoItem={submission.asset_id}
                    />
                  )}
                  {images[currentIndex] &&
                    (images[currentIndex].meter_asset_id ||
                      images[currentIndex].inspection_asset_id ||
                      images[currentIndex].asset_id) && (
                      <SubmissionInfoItem
                        header="Image Asset ID"
                        infoItem={
                          images[currentIndex].meter_asset_id ||
                          images[currentIndex].inspection_asset_id ||
                          images[currentIndex].asset_id
                        }
                      />
                    )}
                  {images[currentIndex] &&
                    images[currentIndex].inspection_asset_type && (
                      <SubmissionInfoItem
                        header={t("assetType:asset")}
                        infoItem={t(
                          `assetType:${images[currentIndex].inspection_asset_type}`
                        )}
                      />
                    )}
                  {submission.mode === "trouble" ? (
                    <SubmissionInfoItem
                      header="Status"
                      infoItem={t(
                        `status:${currentStatus.trouble_status || "submitted"}`
                      )}
                      icon={
                        troubleEditor
                          ? {
                              title: "Edit Status",
                              image: EditIcon,
                              onClick: () => setShowStatusModal(true)
                            }
                          : null
                      }
                    />
                  ) : null}
                </Grid>
                {(submission.meter_checklist ||
                  submission.inspection_checklist ||
                  (submission.general_trouble_checklist &&
                    submission.general_trouble_checklist.length)) && (
                  <TroubleChecklist
                    checklist={
                      submission.inspection_checklist ||
                      submission.meter_checklist ||
                      submission.general_trouble_checklist
                    }
                    type={submission.submission_type}
                  />
                )}
                <StreetView
                  location={{
                    lat: submission.group_latitude,
                    lng: submission.group_longitude
                  }}
                  trouble={submission.mode === "trouble"}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        {images.map(image =>
          image.download_link.indexOf(".mov") > 0 ? (
            <video style={{ display: "none" }} key={image.download_link}>
              <source src={image.download_link} type="video/mp4" />
            </video>
          ) : (
            <img
              src={image.download_link}
              key={image.download_link}
              alt="preloader"
              style={{ display: "none" }}
            />
          )
        )}
        <div className={classes.closeButton}>
          <IconButton
            aria-label="close-status-modal"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Dialog>
      <StatusModal
        open={showStatusModal}
        onClose={onStatusModalClose}
        onCancel={() => setShowStatusModal(false)}
        currentStatus={currentStatus.trouble_status || "submitted"}
        statusHistory={submission.trouble_status_history || []}
        reference={currentStatus.trouble_status_reference_number}
        id={submission.id}
      />

      <AnnotationModal
        selection={activeBox}
        onClose={onCancelSavingAnno}
        onCancel={onCancelSavingAnno}
        imageRef={imageRef}
        onSave={onAnnotationChangeSave}
        annotations={annotations}
        id={images[currentIndex] ? images[currentIndex].id : "noimages"}
      />
      <UnsavedChangesDialog
        open={showUnsavedDialog.open}
        onYes={showUnsavedDialog.onYes}
        onNo={() => setShowUnsavedDialog({ open: false, onYes: () => {} })}
      />
    </>
  );
});

export default PhotoCarousel;
