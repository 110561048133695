import moment from "moment";

export const timeCreatedSort = item =>
  item && item.time_created ? moment.unix(item.time_created.seconds) : "";
export const userSort = item =>
  item && item.user_display_name
    ? item.user_display_name
    : item.user_email
    ? item.user_email
    : "";
export const addressSort = item => {
  if (!item || (item && !item.geo_location_summary)) {
    return "";
  } else {
    const street_address = item.geo_location_summary.street_address;
    const municipality = item.geo_location_summary.municipality || "";
    return street_address ? `${street_address} ${municipality}` : municipality;
  }
};

export const criticalSort = item =>
  item && item.critical_response ? item.critical_response : "";
export const tagsSort = item =>
  item
    ? item.inspection_type === "field-urgent"
      ? "Field - Urgent"
      : item.mode_tags && item.mode_tags.length
      ? item.mode_tags.join(", ")
      : ""
    : "";

export const tableSorter = (a, b, orderBy) => {
  let bProp = b[orderBy];
  let aProp = a[orderBy];
  switch (orderBy) {
    case "mode":
      bProp = tagsSort(b);
      aProp = tagsSort(a);
      break;
    case "time_created":
      bProp = timeCreatedSort(b);
      aProp = timeCreatedSort(a);
      break;
    case "submittedBy":
      bProp = userSort(b);
      aProp = userSort(a);
      break;
    case "address":
      bProp = addressSort(b);
      aProp = addressSort(a);
      break;
    case "critical":
      bProp = criticalSort(b);
      aProp = criticalSort(a);
      break;
    default:
      break;
  }
  return { aProp, bProp };
};

export const tableInfo = {
  columns: [
    { id: "submittedBy", label: "table:submittedBy" },
    { id: "time_created", label: "table:submitted" },
    { id: "mode", label: "filters:tags" },
    {
      id: "critical",
      label: "filters:critical"
    },
    /** uncomment when district work is ready {
      id: "district",
      label: "filters:District"
    },*/
    { id: "address", label: "table:address" }
  ],
  rows: [
    {
      accessor: userSort,
      otherRowProps: { component: "th", scope: "row" }
    },
    {
      accessor: row =>
        moment.unix(row.time_created.seconds).format("YYYY/MM/DD HH:mm")
    },
    {
      accessor: row =>
        row.inspection_type === "field-urgent"
          ? "Field - Urgent"
          : row.mode_tags.join(", "),
      style: { textTransform: "capitalize" }
    },
    {
      accessor: row => row.critical_response,
      style: { textTransform: "capitalize" }
    },
    /** uncomment when district work is ready{
      accessor: () => ""
    }, */
    {
      accessor: addressSort
    }
  ],
  startOrder: "time_created",
  rowsPerPageOptions: [10, 20, 30, 40],
  tableStyles: { maxHeight: 500, overflow: "auto" },
  numRows: 10,
  startDirection: "desc"
};
