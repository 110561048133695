import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "./colors";
import { Grid } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GetAppIcon from "@mui/icons-material/GetApp";
import SettingsIcon from "@mui/icons-material/Settings";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { useAuth, AuthCheck } from "reactfire";
import ClickableIcon from "./ClickableIcon";
import HomeIcon from "@mui/icons-material/Home";
import { useHistory } from "react-router-dom";
import { revokeSession } from "../../api/api";
import { MapContext, UserContext, SnackbarContext } from "../../contexts";
import { useFirestore } from "reactfire";
import { useLocation } from "react-router-dom";
import { questionBank } from "./question-bank";

var firstLoad = true;
var newTroubleCount = 0;

const triggerNotification = ({ header, body, group_id }) => {
  const notification = new Notification(header, {
    body,
    icon: require("../../images/PanoSplash.png")
  });
  setTimeout(() => {
    notification.close();
  }, 10 * 1000);

  if (group_id) {
    notification.addEventListener("click", () => {
      window.open(`https://panoexplore.com/live-feed/carousel/${group_id}`);
    });
  }
};

const Header = () => {
  const firestore = useFirestore();
  const location = useLocation();
  const { t } = useTranslation();
  const auth = useAuth();
  const history = useHistory();
  const {
    unsub,
    cleanup,
    setBackToMap,
    setCurrentLocation,
    setTroubleSubmissions
  } = useContext(MapContext);

  const { setSnackInfo } = useContext(SnackbarContext);

  const { accessList, adminGroup } = useContext(UserContext);
  const [showDownload, setShowDownload] = useState(false);
  let unsubscribe = () => {};

  useEffect(() => {
    const updateNotifications = snapshot => {
      let subs = [];
      snapshot.forEach(snap => {
        let data = snap.data();
        data.id = snap.id;
        data.high_priority = false;
        if (
          data.submission_type === "general" ||
          data.inspection_type === "field-urgent"
        ) {
          if (
            data.general_trouble_checklist &&
            data.general_trouble_checklist.length
          ) {
            data.general_trouble_checklist.forEach(checklist => {
              if (checklist.responses && checklist.responses.length) {
                checklist.responses.forEach(item => {
                  if (item.answerId) {
                    const question = questionBank.find(
                      quest => quest.id === item.answerId
                    );
                    if (question && question.activateEmergencyCall) {
                      data.high_priority = true;
                      const trigger = questionBank.find(
                        quest => quest.id === item.questionId
                      );
                      const str = trigger
                        ? `${trigger.question} - ${question.answer_title}`
                        : null;
                      data.critical_response = str;
                    }
                  }
                });
              }
            });
          }
          subs.push(data);
        }
      });
      snapshot.docChanges().forEach(change => {
        if (change.type === "added" && !firstLoad) {
          const data = change.doc.data();
          if (
            data.submission_type === "general" ||
            data.inspection_type === "field-urgent"
          ) {
            newTroubleCount++;
            if (Notification.permission === "granted") {
              triggerNotification({
                header: "New Trouble Submission",
                body: "A new trouble mode submission has been created. Head to the live feed to view.",
                group_id: data.group_id
              });
            }
          }
        }
      });
      firstLoad = false;
      setTroubleSubmissions(subs);
    };

    if (auth.currentUser) {
      unsubscribe = firestore
        .collection("submissions")
        .where("mode", "==", "trouble")
        .where("trouble_status", "in", ["submitted", "updated"])
        .onSnapshot(updateNotifications);
    } else {
      firstLoad = true;
      unsubscribe();
    }
  }, [auth.currentUser]);

  useEffect(() => {
    if (!("Notification" in window)) {
      alert(t("notifications:unsupportedBrowser"));
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          triggerNotification({
            header: "Enabled",
            body: t("notifications:notificationEnabled")
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    setShowDownload(!!accessList.length);
  }, [accessList]);

  const onLogout = () => {
    if (location.pathname === "/live-feed") {
      newTroubleCount = 0;
    }
    revokeSession();
    unsub();
    auth.signOut().then(() => {
      history.replace("/login");
      cleanup();
    });
  };

  const resetLocation = () =>
    navigator.geolocation.getCurrentPosition(position => {
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
    });

  return (
    <Grid
      container
      style={{ background: colors.panoBeige }}
      justifyContent="space-between"
    >
      <Grid item>
        <img
          src={require("../../images/PanoSplash.png")}
          height="70"
          alt="pano header logo"
          onClick={() => {
            if (location.pathname === "/live-feed") {
              newTroubleCount = 0;
            }
            if (location.pathname !== "/") {
              cleanup();
              setBackToMap(true);
              resetLocation();
              history.replace("/");
            }
          }}
          style={{ cursor: "pointer" }}
        />
      </Grid>
      <Grid item style={{ alignSelf: "center" }}>
        <AuthCheck fallback={<div />}>
          <ClickableIcon
            title={t("header:home")}
            IconImage={HomeIcon}
            onClick={async () => {
              if (location.pathname === "/live-feed") {
                newTroubleCount = 0;
              }
              if (location.pathname !== "/") {
                cleanup();
                resetLocation();
                await setSnackInfo({
                  open: false
                });
                setBackToMap(true);
                history.replace("/");
              }
            }}
          />
          <ClickableIcon
            title={t("header:live")}
            IconImage={RssFeedIcon}
            badgeCount={newTroubleCount}
            onClick={async () => {
              newTroubleCount = 0;

              if (location.pathname !== "/live-feed") {
                cleanup();
                await setSnackInfo({
                  open: false
                });
                setBackToMap(true);
                resetLocation();
                history.replace("/live-feed");
              }
            }}
          />
          {adminGroup.length ? (
            <ClickableIcon
              title={t("header:management")}
              IconImage={SettingsIcon}
              onClick={() => {
                if (location.pathname === "/live-feed") {
                  newTroubleCount = 0;
                }
                history.replace("/management");
              }}
            />
          ) : null}
          {showDownload && (
            <ClickableIcon
              title={t("header:data")}
              IconImage={GetAppIcon}
              onClick={() => {
                if (location.pathname === "/live-feed") {
                  newTroubleCount = 0;
                }
                history.replace("/data-download");
              }}
            />
          )}
          <ClickableIcon
            title={t("header:logout")}
            IconImage={ExitToAppIcon}
            onClick={onLogout}
          />
        </AuthCheck>
      </Grid>
    </Grid>
  );
};

export default Header;
