import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { SubmissionsContext } from "../../contexts";
import { Grid, Typography } from "@mui/material";

const Stat = ({ label, count }) => (
  <Grid
    container
    item
    direction="column"
    xs={4}
    style={{
      textAlign: "center"
    }}
  >
    <Typography variant="h5" style={{ margin: "2px", fontWeight: "bold" }}>
      {count}
    </Typography>
    <Typography variant="h6" style={{ fontWeight: "normal", margin: 0 }}>
      {label}
    </Typography>
  </Grid>
);

const StatsDash = () => {
  const { t } = useTranslation();
  const { submissionsInBounds } = useContext(SubmissionsContext);

  return (
    <Paper>
      <Grid
        container
        style={{
          margin: "10px"
        }}
      >
        <Stat
          label={t("stats:submissions")}
          count={submissionsInBounds.length}
        />
        <Stat
          label={t("stats:normal")}
          count={
            submissionsInBounds.filter(sub => sub.mode === "normal").length || 0
          }
        />
        <Stat
          label={t("stats:trouble")}
          count={
            submissionsInBounds.filter(sub => sub.mode === "trouble").length ||
            0
          }
        />
      </Grid>
    </Paper>
  );
};

export default StatsDash;
