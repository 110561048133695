const getCursorPos = ({ e, img }) => {
  let a,
    x = 0,
    y = 0;
  e = e || window.event;

  a = img.getBoundingClientRect();

  x = e.pageX - a.left;
  y = e.pageY - a.top;

  x = x - window.pageXOffset;
  y = y - window.pageYOffset;
  return { x, y };
};

const moveLens = ({ lens, img, e, result, cy, cx }) => {
  e.preventDefault();
  const pos = getCursorPos({ e, img });
  let x = pos.x - lens.offsetWidth / 2;
  let y = pos.y - lens.offsetHeight / 2;

  if (x > img.width - lens.offsetWidth) {
    x = img.width - lens.offsetWidth;
  }
  if (x < 0) {
    x = 0;
  }
  if (y > img.height - lens.offsetHeight) {
    y = img.height - lens.offsetHeight;
  }
  if (y < 0) {
    y = 0;
  }

  lens.style.left = `${x}px`;
  lens.style.top = `${y}px`;

  result.style.backgroundPosition = `-${x * cx}px -${y * cy}px`;
};

export const imageZoom = (imgID, resultID) => {
  let img = document.getElementById(imgID);
  let result = document.getElementById(resultID);

  let lens = document.getElementById("lens");
  lens.style.position = "absolute";
  lens.style.backgroundColor = "rgba(255,255,255, 0.3)";
  lens.style.width = "45px";
  lens.style.height = "34px";
  lens.style.zIndex = 100;

  const cx = (result.offsetWidth / lens.offsetWidth) * 1.25;
  const cy = (result.offsetHeight / lens.offsetHeight) * 1.25;

  result.style.backgroundImage = `url(${img.src})`;
  result.style.backgroundSize = `${img.width * cx}px  ${img.height * cy}px`;

  const moveEvent = e => moveLens({ lens, img, e, result, cy, cx });

  lens.addEventListener("mousemove", moveEvent);
  img.addEventListener("mousemove", moveEvent);
  /* And also for touch screens: */
  lens.addEventListener("touchmove", moveEvent);
  img.addEventListener("touchmove", moveEvent);
};
