import React, { useEffect, useState, useContext } from "react";
import Map from "../map";
import Filters from "../ui/Filters";
import EnhancedTable from "../ui/EnhancedTable/EnhancedTable";
import PhotoCarousel from "../ui/PhotoCarousel";
import { useFirestore } from "reactfire";
import { Grid, CircularProgress, Paper, Typography } from "@mui/material";
import {
  MapContext,
  CarouselContext,
  SubmissionsContext,
  FiltersContext
} from "../../contexts";
import { tableSorter, tableInfo } from "./table-utils";
import { StatusType } from "../constants";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";
import firebase from "firebase";
import reproject from "reproject";

const LiveFeed = () => {
  const { visible, setCarouselInfo } = useContext(CarouselContext);
  const { setFilters, setDistricts } = useContext(FiltersContext);
  const { map, recenterLivefeed } = useContext(MapContext);
  const firestore = useFirestore();
  const location = useLocation();

  const [districtsJSON, setDistrictsJSON] = useState(null);

  useEffect(() => {
    const match = matchPath(location.pathname, {
      path: "/live-feed/carousel/:id",
      exact: true,
      strict: false
    });

    const loadSubmissionInfo = promisedResults => {
      const images = [];
      let submission;
      promisedResults.forEach(results => {
        results.forEach(async doc => {
          const sub = doc.data();
          sub.id = doc.id;
          if (sub.download_link) {
            document.getElementById("preloader").src = sub.download_link;
            images.push(sub);
          } else {
            submission = sub;
          }
        });
      });
      if (submission) {
        setCarouselInfo(submission, images, 0);
      }
      setLoadingSubmission(false);
    };

    if (match && match.isExact) {
      setLoadingSubmission(true);
      const id = match.params.id;
      Promise.all([
        firestore.collection("submissions").where("group_id", "==", id).get(),
        firestore
          .collection("submission_files")
          .where("group_id", "==", id)
          .get()
      ]).then(loadSubmissionInfo);
    }
  }, []);

  useEffect(() => {
    setFilters({
      dates: [],
      tags: [],
      users: [],
      troubleStatusUsers: [],
      submissionTypes: [],
      status: [],
      modes: ["trouble"],
      status: [StatusType.SUBMITTED, StatusType.UPDATED],
      highPriority: false,
      districts: []
    });
  }, []);

  useEffect(() => {
    if (districtsJSON) {
      let newDist = reproject.toWgs84(
        districtsJSON,
        "+proj=sterea +lat_0=46.5 +lon_0=-66.5 +k=0.999912 +x_0=2500000 +y_0=7500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
      );

      map.data.addGeoJson(newDist);
      const districtsFilter = [];
      map.data.setStyle(feature => {
        var value = feature.getProperty("DISTRICTNUMBER");
        var label = feature.getProperty("DISTRICTNAME");
        const geometry = feature.getGeometry();
        let paths = [];
        geometry.forEachLatLng(latLng => {
          paths.push(latLng);
        });

        const polygon = new window.google.maps.Polygon({
          paths
        });
        if (value) {
          districtsFilter.push({ value, label, polygon });
          return { fillOpacity: 0, strokeWeight: 0 };
        }
      });
      setDistricts(districtsFilter);
    }
  }, [districtsJSON]);

  useEffect(() => {
    recenterLivefeed();
    if (map) {
      const storage = firebase
        .app()
        .storage("gs://pano-assets-json-prod")
        .ref();

      storage
        .child("districts.json")
        .getDownloadURL()
        .then(url => {
          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = () => {
            var blob = xhr.response;
            var fr = new FileReader();
            fr.onload = e => {
              const districtsFile = JSON.parse(e.target.result);
              setDistrictsJSON(districtsFile);
            };

            fr.readAsText(blob);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [map]);

  const { submissionsInBounds } = useContext(SubmissionsContext);

  const { zoomToSubmission, setLiveFeed } = useContext(MapContext);

  const [loadingSubmission, setLoadingSubmission] = useState(true);

  useEffect(() => {
    setLiveFeed(true);
    setLoadingSubmission(false);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row"
      }}
    >
      {loadingSubmission ? (
        <CircularProgress />
      ) : (
        <>
          <Grid
            container
            direction="column"
            style={{
              margin: "10px 10px 0 10px"
            }}
          >
            <Paper>
              <Grid
                style={{
                  padding: "10px"
                }}
                container
              >
                <Typography
                  variant="h5"
                  style={{
                    margin: "2px",
                    fontWeight: "bold"
                  }}
                >
                  Live Trouble Feed
                </Typography>
              </Grid>
            </Paper>
            <Map defaultZoom={7} />
          </Grid>
          <Grid
            container
            direction="column"
            style={{
              margin: "0px 10px"
            }}
          >
            <Filters
              liveView={true}
              disabledFilters={["mode", "status", "submissionType"]}
            />
            <EnhancedTable
              info={submissionsInBounds}
              tableInfo={tableInfo}
              onRowSelected={zoomToSubmission}
              sortAccessor={tableSorter}
              exportCSV="Live Feed"
            />
          </Grid>
        </>
      )}
      {visible && <PhotoCarousel />}
      <img id="preloader" alt="preloader" style={{ display: "none" }} />
    </div>
  );
};
export default LiveFeed;
