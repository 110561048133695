import React, { useState } from "react";
import { SnackbarContext } from "../contexts";

export const SnackbarStore = ({ children }) => {
  const [snackInfo, setSnackInfo] = useState({
    type: "info",
    open: false,
    message: ""
  });

  const openSnackbar = info => {
    if (!snackInfo.open) {
      setSnackInfo(info);
    }
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, snackInfo, setSnackInfo }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarStore;
