import React from "react";
import { TextField, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const SearchBar = ({ onSearch, searchLabel }) => (
  <Grid container spacing={1} alignItems="flex-end" justifyContent="flex-end">
    <Grid item>
      <SearchIcon />
    </Grid>
    <Grid item>
      <TextField
        id="search"
        type="search"
        label={searchLabel}
        onChange={onSearch}
        aria-label="search"
        variant="standard"
      />
    </Grid>
  </Grid>
);
export default SearchBar;
